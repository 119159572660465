import React, { useEffect, useState } from "react";
import { NavBar, Footer, Copyright } from '../components/commons'
import Page from "../components/searcher/Page";
import withContext from "../withContext";
import { showLoading, hideLoading, formatText } from './../Helper'
import { http } from './../services/HttpAxios'
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// JQuery
import $ from 'jquery'
window.jQuery = $;

const Search = props => {
    const { global, baseUrl, appTitle } = props.context
    const location = useLocation();
    const [typeNegotiations, setTypeNegotiations] = useState([])
    const [typeProperties, setTypeProperties] = useState([])
    const [locations, setLocations] = useState([])
    const [advisers, setAdvisers] = useState([])
    const [properties, setProperties] = useState([])
    const [queryParams, setQueryParams] = useState({
        operations: '',
        properties: '',
        locations: '',
        advisers: '',
        price: '',
        search: '',
        status: false
    })

    useEffect(async() => {
        showLoading()
        $(window).scrollTop(0);
        if (global['enterprise'] && queryParams.status) {
            try {
                getData(queryParams)
            } catch {}
        }
    }, [props.context, queryParams]);

    // Method to call data from API
    const getData = async(params) => {
        params['search'] = params['search'] ? formatText(params['search']) : ''
        showLoading()
        const query = new URLSearchParams(params).toString()
        const response = await http.get(`/properties/search?${query}`)
        const data = await response.data.data;
        //
        if (data && data.negotiations) setTypeNegotiations(data.negotiations)
        if (data && data.properties) setTypeProperties(data.properties)
        if (data && data.locations) setLocations(data.locations)
        if (data && data.advisers) setAdvisers(data.advisers)
        if (data && data.results) setProperties(data.results)
        hideLoading()
    }

    const setQueryParams2 = (disatch) => {
        setQueryParams(disatch())
        setQueryParams(prevState => ({...prevState, status: true }))
    }



    return (<>
        <Helmet>
            <title>{appTitle} | Buscar</title>
            <link rel="canonical" href="/buscar"></link>
        </Helmet>
        <div className="search position-relative">
            <NavBar className={"position-absolute bg-transparent"}/>
            <Page
                typeNegotiations={typeNegotiations}
                typeProperties={typeProperties}
                locations={locations}
                advisers={advisers}
                properties={properties}
                baseUrl={baseUrl}
                setQueryParams={setQueryParams2}
                queryParams={queryParams}
                global={global}
                params={location.search}
            />
            <Footer
                global={global}
                baseUrl={baseUrl}
            />
            <Copyright/>
        </div>
    </>)
}

export default withContext(Search);