import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Consumer } from './../Context';

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Consumer>
    {({ user }) => (
      <Route
        render={props =>
          {
            return user && Object.keys(user).length > 0 ? <Component {...props} /> : <Redirect to="/admin/auth" />
          }
        }
        {...rest}
      />
    )}
  </Consumer>
)

export default ProtectedRoute
