import React, { useState, useEffect } from "react";
import { FaRegPaperPlane, FaRegBuilding, FaPhoneAlt, FaMailBulk, FaHashtag } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getImageBackend, showLoading, hideLoading, getCurrentUser } from './../../Helper'
import { http } from './../../services/HttpAxios'
import $ from 'jquery';

const ContactForm = props => {
    const currentUser = getCurrentUser()
    const { hideAddress, global, baseUrl, iconsSocial } = props;
    const [whereFindUs, setWhereFindUs] = useState({ address: '', phone: '', email: '' })
    const [showValidation, setShowValidation] = useState(false);
    const [contact, setContact] = useState({
        name: '',
        email: '',
        phone: '',
        subject: ''
    })
    const styleLink = { color: 'inherit', textDecoration: 'inherit'}
    const [socials, setSocials] = useState([])

    useEffect(() => {
        const currentEnterprise = (global && global.enterprise) ? global.enterprise : {}
        setWhereFindUs({
            email: currentEnterprise['email'] || '',
            phone: currentEnterprise['phone'] || '',
            address: currentEnterprise['address'] || ''
        })
        //
        const currentSocials = (global && global.enterprise && global.enterprise.socials) ? global.enterprise.socials : []
        let calculateSocials = []
        for (const social of currentSocials) {
            if (social.status) {
                calculateSocials.push({
                    type: social.main.code,
                    link: social.url,
                    title: social.main.name
                })
            }
        }
        setSocials(calculateSocials)
    }, [props]);

    // Handler Button
    const handlerButtonForm = async () => {
        setShowValidation(true);
        const result = $('#formContact')[0].checkValidity();
        if (!result) return false;
        //
        showLoading()
        const body = {
            subject: 'Alguien quiere vender con nosotros',
            template: 'sell',
            context: contact
        }
        try {
            await http.post(`/mail/send`, body)
            hideLoading();
            setContact({ name: '', email: '', phone: '', subject: '' })
            setShowValidation(false);
            toast.success("Infomarción enviada exitosamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 1500
            });
        } catch (e) {
            console.log(e);
            hideLoading();
            toast.error("Error al enviar, intente nuvamente", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
        }
    }

    // Handler Inputs change
    const handleChangeInput = e => {
        const { name, value } = e.target;
        setContact(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <div className="w-100 app-contact">
            {(global && global['enterprise'] && global['enterprise']['url_banner_head']) && 
            <div className="app-team__photo2 position-relative">
                <div className="color-overlay"></div>
                <img src={getImageBackend(baseUrl, '/enterprise/', global['enterprise']['url_banner_head'])} className="d-block w-100 h-100"/>    
            </div>}
            {!hideAddress && <h1 className="text-center py-4 app-title">Dónde encontrarnos</h1>}
            {!hideAddress && <div className="container mt-4">
                <div className="g-3 px-1 px-md-5">
                    <p className="text-dark">
                        <FaRegBuilding className="me-2"/><span className="fw-bold">Dirección: </span> 
                        {whereFindUs.address}
                    </p>
                    <p className="text-dark">
                        <FaPhoneAlt className="me-2"/><span className="fw-bold">Teléfonos: </span> 
                        {whereFindUs.phone}
                    </p>
                    <p className="text-dark">
                        <FaMailBulk className="me-2"/><span className="fw-bold">Correo: </span> 
                        {whereFindUs.email}
                    </p>
                    <p className="text-dark">
                        <FaHashtag className="me-2"/><span className="fw-bold me-2">Redes sociales: </span> 
                        {socials.map((val, i) => { 
                            const SpecificComponent = iconsSocial[val.type];
                            return (<Link style={styleLink} title={val.title} target="_blank" to={{ pathname: val.link }} key={i}>
                                <SpecificComponent className="fs-5 me-2 color-gray"/>
                            </Link>)
                        })}
                    </p>
                </div>
            </div>}
            <h1 className="text-center py-4 app-title">Contáctanos</h1>
            <div className="container mt-4 mb-5">
                <form id="formContact" autoComplete={'off'} className={`row g-3 px-1 px-md-5 needs-validation ${showValidation ? 'was-validated' : ''}`} noValidate>
                    <div className="col-md-4">
                        <label htmlFor="name" className="form-label mb-0 fw-bold">Nombre completo</label>
                        <input type="text" className="form-control" name="name" value={contact.name} onChange={handleChangeInput} required/>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="email" className="form-label mb-0 fw-bold">Email</label>
                        <input type="email" className="form-control" name="email" value={contact.email} onChange={handleChangeInput} required/>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="phone" className="form-label mb-0 fw-bold">Teléfono</label>
                        <input type="phone" className="form-control" name="phone"  value={contact.phone} onChange={handleChangeInput} required/>
                    </div>
                    <div className="col-12">
                        <label htmlFor="subject" className="form-label mb-0 fw-bold">Escriba su consulta</label>
                        <textarea className="form-control" name="subject" rows="4" style={{ height: 'unset' }} value={contact.subject} onChange={handleChangeInput} required></textarea>
                    </div>
                    <div className="col-12">
                        <button className="btn btn-primary fs-6" type="button"  onClick={() => handlerButtonForm()}><FaRegPaperPlane className="me-2"/>Enviar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ContactForm;