import React, { useState, useEffect } from "react";
import { Context } from "./Context";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { FaWhatsapp, FaFacebook, FaTwitter, FaYoutube, FaInstagram, FaLinkedin } from "react-icons/fa";
import { SiTiktok } from "react-icons/si";
// Components
import { Home, QuienesSomos, Contacto, Team, Search, DetailState, News, DetailAdviser, 
  WishAdviser, ProjectDetail } from './pages'
import { LoginAdmin, UsersAdmin, AppAdmin, EstateAdmin, AdviserAdmin, 
  ProfileAdmin, NewEstateAdmin, EditEstateAdmin } from './pages/admin/index'
import { ToastContainer } from 'react-toastify';
import './App.scss';
import ProtectedRoute from './auth/ProtectedRoute';
import ProtectedAdminRoute from './auth/ProtectedAdminRoute'
import { baseService } from './constants';
import { getCurrentUser, showLoading, hideLoading } from './Helper';
import { WrapperLoading } from './components/loading/WrapperLoading';
import { http } from './services/HttpAxios'

const App = () => {
  const appTitle = 'Sky Group Invictus'
  const routerRef = React.createRef();
  const [userCurrent, setUserCurrent] = useState({});
  const [global, setGlobal] = useState({})
  const iconsSocial = {
    whatsapp: FaWhatsapp,
    youtube: FaYoutube,
    twitter: FaTwitter,
    facebook: FaFacebook,
    tiktok: SiTiktok,
    instagram: FaInstagram,
    linkedin: FaLinkedin
  }

  const getInfoGeneral = () => {
    showLoading()
    http.get('/enterprise/all')
    .then((response) => {
        const data = response.data.data;
        setGlobal({...data});
        hideLoading();
    }, () => {
        hideLoading();
    });
  }

  useEffect(() => {
    getInfoGeneral()
  }, []);

  return (
    <Context.Provider
      value={{
        user: getCurrentUser() || userCurrent,
        baseUrl: baseService,
        setUserCurrent: setUserCurrent,
        iconsSocial: iconsSocial,
        global: global,
        appTitle: appTitle
      }}>
      <Router ref={routerRef} basename={process.env.PUBLIC_URL}>
        <div className="App">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/nosotros" component={QuienesSomos} />
            <Route exact path="/contacto" component={Contacto} />
            <Route exact path="/equipo" component={Team} />
            <Route exact path="/buscar"  component={Search} />
            <Route exact path="/noticias"  component={News} />
            <Route exact path="/inmueble/:id"  component={DetailState} />
            <Route exact path="/admin/auth"  component={LoginAdmin} />
            <Route exact path="/asesor"  component={WishAdviser} />
            <Route exact path="/asesor/:id"  component={DetailAdviser} />
            <Route exact path="/proyecto/:id"  component={ProjectDetail} />
            <ProtectedAdminRoute exact path="/admin/users"  component={UsersAdmin} />
            <ProtectedAdminRoute exact path="/admin/app"  component={AppAdmin} />
            <ProtectedRoute exact path="/admin/inmuebles"  component={EstateAdmin} />
            <ProtectedAdminRoute exact path="/admin/asesores"  component={AdviserAdmin} />
            <ProtectedRoute exact path="/admin/perfil"  component={ProfileAdmin} />
            <ProtectedRoute exact path="/admin/inmueble/crear"  component={NewEstateAdmin} />
            <ProtectedRoute exact path="/admin/inmueble/editar/:id"  component={EditEstateAdmin} />
          </Switch>
          <WrapperLoading/>
          <ToastContainer/>
        </div>
      </Router>
    </Context.Provider>
  )
}

export default App;
