import React from "react";
import { FaProjectDiagram, FaPlusCircle }  from "react-icons/fa";

const NewsSet = props => {
    const { searchNews, addNewNews } = props

    return (<>
        <h5 className="text-secondary fw-bold px-3 mt-2 mb-0">
            <FaProjectDiagram/> Noticias
            <div className="btn-group float-end" role="group">
                <button onClick={addNewNews} type="button" className="btn btn-outline-secondary d-flex align-items-center">
                    <FaPlusCircle className="me-1 font-16"/>Agregar noticia
                </button>
            </div>
        </h5>
        <div className="row mx-0 mt-4">
            <div className="col-md-12 mb-1">
                <div className="form-group has-search mb-0">
                    <span className="fas fa-search form-control-feedback mt-1 ml-1"></span>
                    <input className="form-control input-form w-100 input-big" onChange={searchNews} placeholder="Buscar noticias"></input>
                </div>
            </div>
        </div>
        <div className="container">
            <table className="table table-hover w-100 mt-2 bg-white rounded wrap"
                id="dataTableNews"
                cellSpacing="0"
            />
        </div>
    </>);
}

export default NewsSet; 