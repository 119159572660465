import React, { useEffect, useState } from "react";
import { FaSearch, FaTimes, FaFilter } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { getImageBackend, formatNumeric } from './../Helper'
import $ from 'jquery';

const ListProperties = props => {
    const { properties, baseUrl, params, setQueryParams, labelLocaction, labelAdviser, setLabelLocation, setLabelAdviser, handlerSidebar } = props
    const history = useHistory();

    const showDetail = (property) => {
        history.push(`/inmueble/${property.public_id}`)
    }

    const clearFilter = (name) => {
        setQueryParams(prevState => ({
            ...prevState,
            [name]: ''
        }))
        if (name === 'locations') setLabelLocation('')
        if (name === 'advisers') setLabelAdviser('')
    }

    const handlerSearch = (e) => {
        const currentText = $('#inputSearch').val();
        setQueryParams(prevState => ({
            ...prevState,
            search: e.target.value || currentText
        }))
    }

    useEffect(() => {
        const currentParam = new URLSearchParams(params)
        if (currentParam.has('location') && currentParam.get('location')) {
            setQueryParams(prevState => ({
                ...prevState,
                search: currentParam.get('location')
            }))
            $('#inputSearch').val(currentParam.get('location'))
        }
    }, [params]);

    return (
        <div className="app-list-properties container mb-5">
            <h1 className="text-center py-3 app-title">PROPIEDADES DISPONIBLES</h1>
            <nav className="navbar navbar-expand-lg mt-3 mb-0 bg-white d-flex justify-content-start">
                <div className="d-flex align-items-center mb-0 headSearch">
                    <FaSearch className="fsearch global-cursor-pointer" onClick={handlerSearch}/>
                    <input type="text" id="inputSearch" className="form-control border" ref={element=>(element||{}).onchange=handlerSearch}  
                    autoComplete={'off'} placeholder={`Buscar inmuebles`}></input>
                </div>
            </nav>
            <div className="d-flex pl-md-2">
                <span className={`badge badge-pill badge-grey font-14 align-items-center text-400 px-3 text-primary mx-1 ${labelLocaction  ? 'd-flex' : 'd-none'}`}>
                    {labelLocaction} <FaTimes className="ms-2 global-cursor-pointer" onClick={() => clearFilter('locations')}/>
                </span>
                <span className={`badge badge-pill badge-grey font-14 align-items-center text-400 px-3 text-primary mx-1 ${labelAdviser  ? 'd-flex' : 'd-none'}`}>
                    {labelAdviser} <FaTimes className="ms-2 global-cursor-pointer" onClick={() => clearFilter('advisers')}/>
                </span>
            </div>
            <div className="d-block d-md-none text-right">
                <button type="button" onClick={() => handlerSidebar()} className={`float-end btn btn-light border-0 text-200 font-12 btnViewSelected`}>
                    <FaFilter className="fas fa-filter font-14 mr-1 text-primary"/>
                </button>
            </div>
            <div className="d-flex mb-2 justify-content-end" role="group" aria-label="Basic example">
                <span className="btn btn-light border-0 bg-white text-200 font-12">
                    <span className="text-primary fw-bold font-14">{properties.length}</span> Inmuebles encontrados
                </span>
            </div>
            <div className="row">
                {properties.map((property, i) => { 
                    return (<div className="col-md-6 mt-0 py-2 px-2" key={i} onClick={() => showDetail(property)}>
                    <div className="properties__main position-relative">
                        <div className="color-overlay"></div>
                        <img className="img-fluid w-100" src={getImageBackend(baseUrl, '/properties/', property.url)}></img>
                        <div className="properties__content">
                            <h3>{property.sector}</h3>
                            <p>${formatNumeric(property.price || 0, 0)}</p>
                            <span className="_button rounded-pill" onClick={() => showDetail(property)}>DETALLE</span>
                        </div>
                    </div>
                </div>)
                })}
            </div>
        </div>
    )
}

export default ListProperties;