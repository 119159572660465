import React from "react";
import { getImageBackend } from './../../Helper'
import { useHistory } from "react-router-dom";

const Adviser = props => {
    const { data, baseUrl, className } = props
    const history = useHistory();

    const showDetail = (id) => {
        history.push(`/asesor/${id}`)
    }

    return (
        <div className={`adviser ${className}`} onClick={() => showDetail(data.id)}>
            <div className="adviser__overlay"></div>
            <img loading="lazy" src={getImageBackend(baseUrl, '/advisers/', data.avatar)} className="d-block"/>
            <div className="adviser__name text-center w-100 text-uppercase">
                <p>{String(data.first_name).split(' ')[0]} <br/>
                {String(data.last_name)}
                </p>
                {data.properties && <p className="adviser__name__prop">{
                    data.properties.length === 0 ? 'Sin propiedades' : 
                    data.properties.length === 1 ? '1 Propiedad' : 
                    data.properties.length + ' Propiedades'
                }</p>}
            </div>
        </div>
    )
}

export default Adviser;