import React from "react";
import { getImageBackend } from "./../Helper";

const ListNews = props => {
    const { baseUrl, global, news } = props

    return (<>
        {(global && global['enterprise'] && global['enterprise']['url_banner_head']) && 
        <div className="app-team__photo2 position-relative">
            <div className="color-overlay"></div>
            <img src={getImageBackend(baseUrl, '/enterprise/', global['enterprise']['url_banner_head'])} className="d-block w-100 h-100"/>    
        </div>}
        {(news || []).length > 0 && <div className="app-team container mb-5">
            <h1 className="text-center py-4 app-title">Noticias</h1>
            {(news || []).map((item, i) => {
                return (<div className="row mb-5" key={i} id={item.id}>
                    <div className="col-md-6">
                        <div id={`carouser-detail-${i + 1}`} className="carouser-detail carousel slide carousel-fade global-cursor-pointer" data-bs-ride="carousel" data-bs-interval="false">
                            <div className="carousel-inner">
                                {(item.images || []).map((image, j) => {
                                    return (<div className={`carousel-item detail__caraousel__detail ${ j === 0 ? 'active' : '' }`} data-index={image.index} key={j}>
                                        <img src={getImageBackend(baseUrl, '/enterprise/', image.url)} className="d-block  w-auto mx-auto" alt="..."/>
                                    </div>)
                                })}
                                
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target={`#carouser-detail-${i + 1}`} data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target={`#carouser-detail-${i + 1}`} data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6"  id={item.id}>
                        <p className="app-title font-26 mb-2 text-uppercase">{item.title}</p>
                        <p className="text-muted mb-2 font-18">{String(item.date).split("T")[0]}</p>
                        <p className="font-18 mb-2 app-description">{item.description}</p>
                    </div>
                </div>)
            })}
        </div>}
    </>)
}

export default ListNews;