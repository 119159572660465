import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

const VerticalNet = props => {
    const { iconsSocial, global } = props;
    const [socials, setSocials] = useState([])
    //
    useEffect(() => {
        const currentSocials = (global && global.enterprise && global.enterprise.socials) ? global.enterprise.socials : []
        let calculateSocials = []
        for (const social of currentSocials) {
            if (social.status) {
                calculateSocials.push({
                    type: social.main.code,
                    link: social.url,
                    title: social.main.name
                })
            }
        }
        setSocials(calculateSocials)
    }, [props]);
    
    const styleLink = { color: 'inherit', textDecoration: 'inherit'}

    return (<>
        {iconsSocial && <div className="vertical-net" data-aos="fade-right">
            {socials.map((val, i) => { 
                const SpecificComponent = iconsSocial[val.type];
                return (<Link style={styleLink} title={val.title} target="_blank" to={{ pathname: val.link }} key={i}>
                    <SpecificComponent className="app-footer__icons"/>
                </Link>)
            })}
        </div>}
    </>)
}

export default VerticalNet;