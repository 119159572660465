import React from 'react'; 

const ModalCreateUser = props => {
    const { handlerCreate, title, data, handleChangeInput, options, showValidation } = props

    return (  
        <div className="modal fade" id="createUserModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-primary">
                        <h5 className="modal-title text-white" id="staticBackdropLabel">{title}</h5>
                        <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form name="newUserForm" id="newUserForm" autoComplete={'off'} className={`modal-body needs-validation ${showValidation ? 'was-validated' : ''}`}>
                        <div className="col-12">
                            <label htmlFor="name" className="form-label mb-0 fw-bold">Nombre</label>
                            <input type="text" className="form-control" name="name" value={data.name} onChange={handleChangeInput} required minLength={3}/>
                        </div>
                        <div className="col-12 mt-2">
                            <label htmlFor="email" className="form-label mb-0 fw-bold">Email</label>
                            <input type="email" className="form-control" name="email" value={data.email} onChange={handleChangeInput} required minLength={3}/>
                        </div>
                        <div className="col-12 mt-2">
                            <label htmlFor="role" className="form-label mb-0 fw-bold">Rol</label>
                            <select className="form-select form-control" name="role" aria-label="Default select example" onChange={handleChangeInput} value={data.role}>
                                {options.map((val, i) => { 
                                    return (<option key={i} value={val.value}>{val.label}</option>)
                                })}
                            </select>
                        </div>
                        <div className="col-12 mt-2">
                            <label htmlFor="password" className="form-label mb-0 fw-bold">Password</label>
                            <input autoComplete={"on"} type="password" className="form-control" name="password" value={data.password} onChange={handleChangeInput} required  minLength={5}/>
                            <div id="emailHelp" className="form-text">Inserte un password de al menos 5 caracteres</div>
                        </div>
                    </form>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
                        <button type="button" className="btn btn-primary" onClick={handlerCreate}>Crear</button>
                    </div>
                </div>
            </div>
        </div> 
    )  
}  

export default ModalCreateUser; 