import React, { useEffect } from "react";
import { Link, NavLink, useLocation } from 'react-router-dom';
import { AiOutlineMenu } from "react-icons/ai";
import $ from 'jquery';

const NavBar = props => {
    const { className } = props
    const location = useLocation();
    
    useEffect(() => {
        if (location.pathname) {
            // menu conocenos
            location.pathname === '/equipo' ? $('#dropdownMenuButton1').addClass('global-selected rounded-pill') :
            location.pathname === '/nosotros' ? $('#dropdownMenuButton1').addClass('global-selected rounded-pill') : 
            location.pathname === '/noticias' ? $('#dropdownMenuButton1').addClass('global-selected rounded-pill') : 
            $('#dropdownMenuButton1').removeClass('global-selected rounded-pill')
            // menu contacto
            location.pathname === '/contacto' ? $('#dropdownMenuButton2').addClass('global-selected rounded-pill') :
            location.pathname === '/asesor' ? $('#dropdownMenuButton2').addClass('global-selected rounded-pill') : 
            $('#dropdownMenuButton2').removeClass('global-selected rounded-pill')
        }
    }, [location]);

    return (
        <nav className={`${className} navbar navbar-expand-lg navbar-light bg-navbar py-0 w-100 z-over navbar-main`}>
            <div className="container-fluid container-navbar">
                <a className="navbar-brand me-auto d-none" href="#">
                    <img src="logo_white.png?v=2" alt="" width="100" height="100"/>
                </a>
                <button className="navbar-toggler float-end" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                    <AiOutlineMenu className="navbar-toggler-icon text-white"/>
                </button>
                <div className="collapse navbar-collapse" id="navbarTogglerDemo02" data-aos="fade-in">
                    <li className="nav-item px-2 text-center list-unstyled">
                        <Link to="/" className="navbar-brand navbar-brand2">
                            <img src="logo_white.png?v=2" width="160" height="120" alt=""></img>
                        </Link>
                    </li>
                    <ul className="navbar-nav mx-auto mb-2 mb-lg-0 mt-3 mt-md-0">
                        <li className="nav-item px-2 text-center my-auto">
                            <NavLink to="/" exact className="nav-link text-white text-300" activeClassName="global-selected rounded-pill">
                                Inicio
                            </NavLink>
                        </li>
                        <li className="nav-item px-2 text-center my-auto">
                            <NavLink to="/buscar" exact className="nav-link text-white text-300" activeClassName="global-selected rounded-pill">
                                Buscar
                            </NavLink>
                        </li>
                        <li className="nav-item px-2 text-center my-auto dropdown">
                            <a className="nav-link text-white text-300 dropdown-toggle global-cursor-pointer" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                Conócenos
                            </a>
                            <ul className="dropdown-menu dropdown__container" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <NavLink to="/nosotros" className="nav-link text-primary text-300">
                                        Quienes Somos
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/equipo" className="nav-link text-primary text-300">
                                        Nuestro Equipo
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/noticias" className="nav-link text-primary text-300">
                                        Noticias
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item px-2 text-center my-auto dropdown">
                            <a className="nav-link text-white text-300 dropdown-toggle global-cursor-pointer" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                Contacto
                            </a>
                            <ul className="dropdown-menu dropdown__container" aria-labelledby="dropdownMenuButton2">
                                <li>
                                    <NavLink to="/contacto" className="nav-link text-primary text-300">
                                        Vende con nosotros
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/asesor" className="nav-link text-primary text-300">
                                        Conviértete en asesor
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavBar;