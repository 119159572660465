import React from "react";
import { Editor } from 'react-draft-wysiwyg';
import { FaImage, FaSave, FaEdit, FaTrashAlt, FaRegClone, FaRegCompass, FaRegBuilding, FaAtlas, FaUsers }  from "react-icons/fa";

const InfoSet = props => {
    const { urlBannerHead, fileUploadHead, handlerBannerHead, urlBannerAdviser, fileUploadAdviser, handlerBannerAdviser,
        urlBanner2, fileUpload2, handlerBanner2, urlVideo2, defaultVideoUrl, fileUpload4, deleteVideoSecond,
        editorOverview, setEditorOverview, editorMision, setEditorMision, editorVision, setEditorVision, saveInfoEnterprise,
        setTextProject, textProject, urlBannerFooter, fileUploadFooter, handlerBannerFooter,
        setEditorAdviser, editorAdviser } = props

    return (<>
        <form className="row g-3 needs-validation mb-5 pt-3" id="formInfo" name="formInfo" noValidate>
            <div className="form-group mb-3 border">
                <label className="fw-bold my-4 font-16 px-2" htmlFor="name"><FaImage className="me-1"/>Banner Secundario</label>
                <div className="row">
                    <div className="col-12 position-relative mb-2">
                        <img src={urlBannerHead} className="img-fluid w-100 img-banner" alt="..." />    
                        <input name="file" type="file" ref={fileUploadHead} accept="image/gif,image/jpeg,image/jpg,image/png" id="imgStoreAvatar" onChange={(e) => handlerBannerHead(e.target.files)} className="form-control-file d-none"></input>
                        <button type="button" className="btn btn-outline-secondary position-absolute btn-banner d-flex align-items-center"  onClick={() => fileUploadHead.current.click()}>
                            <FaEdit className="me-1"/>Actualizar Banner
                        </button>
                    </div>
                </div>
                <p className="mb-0 text-muted fst-italic">Se recomienda imágenes de 570 x 240 px, con un peso menor a 2 MB</p>
            </div>

            <div className="form-group mb-3 border">
                <label className="fw-bold my-4 font-16 px-2" htmlFor="name"><FaImage className="me-1"/>Banner Asesores</label>
                <div className="row">
                    <div className="col-12 position-relative mb-2">
                        <img src={urlBannerAdviser} className="img-fluid w-100 img-banner" alt="..." />    
                        <input name="file" type="file" ref={fileUploadAdviser} accept="image/gif,image/jpeg,image/jpg,image/png" id="imgStoreAvatar" onChange={(e) => handlerBannerAdviser(e.target.files)} className="form-control-file d-none"></input>
                        <button type="button" className="btn btn-outline-secondary position-absolute btn-banner d-flex align-items-center"  onClick={() => fileUploadAdviser.current.click()}>
                            <FaEdit className="me-1"/>Actualizar Banner
                        </button>
                    </div>
                </div>
                <p className="mb-0 text-muted fst-italic">Se recomienda imágenes de 570 x 240 px, con un peso menor a 2 MB</p>
            </div>

            <div className="form-group mb-3 border">
                <label className="fw-bold my-4 font-16 px-2" htmlFor="name"><FaImage className="me-1"/>Banner Footer</label>
                <div className="row">
                    <div className="col-12 position-relative mb-2">
                        <img src={urlBannerFooter} className="img-fluid w-100 img-banner" alt="..." />    
                        <input name="file" type="file" ref={fileUploadFooter} accept="image/gif,image/jpeg,image/jpg,image/png" id="imgStoreAvatar" onChange={(e) => handlerBannerFooter(e.target.files)} className="form-control-file d-none"></input>
                        <button type="button" className="btn btn-outline-secondary position-absolute btn-banner d-flex align-items-center"  onClick={() => fileUploadFooter.current.click()}>
                            <FaEdit className="me-1"/>Actualizar Banner
                        </button>
                    </div>
                </div>
                <p className="mb-0 text-muted fst-italic">Se recomienda imágenes de 570 x 240 px, con un peso menor a 2 MB</p>
            </div>

            <div className="form-group mb-3 border">
                <label className="fw-bold my-4 font-16 px-2" htmlFor="name"><FaImage className="me-1"/>Banner Quienes somos</label>
                <div className="row">
                    <div className="col-md-6 position-relative mb-2">
                        <img src={urlBanner2} className="img-fluid w-100 img-banner2" alt="..." />    
                        <input name="file" type="file" ref={fileUpload2} accept="image/gif,image/jpeg,image/jpg,image/png" id="imgStoreAvatar" onChange={(e) => handlerBanner2(e.target.files, 'banner')} className="form-control-file d-none"></input>
                        <button type="button" className="btn btn-outline-secondary position-absolute btn-banner d-flex align-items-center"  onClick={() => fileUpload2.current.click()}>
                            <FaEdit className="me-1"/>Actualizar Banner
                        </button>
                    </div>
                    <div className="col-md-6 position-relative mb-2">
                        {!urlVideo2 && <img src={defaultVideoUrl} className="img-fluid w-100 img-banner2" alt="..." />}
                        {urlVideo2 && <video className="img-fluid w-100 img-banner2" controls >
                            <source src={urlVideo2} type="video/mp4"/>
                        </video>}
                        <div className="position-absolute btn-banner d-flex">
                            <button type="button" className="btn btn-outline-secondary d-flex align-items-center"  onClick={() => fileUpload4.current.click()}>
                                <FaEdit className="me-1"/>Actualizar Video
                            </button>
                            <button type="button" className="btn btn-outline-secondary d-flex align-items-center ms-1"  onClick={deleteVideoSecond}>
                                <FaTrashAlt className="me-1"/>Eliminar Video
                            </button>
                        </div>
                        <input name="file" type="file" ref={fileUpload4} accept="video/*" id="imgStoreAvatar" onChange={(e) => handlerBanner2(e.target.files, 'video')} className="form-control-file d-none"></input>
                    </div>
                </div>
                <p className="mb-0 text-muted fst-italic">Se recomienda imágenes de 570 x 240 px, con un peso menor a 2 MB</p>
            </div>
            
            <div className="form-group mb-3 border">
                <label className="fw-bold my-2 font-16 px-2" htmlFor="name"><FaRegBuilding className="me-1"/>Resumen de la empresa</label>
                <Editor
                    editorState={editorOverview}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={setEditorOverview}
                />
            </div>
            <div className="form-group mb-3 border">
                <label className="fw-bold my-2 font-16 px-2" htmlFor="name"><FaRegClone className="me-1"/>Misión</label>
                <Editor
                    editorState={editorMision}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={setEditorMision}
                />
            </div>
            <div className="form-group mb-3 border">
                <label className="fw-bold my-2 font-16 px-2" htmlFor="name"><FaRegCompass className="me-1"/>Visión</label>
                <Editor
                    editorState={editorVision}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={setEditorVision}
                />
            </div>
            <div className="form-group mb-3 border">
                <label className="fw-bold my-2 font-16 px-2" htmlFor="name"><FaUsers className="me-1"/>¿Por qué trabajar con un Asesor Inmobiliario Invictus?</label>
                <Editor
                    editorState={editorAdviser}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={setEditorAdviser}
                />
            </div>
            <div className="form-group mb-3 border pb-2">
                <label className="fw-bold my-2 font-16 px-2" htmlFor="name"><FaAtlas className="me-1"/>Título para projectos</label>
                <input type="text" className="form-control" name="text_project" value={textProject}  onChange={(e) => setTextProject(e.target.value)} required/>
            </div>
            <div className="col-12 my-3">
                <button className="btn btn-primary d-flex align-items-center" type="button" onClick={saveInfoEnterprise}> <FaSave className="me-1"/> Guardar</button>
            </div>
        </form>
    </>);
}

export default InfoSet; 