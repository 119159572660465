import React  from 'react';  

const ModalMngMunicipalities = props => {
    const { handlerSubmit, data, handleChangeInput, countries, showValidation, states } = props

    return (  
        <div className="modal fade" id="mngMunicipalitiesModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className={`modal-header ${data.type === 'edit' ? 'bg-warning' : 'bg-primary'}`}>
                        <h5 className={`modal-title ${data.type === 'edit' ? 'text-dark' : 'text-white'}`} id="staticBackdropLabel">{data.title}</h5>
                        <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form name="mngMunicipalities" id="mngMunicipalities" autoComplete={'off'} className={`modal-body needs-validation ${showValidation ? 'was-validated' : ''}`}>
                        <div className="col-12">
                            <label htmlFor="name" className="form-label mb-1 fw-bold">País</label>
                            <select name="country_id" className="form-control form-select" required value={data.country_id} onChange={handleChangeInput}>
                                <option disabled value="">Seleccionar</option>
                                {countries.map((val, i) => { 
                                    return (<option key={i} value={val.id}>{val.name}</option>)
                                })}
                            </select>
                        </div>
                        <div className="col-12">
                            <label htmlFor="name" className="form-label mb-1 fw-bold">Estado</label>
                            <select name="state_id" className="form-control form-select" required value={data.state_id} onChange={handleChangeInput}>
                                <option disabled value="">Seleccionar</option>
                                {states.map((val, i) => { 
                                    return (<option key={i} value={val.id}>{val.name}</option>)
                                })}
                            </select>
                        </div>
                        <div className="col-12">
                            <label htmlFor="name" className="form-label mb-1 fw-bold">Nombre</label>
                            <input type="text" className="form-control" name="name" value={data.name} onChange={handleChangeInput} required/>
                        </div>
                    </form>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
                        <button type="button" className={`btn ${data.type === 'edit' ? 'btn-warning text-dark' : 'btn-primary'}`} onClick={handlerSubmit}>Guardar</button>
                    </div>
                </div>
            </div>
        </div> 
    )  
}  

export default ModalMngMunicipalities; 