import $ from 'jquery';

export function showLoading() {
    $("#wrapper-loadingWheel").fadeIn(125);
}

export function hideLoading() {
    $("#wrapper-loadingWheel").fadeOut(125);
}

export function getCurrentUser()  {
    let currentUser = localStorage.getItem("user");
    currentUser = currentUser ? JSON.parse(currentUser) : null;
    return currentUser;
}

export function getAvatarUrl(baseUrl, urlAvatar, defaultUrl = 'assets/img/profile.png') {
    if (urlAvatar) {
        if (urlAvatar.indexOf("http://") === 0 || urlAvatar.indexOf("https://") === 0) {
            return urlAvatar;
        } else {
            return `${baseUrl}/users/${urlAvatar}`
        }
    } else {
        return defaultUrl;
    }
}

export function getImageBackend(baseBack, baseUrl ,urlImage, defaultUrl = null) {
    if (urlImage) {
        if (urlImage.indexOf("http://") === 0 || urlImage.indexOf("https://") === 0) {
            return urlImage;
        } else {
            return `${baseBack}${baseUrl}${urlImage}`
        }
    } else {
        return defaultUrl;
    }
}


export function formatNumeric($val, $decimals = 2) {
    return numberWithCommas(parseFloat($val).toFixed($decimals));
}

export function groupByArray(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

export function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
}

export function hasDuplicates(array = []) {
    return (new Set(array)).size !== array.length;
}

export function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
  
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)
  
    return new Blob([ia], { type: mimeString })
}

export const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;   
        resolve(base64data);
      }
    });
}

export const formatText = (text) => {
    const newText = String(text).replace(/\s+/g, " ").toLocaleLowerCase().trim()
    return newText
}