import React, { useState, useEffect } from "react";
import withContext from '../withContext'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { getImageBackend } from '../Helper'
import { useHistory } from "react-router-dom";

const NewsHome = props => {
    const { projects, baseUrl, global } = props;
    const history = useHistory();
    const [arrayBanners, setArrayBanners] = useState([])

    //
    useEffect(() => {
        setArrayBanners(projects || [])
    }, [props]);

    //
    const seeProject = id => {
        history.push(`/noticias?id=${id}`)
    }

    return (<div className="container py-5 miami">
        <div className="miami__title mb-2">
            <span className="text-uppercase text-center">Noticias</span>
        </div>
        {arrayBanners.length > 0 && <OwlCarousel items={1}  
            className="owl-theme owl-miami" 
            data-aos="fade-left"
            loop = {true}
            mouseDrag = {true}
            nav = {true}
            margin = {0}
            dots = {true}
            navText = {[
                '<svg class="navowl owlleft" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg"><path d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path></svg>', 
                '<svg class="navowl owlright" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg"><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>'
            ]}>
            {arrayBanners.map((val, i) => (
                <div key={i} className="item position-relative owl-miami__item" onClick={() => seeProject(val.id)}>
                    <div className="color-overlay"></div>
                    <h3 className="position-absolute owl-miami__item--title">{val.title}</h3>
                    <p className="position-absolute owl-miami__item--description">"{val.description.split(/\r?\n/)[0]}"</p>
                    <img  className="img" alt="Banner1" src={getImageBackend(baseUrl, '/enterprise/', val.images[0]['url'])}/>
                </div> 
            ))}
        </OwlCarousel>}
    </div>)
}

export default withContext(NewsHome);