/* eslint-disable import/first */
import React, { useState, useEffect } from "react";
import { FaEdit, FaInfoCircle, FaTrashAlt, FaPlusCircle, FaExternalLinkAlt } from "react-icons/fa";
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';
import { useHistory } from "react-router-dom";
//
import { HeaderAdmin, SidebarAdmin } from '../../components/admin'
import { ModalDelete, ModalDetailEstate, ModalWarning } from "../../components/modals";
import { getImageBackend, showLoading, hideLoading, getCurrentUser, formatNumeric } from './../../Helper'
import { http } from './../../services/HttpAxios'
import withContext from './../../withContext'
//
import $, { map } from 'jquery'
$.DataTable = require('datatables.net-bs');

const EstateAdmin = props => {
    const iconsSocial = props.context.iconsSocial
    const history = useHistory();
    const currentUser = getCurrentUser();
    const options = [
        { value: 'active', label: 'Activo' },
        { value: 'close', label: 'Inactivo' }
    ]
    //
    const [currentTable, setCurrentTable] = useState(null);
    const [advisers, setAdvisers] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false);
    const [deleteEstate, setDeleteEstate] = useState({ id: 0, title: '', body: '' })
    const [detailProperty, setDetailProperty] = useState({})
    const [statusProperty, setStatusProperty] = useState({ id: 0, title: '', body: '', type: '' })
    const [counts, setCounts] = useState({ total: 0, active: 0, inactive: 0 })

    //
    const loadTable = (dataSet = []) => {
        dataSet.map(element => {
            let advisers = [];
            for (const adviser of element.advisers) {
                advisers.push(`${adviser.first_name} ${adviser.last_name}`)
            }
            element.advisers_name = advisers.join(', ')
        })
        //
        if ($.fn.DataTable.isDataTable('#dataTable')) {
            if (currentTable) {
                currentTable.clear();
                currentTable.rows.add(dataSet);
                currentTable.draw();
            }
        } else {
            let dataTable = $('#dataTable').DataTable({
                dom: 'rtp',
                columns: [
                    {
                        data: null,
                        title: "",
                        className: "align-middle text-center",
                        width: "70px"
                    },
                    { 
                        title: "Nombre", 
                        data: 'title',
                        className: "align-middle text-capitalize",
                    },
                    { 
                        title: "Tipo", 
                        data: null,
                        className: "align-middle",
                        render: function(data, type, row) {
                            return `<span class="text-capitalize">${data.negotiation.name} / ${data.type.name}</span>`;
                        }
                    },
                    { 
                        title: "Price", 
                        data: null,
                        className: "align-middle fw-bold",
                        render: function(data, type, row) {
                            return `<span class="text-success"">$${formatNumeric(data.price, 0)}</span>`;
                        }
                    },
                    { 
                        title: "Direccion", 
                        data: null,
                        className: "align-middle",
                        render: function(data, type, row) {
                            return `<span class="text-capitalize">${data.sector}</span>`;
                        }
                    },
                    { 
                        title: "Asesor", 
                        data: null,
                        className: "align-middle",
                        render: function(data, type, row) {
                            return `<span>${data.advisers_name}</span>`;
                        }
                    },
                    { 
                        title: "Destacado", 
                        data: null,
                        className: "align-middle text-center",
                        render: function(data, type, row) {
                            return `<div class="form-check form-switch ps-2">
                                        <input class="form-check-input btnFeature" type="checkbox" id="feature_${data.id}" ${data.is_feature ? 'checked' : ''}>
                                        <label class="form-check-label" for="feature_${data.id}"></label>
                                    </div>`
                        }
                    },
                    { 
                        title: "Activo", 
                        data: null,
                        className: "align-middle text-center",
                        render: function(data, type, row) {
                            return `<div class="form-check form-switch ps-2">
                                        <input class="form-check-input btnStatus" type="checkbox" id="status_${data.id}" ${data.status === 'ACTIVE' ? 'checked' : ''}>
                                        <label class="form-check-label" for="status_${data.id}"></label>
                                    </div>`
                        }
                    },
                    {
                        data: null,
                        title: "",
                        className: "align-middle text-center",
                        width: "10%"
                    },
                    {
                        data: 'status',
                        visible: false,
                    },
                    {
                        data: 'advisers_name',
                        visible: false,
                    },
                    {
                        data: 'description',
                        visible: false,
                    },
                    {
                        data: 'code',
                        visible: false,
                    }
                ],
                columnDefs: [
                    {
                        targets: 0,
                        createdCell: (td, data, rowData, row, col) => {
                            ReactDOM.render(
                                <div className="nowrap">
                                        <img className="me-2" src={getImageBackend(props.context.baseUrl, '/properties/', data.images[0].url)} width="70" height="70" alt=""/>
                                </div>, td)},
                    },
                    {
                        targets: 8,
                        createdCell: (td, data, rowData, row, col) => {
                            ReactDOM.render(
                                <div className="nowrap">
                                        <a className="me-1 global-cursor-pointer detailEstate" title="Detalle del inmueble"><FaInfoCircle className="text-info font-16"/></a>
                                        <a className="me-1 global-cursor-pointer editEstate" title="Editar inmueble"><FaEdit className="text-warning font-16"/></a>
                                        <a className="me-1 global-cursor-pointer deleteEstate" title="Eliminar inmueble"><FaTrashAlt className="text-danger font-16"/></a>
                                        <a className="me-1 global-cursor-pointer" target="_blank" rel="noopener noreferrer" href={`inmueble/${data.public_id}`} title="Abrir"><FaExternalLinkAlt className="text-dark font-16"/></a>
                                    </div>, td)},
                    }
                ],
                "language": {
                    "paginate": {
                        "previous": "<i className='fa fa-chevron-left'>",
                        "next": "<i className='fa fa-chevron-right'>"
                    },
                    "emptyTable": "Inmuebles no encontrados"
                },
                data: dataSet,
                paging: false,
                pageLength: 10,
                responsive: true
            }).columns.adjust();
            setCurrentTable(dataTable);
            //Click actions
            $('#dataTable tbody').on('click', '.deleteEstate', function() {
                const rowLink = $(this).parent().parent().parent();
                const selectedEstate = dataTable.row(rowLink).data();
                setDeleteEstate({ 
                    id: selectedEstate.id, 
                    title: 'Eliminar Inmueble', 
                    body: `¿Está seguro de que desea eliminar el inmueble "${selectedEstate.title}"?` 
                })
                $('#deleteModal').modal('show');
            });
            //
            $('#dataTable tbody').on('click', '.editEstate', function() {
                const rowLink = $(this).parent().parent().parent();
                const selectedEstate = dataTable.row(rowLink).data();
                history.push(`/admin/inmueble/editar/${selectedEstate.id}`)
            });
            //
            $('#dataTable tbody').on('click', '.detailEstate', function() {
                const rowLink = $(this).parent().parent().parent();
                const selectedEstate = dataTable.row(rowLink).data();
                getDetailProperty(selectedEstate.id)
            });
            //
            $('#dataTable tbody').on('click', '.btnStatus', function() {
                const rowLink = $(this).parent().parent().parent();
                const selectedPropery = dataTable.row(rowLink).data();
                setStatusProperty({
                    type: 'status',
                    id: selectedPropery.id,
                    title: 'Status del Inmueble',
                    body: '¿Está seguro de que desea actualizar el status del inmueble?'
                })
                $('#warningModal').modal('show');
            });
            //
            $('#dataTable tbody').on('click', '.btnFeature', function() {
                const rowLink = $(this).parent().parent().parent();
                const selectedPropery = dataTable.row(rowLink).data();
                setStatusProperty({
                    type: 'feature',
                    id: selectedPropery.id,
                    title: 'Inmueble destacado',
                    body: '¿Está seguro de que desea actualizar este dato del inmueble?'
                })
                $('#warningModal').modal('show');
            });
        }
        
    }

    const handlerCreateEstate = () => {
        history.push("/admin/inmueble/crear")
    }

    const handlerDelete = () => {
        showLoading()
        http.delete(`/properties/${deleteEstate.id}`,
        { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`} })
        .then((response) => {
            const message = response.data.message;
            $('#deleteModal').modal('hide');
            hideLoading();
            toast.success(message, {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000
            });
            setDataLoaded(!dataLoaded)
        }, (error) => {
            const message = error.response.data.message
            toast.error(message || "Error al eliminar inmueble, intente nuevamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
            hideLoading();
        });
    }

    // get advisers options
    const getAllAdvisers = () => {
        showLoading();
        http.get('/advisers/all')
        .then((response) => {
            const data = response.data.data;
            setAdvisers(data.advisers);
        });
    }

    useEffect(() => {
        getAllAdvisers();
    }, []);

    useEffect(() => {
        getAllProperties();
    }, [dataLoaded]);

    const getAllProperties = () => {
        showLoading();
        http.get('/properties/all')
        .then((response) => {
            const data = response.data.data;
            loadTable(data);
            let counters = { total: 0, active: 0, inactive: 0 }
            for (let index = 0; index < (data || []).length; index++) {
                counters.total += 1
                data[index]["status"] == "ACTIVE" ? counters.active += 1 : counters.inactive += 1
            }
            setCounts({...counters})
            hideLoading();
        }, () => {
            hideLoading();
            loadTable([])
        });
    }

    const setStatusFilter = (e) => {
        let { name, value } = e.target
        if (name === 'status') {
            if (value == 'all') value = ''
            if (currentTable) currentTable.columns(9).search(value).draw();
        } else if (name === 'adviser') {
            if (value == 'all') value = ''
            if (currentTable) currentTable.columns(10).search(value).draw();
        } else {
            if (currentTable) currentTable.search(value).draw();
        }
    }

    const getDetailProperty = (id) => {
        showLoading();
        http.get(`/properties/${id}`)
        .then((response) => {
            const data = response.data.data;
            setDetailProperty({...data})
            $('#previewEstateModal').modal('show');
            hideLoading();
        }, (error) => {
            const message = error.response.data.message
            toast.error(message || `Error, intente nuevamente!`, {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
            hideLoading();
        });
    }

    const changeStatusProperty = async() => {
        showLoading()
        try {
            await http.put(`/properties/${statusProperty.id}/${statusProperty.type}`, { 
                status: $(`#status_${statusProperty.id}`).is(":checked") ? 'ACTIVE' : 'CLOSE',
                is_feature: $(`#feature_${statusProperty.id}`).is(":checked"),
            },
            { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`}})
            hideLoading();
            toast.success("Infomarción actualizada exitosamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 1500
            });
            $('#warningModal').modal('hide');
            setDataLoaded(!dataLoaded)
        } catch {
            hideLoading();
            toast.error("Error al actualizar, intente nuvamente", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
        }
    }

    const closeModalWarning = () => {
        if (statusProperty.type === 'status') $(`#status_${statusProperty.id}`).prop('checked', !$(`#status_${statusProperty.id}`).is(":checked"));
        if (statusProperty.type === 'feature') $(`#feature_${statusProperty.id}`).prop('checked', !$(`#feature_${statusProperty.id}`).is(":checked"));
        $('#warningModal').modal('hide');
    }

    return (<>
        <div className="search">
            <HeaderAdmin/>
            <div className="d-flex bg-white" id="wrapper">
                <SidebarAdmin/>
                <div className="page-content-wrapper bg-admin container-fluid px-md-5 mt-3">
                    <h2 className="text-primary mt-2 mb-4 fw-bold">Inmuebles
                        <button type="button" className="btn btn-outline-secondary float-end d-flex align-items-center" onClick={handlerCreateEstate}>
                            <FaPlusCircle className="me-1 font-16"/>Agregar inmueble
                        </button>
                    </h2>
                    <div className="d-block">
                        <p className="mb-1 d-flex align-items-center">Total: <span className="badge bg-success ms-1">{counts.total}</span></p>
                        <p className="mb-1 d-flex align-items-center">Activos: <span className="badge bg-info ms-1">{counts.active}</span></p>
                        <p className="mb-4 d-flex align-items-center">Inactivos: <span className="badge bg-danger ms-1">{counts.inactive}</span></p>
                    </div>
                    <div className="row mx-0">
                        <div className="col-md-6 ps-md-0 mb-3">
                            <div className="form-group has-search mb-0">
                                <span className="fas fa-search form-control-feedback mt-1 ml-1"></span>
                                <input className="form-control input-form w-100 input-big" name="text" onChange={setStatusFilter} placeholder="Buscar Inmueble"></input>
                            </div>
                        </div>
                        <div className="col-md-3 form-inline ps-0 mb-3">
                            <div className="form-group w-100">
                                <select className="form-select form-control" name="status" aria-label="Default select example" onChange={setStatusFilter} defaultValue={'all'}>
                                    <option value={'all'}>Selecionar status</option>
                                    {options.map((val, i) => { 
                                        return (<option key={i} value={val.value}>{val.label}</option>)
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3 form-inline px-0 mb-3">
                            <div className="form-group w-100">
                                <select className="form-select form-control" name="adviser" aria-label="Default select example" onChange={setStatusFilter} defaultValue={'all'}>
                                    <option value={'all'}>Seleccionar Asesor</option>
                                    {advisers.map((val, i) => { 
                                        return (<option key={i} value={`${val.first_name} ${val.last_name}`}>{val.first_name} {val.last_name}</option>)
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="container card">
                        <table className="table table-hover w-100 mt-2 bg-white rounded wrap"
                            id="dataTable"
                            cellSpacing="0"
                        />
                    </div>
                </div>
            </div>
        </div>
        <ModalDelete
            title={deleteEstate.title}
            body={deleteEstate.body}
            handlerDelete={handlerDelete}
        />
        <ModalDetailEstate
            detailEstate={detailProperty}
            iconsSocial={iconsSocial}
            baseUrl={props.context.baseUrl}
        />
        <ModalWarning
            title={statusProperty.title}
            body={statusProperty.body}
            handlerClose={closeModalWarning}
            handlerAction={changeStatusProperty}                       
        />
    </>
    )
}

export default withContext(EstateAdmin);