import React, { useState, useEffect } from "react";
import { FaChevronRight, FaChevronUp, FaWindowClose } from "react-icons/fa";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import $ from 'jquery';

const Sidebar = props => {
    const { typeProperties, typeNegotiations, locations, advisers, setQueryParams,
        setLabelLocation, setLabelAdviser, labelLocaction, labelAdviser, params } = props
    const maxLocations = 3;
    const maxAdvisers = 3;
    const defaultStepValue = 100;
    const defaultMaxValue = 100000000;
    const [filteredLocations, setFilteredLocations] = useState([]);
    const [filteredAdvisers, setFilteredAdvisers] = useState([]);
    const [showLocations, setShowLocations] = useState(false)
    const [showAdvisers, setShowAdvisers] = useState(false)
    const [value, setValue] = useState({ min: '', max: '' });
    const [selectedProperties, setSelectedProperties] = useState([])
    const [selectedOperations, setSelectedOperations] = useState([])

    useEffect(() => {
        const filtred = (locations || []).slice(0, maxLocations);
        const filtred2 = (advisers|| []).slice(0, maxAdvisers);
        setFilteredLocations(filtred);
        setFilteredAdvisers(filtred2);
    }, [locations, advisers]);

    useEffect(() => {
        if (showLocations) {
            setFilteredLocations(locations);
        } else {
            const filtred = (locations || []).slice(0, maxLocations);
            setFilteredLocations(filtred);
        }
    }, [showLocations]);

    useEffect(() => {
        if (showAdvisers) {
            setFilteredAdvisers(advisers);
        } else {
            const filtred2 = (advisers || []).slice(0, maxAdvisers);
            setFilteredAdvisers(filtred2);
        }
    }, [showAdvisers]);

    useEffect(() => {
        if (value.min < 0) setValue(prev => ({...prev, min: 0}))
    }, [value]);

    useEffect(() => {
        const currentParam = new URLSearchParams(params)
        if (currentParam.has('operations') || currentParam.has('properties')) {
            if (currentParam.has('operations') && currentParam.get('operations') && !selectedOperations.includes(currentParam.get('operations'))) {
                selectedOperations.push(currentParam.get('operations'))
                setSelectedOperations([...selectedOperations])
            }
            if (currentParam.has('properties') && currentParam.get('properties') && !selectedProperties.includes(currentParam.get('properties'))) {
                selectedProperties.push(currentParam.get('properties'))
                setSelectedProperties([...selectedProperties])
            }
        }
    }, [params]);

    useEffect(() => {
        setQueryParams(prevState => ({
            ...prevState,
            operations: selectedOperations.join(","),
            properties: selectedProperties.join(",")
        }))
    }, [selectedProperties, selectedOperations]);

    const handlerRangeInput = (value) => { setValue(value) }

    const handlerInputPrice = (event) => {
        let currentValue = Number(event.target.value);
        setValue({ min: currentValue, max: value.max })
    }

    const handlerInputPriceMax = (event) => {
        let currentValue = Number(event.target.value);
        value.min = value.min || 0;
        setValue({ min: value.min, max: currentValue })
    }

    //
    const handlerProperties = (name) => {
        let value = name.split("_")[1]
        let includeElement = selectedProperties.filter(function(element){ 
            return element === value;
        });
        if (includeElement && includeElement.length > 0) {
            let newPropertie = selectedProperties.filter(function(element){ 
                return element != value;
            });
            setSelectedProperties(newPropertie);
        } else {
            setSelectedProperties(oldArray => [...oldArray, value]);
        }
    }

    //
    const handlerOperations = (name, operationName) => {
        let value = name.split("_")[1]
        let includeElement = selectedOperations.filter(function(element){ 
            return element === value;
        });
        if (includeElement && includeElement.length > 0) {
            let newPropertie = selectedOperations.filter(function(element){ 
                return element != value;
            });
            setSelectedOperations(newPropertie);
        } else {
            setSelectedOperations(oldArray => [...oldArray, value]);
        }
    }

    //
    const handlerQuery = (type, valor, name) => {
        setQueryParams(prevState => ({
            ...prevState,
            [type]: valor
        }))
        if (type === 'locations') setLabelLocation(name)
        if (type === 'advisers') setLabelAdviser(name)
    } 

    const handlerPrice = () => {
        const valueSearch = (value.min || 0) + ',' + (value.max || defaultMaxValue);
        setQueryParams(prevState => ({
            ...prevState,
            price: valueSearch
        }))
    }

    const handlerButtonClose = () => {
        $('#wrapper').toggleClass('toggled');
        $('.page-content-wrapper').removeClass('d-flex').toggleClass('d-none');
        $('#sidebar-wrapper').toggleClass('vw-100');
    }
    
    return (
        <div className="bg-white pl-2 pr-1 mt-3" id="sidebar-wrapper">
        <div className="mx-0 px-4 d-flex d-md-none cursor-pointer text-right" onClick={handlerButtonClose}>
            <span className="font-18 ms-auto pr-2">Cerrar</span><FaWindowClose className="font-26 text-dark ms-2"/>
        </div>

        {!labelLocaction && <div  className={`sidebar-heading`}>
            <p className="h5 mb-3 fw-bold">Ubicación</p>
            {filteredLocations.map((val, i) => (
                <li key={i} className="d-flex justify-content-between align-items-center mb-2">
                    <a onClick={() => handlerQuery('locations', val.id, val.name)} className="h6 text-dark mb-0 global-cursor-pointer">{val.name}</a>
                    <small className="text-primary badge badge-grey fw-bold">{val.counting}</small>
                </li>
            ))}
            {(locations || []).length > 3 && <div className="d-grid gap-2">
                <button onClick={()=> setShowLocations(prev => !prev) } className="ver-mas btn btn-grey fw-bold btn-rounded mt-2 d-flex justify-content-between align-items-center">
                    {!showLocations ? 'Todas las ubicaciones' : 'Mostrar menos'}
                    {!showLocations ? <FaChevronRight className="float-right"/> : <FaChevronUp/>}
                </button>
            </div>}
        </div>}

        <div className={`sidebar-heading`}>
            <p className="h5 mb-3 fw-bold">Operación</p>
            {typeNegotiations.map((val, i) => (
                <div className="form-group form-check d-flex align-items-center mb-1 px-0" key={i}>
                    <div className="container-check me-2">
                        <input type="checkbox" checked={selectedOperations.includes(String(val.id))} className="form-check-input" id={`operation_${val.id}`} name={`operation_${val.id}`}  onChange={(e) => handlerOperations(e.target.name, val.name)}></input>
                        <label className="form-check-label checkmark" htmlFor={`operation_${val.id}`}></label>
                    </div>
                    <div className="h6 mb-0">{val.name}</div>
                </div> 
            ))}
        </div>

        <div className={`sidebar-heading`}>
            <p className="h5 mb-3 fw-bold">Tipo de propiedad</p>
            {typeProperties.map((val, i) => (
                <div className="form-group form-check d-flex align-items-center mb-1 px-0" key={i}>
                    <div className="container-check me-2">
                        <input type="checkbox" checked={selectedProperties.includes(String(val.id))} className="form-check-input" id={`property_${val.id}`} name={`property_${val.id}`}  onChange={(e) => handlerProperties(e.target.name)}></input>
                        <label className="form-check-label checkmark" htmlFor={`property_${val.id}`}></label>
                    </div>
                    <div className="h6 mb-0">{val.name}</div>
                </div>
            ))}
        </div>

        {!labelAdviser && <div  className={`sidebar-heading`}>
            <p className="h5 mb-3 fw-bold">Asesor</p>
            {filteredAdvisers.map((val, i) => (
                <li key={i} className="d-flex justify-content-between align-items-center mb-2">
                    <a onClick={() => handlerQuery('advisers', val.id, val.name)} className="h6 text-dark mb-0 global-cursor-pointer">{val.name}</a>
                    <small className="text-primary badge badge-grey fw-bold">{val.counting}</small>
                </li>
            ))}
            {(advisers || []).length > 3 && <div className="d-grid gap-2">
                <button onClick={()=> setShowAdvisers(prev => !prev) } className="ver-mas btn btn-grey fw-bold btn-rounded mt-2 d-flex justify-content-between align-items-center">
                    {!showAdvisers ? 'Todos los asesores' : 'Mostrar menos'}
                    {!showAdvisers ? <FaChevronRight className="float-right"/> : <FaChevronUp/>}
                </button>
            </div>}
        </div>}

        <div className={`sidebar-heading`}>
            <p className="h5 mb-3 fw-bold">Precio ($)</p>
            <InputRange
                maxValue={defaultMaxValue}
                minValue={0}
                step={defaultStepValue}
                value={value}
                formatLabel={value => ''}
                onChange={handlerRangeInput} />
            <div className="d-flex mt-3 align-items-center w-100">
                <div className="form-group me-2 w-50">
                    <label className="h6 font-weight-bold" htmlFor="valueMin">Min</label>
                    <input type="number" className="form-control" step={defaultStepValue} min={0} max={defaultMaxValue} id="valueMin" value={value.min} onChange={handlerInputPrice}></input>
                </div>
                <span className="h6 mt-4">-</span>
                <div className="form-group ms-2 w-50">
                    <label className="h6 font-weight-bold" htmlFor="valueMax">Max</label>
                    <input type="number" className="form-control" step={defaultStepValue} min={0} max={defaultMaxValue} id="valueMax" value={value.max} onChange={handlerInputPriceMax}></input>
                </div>
            </div>
            <div className={`sidebar-heading mb-3 row mx-0 px-0`}>
                <button className="btn btn-primary btn-rounded col-12" onClick={handlerPrice}>Buscar</button>
                <button onClick={() => setValue({ min: '', max: '' })} className="btn btn-secondary btn-rounded col-12 mt-1">Restaurar</button>
            </div>
        </div>
    </div>
    )
}

export default Sidebar;