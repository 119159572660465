import React from "react";
import { VerticalNet, NavBar, Footer, Copyright } from '../components/commons'
import { InfoEnterprise } from '../components'
import withContext from "../withContext";
import { Helmet } from 'react-helmet';

const QuienesSomos = props => {
    const { iconsSocial, global, baseUrl, appTitle } = props.context

    return (<><Helmet>
            <title>{appTitle} | Quienes Somos</title>
            <link rel="canonical" href="/nosotros"></link>
        </Helmet>
        <div className="nosotros position-relative">
            <NavBar className={"position-absolute bg-transparent"}/>
            <InfoEnterprise
                global={global}
                baseUrl={baseUrl}
            />
            <Footer
                global={global}
                baseUrl={baseUrl}
            />
            <Copyright/>
            <VerticalNet 
                iconsSocial={iconsSocial} 
                global={global}
            />
        </div>
    </>)
}

export default withContext(QuienesSomos);
