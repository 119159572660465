import React, { Component } from "react";

export class WrapperLoading extends Component {
    render()  
    {  
      return (
        <div className="loading-panel-wrapper" id="wrapper-loadingWheel">
            <div className="loading-panel-dialog ">
                <div className="spinner-border avatar-lg text-primary m-2" role="status"></div>
            </div>
        </div>
      )  
    }
}

export default WrapperLoading; 