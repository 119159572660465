import React, { useState, useEffect } from "react";
import { FaMapMarkerAlt, FaRegBuilding, FaPhoneAlt, FaMailBulk, FaHashtag } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { getImageBackend, formatNumeric } from './../../Helper'


const Footer = props => {
    const { global, baseUrl } = props;
    const history = useHistory();
    const [whereFindUs, setWhereFindUs] = useState({ address: '', phone: '', email: '', name: '' })
    const [recentsProperties, setRecentsProperties] = useState([])
    const [imgFooter, setImgFooter] = useState('./assets/img/footer.jpg')

    const showDetail = (property) => {
        history.push(`/inmueble/${property.public_id}`)
    }

    //
    useEffect(() => {
        const currentEnterprise = (global && global.enterprise) ? global.enterprise : {}
        setWhereFindUs({
            name: currentEnterprise['name'] || '',
            email: currentEnterprise['email'] || '',
            phone: currentEnterprise['phone'] || '',
            address: currentEnterprise['address'] || ''
        })
        //
        const currentRecients = (global && global.recients) ? global.recients : []
        setRecentsProperties(currentRecients)
        //
        if (currentEnterprise.url_banner_footer) setImgFooter(getImageBackend(baseUrl, '/enterprise/', currentEnterprise.url_banner_footer))
    }, [props]);

    return (
        <div className="w-100 mx-0 py-4 px-2 app-footer app-img-bg" style={{backgroundImage: `url(${imgFooter})`,
        backgroundAttachment: 'fixed',
        backgroundBlendMode: 'luminosity, normal',
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
        WebkitBackgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'}}>
            <div className="row mx-0">
                <div className="col-md-3 ps-4 container mt-2 mt-md-0" data-aos="fade-right">
                    <h4 className="app-footer__title mt-3 mb-3">Dónde encontrarnos</h4>
                    <div className="px-2">
                        <p className="text-white">
                            <FaRegBuilding className="me-2"/>{whereFindUs.address}
                        </p>
                        <p className="text-white">
                            <FaPhoneAlt className="me-2"/>{whereFindUs.phone}
                        </p>
                        <p className="text-white">
                            <FaMailBulk className="me-2"/>{whereFindUs.email}
                        </p>
                        <p className="text-white">
                            <FaHashtag className="me-2"/> {whereFindUs.name}
                        </p>
                    </div>
                </div>
                <div className="col-md-3 container mt-2 mt-md-0" data-aos="zoom-in">
                    <h4 className="app-footer__title mt-3 mb-3">Inmuebles recientes</h4>
                    {recentsProperties.map((val, i) => { 
                        return (<div className="d-flex media align-items-center mb-3" key={i}>
                            <img className="me-2 rounded"  src={getImageBackend(baseUrl, '/properties/', val.images[0]['url'])} width="80" height="60" alt=""/>
                            <div className="media-body">
                                <p className="my-0 text-white fw-bold text-truncate text-break-spaces text-decoration-underline global-cursor-pointer"  onClick={() => showDetail(val)}>{val.title}</p>
                                <p className="my-0 text-truncate text-white text-break-spaces"><FaMapMarkerAlt/> {val.municipality.name}, {val.state.name}</p>
                            </div>
                        </div>)
                    })}
                </div>
                <div className="col-md-3 container mt-2 mt-md-0" data-aos="fade-left">
                    <h4 className="app-footer__title mt-3 mb-3">¿Cómo podemos ayudarte?</h4>
                    <ul>
                        <li className="app-footer__links"><Link to={'/buscar'}>Propiedades en venta</Link></li>
                        <li className="app-footer__links"><Link to={'/contacto'}>Contáctanos</Link></li>
                        <li className="app-footer__links"><Link to={'/asesor'}>Forma parte de nuestro equipo</Link></li>
                        <li className="app-footer__links"><Link to={'/noticias'}>Noticias</Link></li>
                    </ul>
                </div>
                <div className="col-md-2 px-0 text-center mt-2 mt-md-0" data-aos="fade-left">
                    <img src="/assets/img/logo_white.png?v=2" width="200" height="170" alt="logo"/>
                    <img className="img-camara" src="/assets/img/camara3.png" alt="logo2"/>
                </div>
            </div>
        </div>
    )
}

export default Footer;