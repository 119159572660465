// Libraries
import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import { FaEdit, FaLock, FaTrashAlt, FaPlusCircle } from "react-icons/fa";
// Components
import { HeaderAdmin, SidebarAdmin } from '../../components/admin'
import { ModalCreateUser, ModalDelete, ModalEditUser, ModalPasswordUser } from "../../components/modals";
import { showLoading, hideLoading, getAvatarUrl } from "../../Helper";
import { http } from './../../services/HttpAxios'
import withContext from "../../withContext";
// JQuery
import $ from 'jquery'
$.DataTable = require('datatables.net-bs');
window.jQuery = $;

const UsersAdmin = props => {
    // Constants
    const currentUser = props.context.user;
    const options = [
        { label: 'ADMIN', value: 'ADMIN' },
        { label: 'USER', value: 'USER' }
    ]
    // Hooks
    const [showValidation, setShowValidation] = useState(false);
    const [currentTable, setCurrentTable] = useState(null);
    const [user, setUser] = useState({ title: '', name: '', email: '', role: 'USER', password: '', id: null })
    const [userDelete, setUserDelete] = useState({ title: '', id: '', body: '' })
    const [password, setPassword] = useState({ id: 0, password1: '', password2: '' })
    const [dataLoaded, setDataLoaded] = useState(false);
    
    // Load table
    const loadTable = (dataSet = []) => {
        if ($.fn.DataTable.isDataTable('#dataTable')) {
            if (currentTable) {
                currentTable.clear();
                currentTable.rows.add(dataSet);
                currentTable.draw();
            }
        } else {
            let dataTable = $('#dataTable').DataTable({
                dom: 'rtp',
                columns: [
                    {
                        data: null,
                        title: "",
                        className: "align-middle text-center",
                        width: "45px"
                    },
                    { 
                        title: "Nombre", 
                        data: 'name',
                        className: "align-middle",
                    },
                    { 
                        title: "Email", 
                        data: 'email',
                        className: "align-middle",
                    },
                    { 
                        title: "Rol", 
                        data: null,
                        className: "align-middle",
                        render: function(data, type, row) {
                            return `<span class="badge bg-dark rounded-pill">${data.role}</span>`;
                        }
                    },
                    {
                        data: null,
                        title: "",
                        className: "align-middle text-center",
                        width: "10%"
                    }
                ],
                columnDefs: [
                    {
                        targets: 0,
                        createdCell: (td, data, rowData, row, col) => {
                            ReactDOM.render(
                                <div className="nowrap">
                                        <img className="me-2 rounded-circle" src={getAvatarUrl(props.context.baseUrl, data.avatar)} width="40" height="40" alt=""/>
                                </div>, td)},
                    },
                    {
                        targets: 4,
                        createdCell: (td, data, rowData, row, col) => {
                            ReactDOM.render(
                                <div className="nowrap">
                                        <a className="me-1 global-cursor-pointer passwordUser" title="Cambiar contraseña"><FaLock className="text-secondary font-16"/></a>
                                        <a className="me-1 global-cursor-pointer editUser" title="Editar usuario"><FaEdit className="text-warning font-16"/></a>
                                        <a className="me-1 global-cursor-pointer deleteUser" title="Eliminar usuarioi"><FaTrashAlt className="text-danger font-16"/></a>
                                    </div>, td)},
                    }
                ],
                "language": {
                    "paginate": {
                        "previous": "<i className='fa fa-chevron-left'>",
                        "next": "<i className='fa fa-chevron-right'>"
                    },
                    "emptyTable": "Usuarios no encontrados"
                },
                data: dataSet,
                paging: false,
                pageLength: 10,
                responsive: true
            }).columns.adjust();
            setCurrentTable(dataTable);
            //Click actions
            $('#dataTable tbody').on('click', '.deleteUser', function() {
                const rowLink = $(this).parent().parent().parent();
                const selectedUser = dataTable.row(rowLink).data();
                setShowValidation(false);
                setUserDelete({ 
                    id: selectedUser.id, 
                    title: 'Eliminar usuario', 
                    body: `¿Está seguro de que desea eliminar al usuario "${selectedUser.name}"?` 
                })
                $('#deleteModal').modal('show');
            });
            //
            $('#dataTable tbody').on('click', '.editUser', function() {
                const rowLink = $(this).parent().parent().parent();
                const selectedUser = dataTable.row(rowLink).data();
                setShowValidation(false);
                setUser({ 
                    title: 'Editar usuario', 
                    name: selectedUser.name, 
                    email: selectedUser.email, 
                    role: selectedUser.role,
                    id: selectedUser.id
                })
                $('#editUserModal').modal('show');
            });
            //
            $('#dataTable tbody').on('click', '.passwordUser', function() {
                const rowLink = $(this).parent().parent().parent();
                const selectedUser = dataTable.row(rowLink).data();
                setShowValidation(false);
                setPassword({ 
                    id: selectedUser.id, 
                    password1: '', 
                    password2: ''
                })
                $('#passwrodUserModal').modal('show');
            });
        }
        
    }

    useEffect(() => {
        getAllUsers()
    }, [dataLoaded]);

    // Method to get All users
    const getAllUsers = () => {
        showLoading();
        http.get('/users/all')
        .then((response) => {
            const data = response.data.data;
            loadTable(data);
            hideLoading();
        }, () => {
            hideLoading();
            loadTable([])
        });
    }

    // Method to create user
    const handlerCreate = () => {
        setShowValidation(true);
        const result = $('#newUserForm')[0].checkValidity();
        if (!result) return false;
        // Call api
        showLoading()
        http.post(`/users/create`, user,
        { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`} })
        .then((response) => {
            const message = response.data.message;
            $('#createUserModal').modal('hide');
            hideLoading();
            toast.success(message, {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000
            });
            setDataLoaded(!dataLoaded)
        }, (error) => {
            const message = error.response.data.message
            toast.error(message || "Error al guardar usuario, intente nuevamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
            hideLoading();
        });
    }

    // Methdo to delete user
    const handlerDelete = () => {
        showLoading()
        http.delete(`/users/${userDelete.id}`,
        { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`} })
        .then((response) => {
            const message = response.data.message;
            $('#deleteModal').modal('hide');
            hideLoading();
            toast.success(message, {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000
            });
            setDataLoaded(!dataLoaded)
        }, (error) => {
            const message = error.response.data.message
            toast.error(message || "Error al eliminar usuario, intente nuevamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
            hideLoading();
        });
    }

    const handlerEdit = () => {
        setShowValidation(true);
        const result = $('#editUserForm')[0].checkValidity();
        if (!result) return false;
        // Call api
        showLoading()
        http.put(`/users/${user.id}`, user,
        { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`} })
        .then((response) => {
            const message = response.data.message;
            $('#editUserModal').modal('hide');
            hideLoading();
            toast.success(message, {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000
            });
            setDataLoaded(!dataLoaded)
        }, (error) => {
            const message = error.response.data.message
            toast.error(message || "Error al actualizar usuario, intente nuevamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
            hideLoading();
        });
    }

    const handlerPassword = () => {
        setShowValidation(true);
        const result = $('#passwordUserForm')[0].checkValidity();
        if (!result) return false;
        // Call api
        showLoading()
        http.put(`/users/password/${password.id}`, password,
        { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`} })
        .then((response) => {
            const message = response.data.message;
            $('#passwrodUserModal').modal('hide');
            hideLoading();
            toast.success(message, {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000
            });
            setDataLoaded(!dataLoaded)
        }, (error) => {
            const message = error.response.data.message
            toast.error(message || "Error al actualizar password, intente nuevamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
            hideLoading();
        });
    }

    // Handler Inputs change
    const handleChangeInput = e => {
        const { name, value } = e.target;
        setUser(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangePass = e => {
        const { name, value } = e.target;
        setPassword(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Open Modal to create user
    const openCreateUser = () => {
        setShowValidation(false);
        setUser({ title: 'Agregar nuevo usuario', name: '', email: '', role: 'USER', password: '', id: null })
        $('#createUserModal').modal('show');
    }

    // Search users
    const searchUsers = (e) => {
        if (currentTable) currentTable.search(`${e.target.value}`).draw();
    }

    // Filter role
    const setStatusFilter = (e) => {
        const roles = e.target.value == 'all' ? '' : e.target.value;
        if (currentTable) currentTable.search(roles).draw();
    }

    return (<>
        <div className="search">
            <HeaderAdmin/>
            <div className="d-flex bg-white" id="wrapper">
                <SidebarAdmin/>
                <div className="page-content-wrapper bg-admin container-fluid px-md-5 mt-3">
                    <h2 className="text-primary mt-2 mb-4 fw-bold">Gestiona a tus Administradores
                        <button type="button" className="btn btn-outline-secondary float-end d-flex align-items-center" onClick={openCreateUser}>
                            <FaPlusCircle className="me-1 font-16"/>Agregar usuario
                        </button>
                    </h2>
                    <div className="row mx-0">
                        <div className="col-md-10 ps-md-0 mb-3">
                            <div className="form-group has-search mb-0">
                                <span className="fas fa-search form-control-feedback mt-1 ml-1"></span>
                                <input className="form-control input-form w-100 input-big" onChange={searchUsers} placeholder="Buscar Usuarios"></input>
                            </div>
                        </div>
                        <div className="col-md-2 form-inline px-0 mb-3">
                            <div className="form-group w-100">
                                <select className="form-select form-control" name="role" aria-label="Default select example" onChange={setStatusFilter} defaultValue={'all'}>
                                    <option value={'all'}>-- TODOS --</option>
                                    {options.map((val, i) => { 
                                        return (<option key={i} value={val.value}>{val.label}</option>)
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="container card">
                        <table className="table table-hover w-100 mt-2 bg-white rounded wrap"
                            id="dataTable"
                            cellSpacing="0"
                        />
                    </div>
                </div>
            </div>
        </div>
        <ModalCreateUser
            title={user.title}
            data={user}
            options={options}
            handlerCreate={handlerCreate}
            handleChangeInput={handleChangeInput}
            showValidation={showValidation}
        />
        <ModalDelete
            title={userDelete.title}
            body={userDelete.body}
            handlerDelete={handlerDelete}
        />
        <ModalEditUser
            title={user.title}
            data={user}
            options={options}
            handlerEdit={handlerEdit}
            handleChangeInput={handleChangeInput}
            showValidation={showValidation}
            setShowValidation={setShowValidation}
        />
        <ModalPasswordUser
            data={password}
            handlerPassword={handlerPassword}
            handleChangeInput={handleChangePass}
            showValidation={showValidation}
        />
        </>
    )
}

export default withContext(UsersAdmin);