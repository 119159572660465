import React from "react";
import { getImageBackend, formatNumeric } from './../../Helper'
import { BiBed, BiBath, BiCar, BiHomeAlt } from "react-icons/bi";
import { Adviser } from './../commons'

const PreviewState = props => {
    const {  detail, baseUrl, global } = props

    return (<>
        {(global && global['enterprise'] && global['enterprise']['url_banner_head']) && 
        <div className="app-team__photo2 position-relative">
            <div className="color-overlay"></div>
            <img src={getImageBackend(baseUrl, '/enterprise/', global['enterprise']['url_banner_head'])} className="d-block w-100 h-100"/>    
        </div>}
        <div className="container-fluid mt-4">
            <div className="row mt-2">
                <div className="col-md-8">
                    <div id="carouser-detail" className="carousel slide carousel-fade global-cursor-pointer" data-bs-ride="carousel" data-bs-interval="false">
                        <div className="carousel-inner">
                            {(detail.images || []).map((image, j) => {
                                return (<div className={`carousel-item detail__caraousel__detail ${ j === 0 ? 'active' : '' }`} data-index={image.index} key={j}>
                                    <img src={getImageBackend(baseUrl, '/properties/', image.url)} className="d-block w-auto mx-auto" alt="..."/>
                                </div>)
                            })}
                            
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouser-detail" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouser-detail" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="row mt-3 mt-md-0">
                        <div className="col-md-12">
                            <div className="card-body pb-0 pt-1">
                                <div className="row mx-0">
                                    <div className="col-md-12 px-0 detail__caraousel__info">
                                        <p className="h3 text-primary text-uppercase mb-0">{detail.title}</p>
                                    </div>
                                    <div className="col-md-12 px-0 mt-1">
                                        <p className="h1 text-primary fw-bold text-uppercase mb-0">$ {formatNumeric(detail.price, 0)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="card-body pt-0">
                                <div className="row mt-4">
                                    <p className="text-muted fw-bold pe-0 text-uppercase detail__caraousel__info">Tipo de negociación:
                                        <span className="fw-light ps-2">{detail.negotiation.name}</span>
                                    </p>
                                </div>
                                <div className="row mt-2">
                                    <p className="text-muted fw-bold pe-0 text-uppercase">Tipo de propiedad:
                                        <span className="fw-light ps-2">{detail.type.name}</span>
                                    </p>
                                </div>
                                <div className="row mt-2">
                                    <p className="text-muted fw-bold pe-0 text-uppercase detail__caraousel__info">Ubicación:
                                        <span className="fw-light ps-2">{detail.state.name} - {detail.municipality.name}</span>
                                    </p>
                                </div>
                                <div className="row mt-2 detail__caraousel__icons">
                                    <div className="col-md-6 d-flex align-items-center">
                                        <BiHomeAlt className="font-26 text-muted"/>
                                        <span className="ps-2">{formatNumeric(detail.meters, 0)} m<sup>2</sup> </span>
                                    </div>
                                    <div className="col-md-6 d-flex align-items-center">
                                        <BiBed className="font-26 text-muted"/>
                                        <span className="ps-2">{detail.rooms} {detail.rooms > 1 ? 'Habitaciones' : 'Habitación'}</span>
                                    </div>
                                </div>
                                <div className="row mt-4 detail__caraousel__icons">
                                    <div className="col-md-6 d-flex align-items-center">
                                        <BiBath className="font-26 text-muted"/>
                                        <span className="ps-2">{detail.bathrooms} {detail.bathrooms > 1 ? 'Baños' : 'Baño'}</span>
                                    </div>
                                    <div className="col-md-6 d-flex align-items-center">
                                        <BiCar className="font-26 text-muted"/>
                                        <span className="ps-2">{detail.parking} {detail.parking > 1 ? 'Puestos' : 'Puesto'} de estacionamiento</span>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <p className="text-muted fw-bold pe-0 text-uppercase">Código:
                                        <span className="fw-light ps-2">{detail.code}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-5 mt-3">
                <div className="col-md-6">
                    <p className="detail__caraousel__description mb-0 px-3 app-description">{detail.description}</p>
                </div>
                {(detail.advisers && detail.advisers.length == 1) && <div className="col-md-6 px-5 detail__caraousel__info  mt-5 mt-md-0">
                    <p className="h3 text-center fw-normal">Asesor Captador</p>
                    <Adviser
                        className={"adviser__property"}
                        data={detail.advisers[0]}
                        baseUrl={baseUrl}
                    />
                </div>}
                {(detail.advisers && detail.advisers.length > 1) && <div className="col-md-6 px-5 detail__caraousel__info  mt-5 mt-md-0">
                    <p className="h3 text-center fw-normal mt-3">Asesores Captadores</p>
                    <div className="row justify-content-between">
                        {detail.advisers.map((adviser, i) => {
                        return (
                            <div className="col-md-6 mt-2 mt-md-0 px-1" key={i}>
                                <Adviser
                                    className={"adviser__property"}
                                    data={adviser}
                                    baseUrl={baseUrl}
                                />
                            </div>)
                        })}
                    </div>
                </div>}
            </div>
        </div>
    </>)
}

export default PreviewState;
