import React, { useState } from "react";
import Sidebar from './Sidebar'
import ListProperties from './../ListProperties'
import { getImageBackend } from "./../../Helper";
import $ from 'jquery';

const Page = props => {
    const { typeProperties, typeNegotiations, locations, advisers, properties, baseUrl, setQueryParams, queryParams, global,
    params } = props
    const [labelLocaction, setLabelLocation] = useState('')
    const [labelAdviser, setLabelAdviser] = useState('')

    const handlerSidebar = () => {
        $('#wrapper').toggleClass('toggled');
        $('.page-content-wrapper').removeClass('d-flex').toggleClass('d-none');
        $('#sidebar-wrapper').toggleClass('vw-100');
    }

    return (
        <>
            {(global && global['enterprise'] && global['enterprise']['url_banner_head']) && 
            <div className="app-team__photo2 position-relative">
                <div className="color-overlay"></div>
                <img src={getImageBackend(baseUrl, '/enterprise/', global['enterprise']['url_banner_head'])} className="d-block w-100 h-100"/>    
            </div>}
            <div className="d-flex bg-white" id="wrapper">
                <Sidebar
                    typeProperties={typeProperties}
                    typeNegotiations={typeNegotiations}
                    locations={locations}
                    advisers={advisers}
                    setQueryParams={setQueryParams}
                    queryParams={queryParams}
                    labelLocaction={labelLocaction}
                    labelAdviser={labelAdviser}
                    setLabelLocation={setLabelLocation}
                    setLabelAdviser={setLabelAdviser}
                    params={params}
                />
                <ListProperties
                    properties={properties}
                    queryParams={queryParams}
                    setQueryParams={setQueryParams}
                    baseUrl={baseUrl}
                    labelLocaction={labelLocaction}
                    labelAdviser={labelAdviser}
                    setLabelLocation={setLabelLocation}
                    setLabelAdviser={setLabelAdviser}
                    handlerSidebar={handlerSidebar}
                    params={params}
                />
            </div>
        </>
    )
}

export default Page;