/* eslint-disable import/first */
import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { FaEdit, FaTrashAlt, FaRegTimesCircle, FaRegEdit } from "react-icons/fa";
// components
import { HeaderAdmin, SidebarAdmin } from '../../components/admin'
import { ProjectsSet, NumbersSet, InfoSet, SocialsSet, ContactSet, WebSet, NewsSet } from './../../components/admin/settings'
import { ModalDelete, ModalEditAdmin, ModalCreateAdmin, ModalMngStates, 
    ModalMngMunicipalities, ModalCreateProject, ModalCreateNew } from "../../components/modals";
import { Copyright } from '../../components/commons'
import { showLoading, hideLoading, getCurrentUser, getImageBackend, DataURIToBlob, getBase64FromUrl } from './../../Helper'
import withContext from "../../withContext"
import { http } from './../../services/HttpAxios'
// JQueryS
import $, { map } from 'jquery';
window.jQuery = $;
$.DataTable = require('datatables.net-bs');

const AppAdmin = props => {
    const global = props.context.global
    const currentUser = getCurrentUser()
    const baseURL = props.context.baseUrl
    const fileUpload = React.createRef();
    const fileUpload2 = React.createRef();
    const fileUpload3 = React.createRef();
    const fileUpload4 = React.createRef();
    const fileUploadAdviser = React.createRef();
    const fileUploadFooter = React.createRef();
    const fileUploadHead = React.createRef();
    const fileUploadCounts = React.createRef();
    const defaultVideoUrl = './assets/img/video.png'
    // hooks
    const [dataLoaded, setDataLoaded] = useState(false);
    const [currentTable, setCurrentTable] = useState(null);
    const [currentTableProperties, setCurrentTableProperties] = useState(null);
    const [currentTableOperations, setCurrentTableOperations] = useState(null);
    const [currentTableProjects, setCurrentTableProjects] = useState(null);
    const [currentTableNews, setCurrentTableNews] = useState(null);
    const [urlBanner, setUrlBanner] = useState('https://mdbootstrap.com/img/new/slides/041.jpg');
    const [urlBanner2, setUrlBanner2] = useState('./assets/img/nosotros.jpg');
    const [urlBannerAdviser, setUrlBannerAdviser] = useState('./assets/img/nosotros.jpg');
    const [urlBannerFooter, setUrlBannerFooter] = useState('./assets/img/footer.jpg');
    const [urlBannerHead, setUrlBannerHead] = useState('./assets/img/nosotros.jpg');
    const [urlBannerCounts, setUrlBannerCounts] = useState('./assets/img/background2.jpg');
    const [urlVideo, setUrlVideo] = useState(null);
    const [urlVideo2, setUrlVideo2] = useState(null);
    const [elementDelete, setElementDelete] = useState({ type: '', id: 0, title: '', body: '' })
    const [elementEdit, setElementEdit] = useState({ action: '', type: '', id: 0, title: '', data: '' })
    const [infoEdit, setInfoEdit] = useState({ name: '' })
    const [showValidation, setShowValidation] = useState(false);
    const [contact, setContact] = useState({ name: '', address: '', email: '', phone: '' })
    const [links, setLinks] = useState([])
    const [location, setLocation] = useState({ id: null, country_id: '', state_id: '', name: '', title: '', type: '' })
    const [locationValidation, setLocationValidation] = useState(false)
    const [textProject, setTextProject] = useState('')
    // locations
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [defaultStates, setDefaultStates] = useState([])
    // editors
    const [editorOverview, setEditorOverview] = useState(EditorState.createEmpty())
    const [editorMision, setEditorMision] = useState(EditorState.createEmpty())
    const [editorVision, setEditorVision] = useState(EditorState.createEmpty())
    const [editorAdviser, setEditorAdviser] = useState(EditorState.createEmpty())
    // counts
    const [counts, setCounts] = useState({ count_success_negotiations: 0, count_properties: 0, count_advisers: 0 })
    // projects
    const [project, setProject] = useState({ id: null, title: '', description: '', images: [] })
    const [actionProject, setActionProject] = useState('create')
    const [propertyImages, setPropertyImages] = useState([])
    // noticias
    const [news, setNews] = useState({ id: null, title: '', description: '', date: '', images: [] })
    const [actionNews, setActionNews] = useState('create')
    const [newsImages, setNewsImages] = useState([])

    // set data in editors 
    const setInfoEditor = (html) => {
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        return EditorState.createWithContent(contentState);
    }

    const loadTable = (dataSet = []) => {
        for (const data of dataSet) {
            data.muni = ''
            data.municipalities.map(mun => {
                data.muni += mun.name + ', '
            });   
        }
        if ($.fn.DataTable.isDataTable('#dataTable')) {
            if (currentTable) {
                currentTable.clear();
                currentTable.rows.add(dataSet);
                currentTable.draw();
            }
        } else {
            let dataTable = $('#dataTable').DataTable({
                dom: 'rtp',
                columns: [
                    { 
                        title: "Estado", 
                        data: 'name',
                        className: "align-middle text-center",
                    },
                    { 
                        title: "País", 
                        data: null,
                        className: "align-middle",
                        render: function(data, type, row) {
                            return `<span class="text-capitalize">${data.country.name}</span>`;
                        }
                    },
                    { 
                        title: "# Municipios", 
                        data: null,
                        className: "align-middle text-center",
                        render: function(data, type, row) {
                            const subcat = data.municipalities.length;
                            return `<span class="badge bg-success rounded-pill">${subcat}</span>`;
                        }
                    },
                    { 
                        title: "Municipios", 
                        data: null,
                        className: "align-middle",
                        width: "50%"
                    },
                    {
                        data: null,
                        title: "",
                        className: "align-middle text-center",
                        width: "10%"
                    },
                    {
                        data: "muni",
                        visible: false
                    }
                ],
                columnDefs: [{
                    targets: 4,
                    createdCell: (td, data, rowData, row, col) => {
                        ReactDOM.render(
                            <div className="nowrap">
                                    <a className="me-1 global-cursor-pointer editLocation" title="Editar"><FaEdit className="text-warning font-16"/></a>
                                    <a className="me-1 global-cursor-pointer deleteLocation" title="Eliminar"><FaTrashAlt className="text-danger font-16"/></a>
                                </div>, td)},
                  },
                  {
                    targets: 3,
                    createdCell: (td, data, rowData, row, col) => {
                        ReactDOM.render(
                            <div className="nowrap">
                                {(data.municipalities || []).map((val, i) => { 
                                    return (<span className="badge rounded-pill bg-primary me-1 mb-1 font-12 align-middle" key={i}>
                                        {val.name}
                                        <FaRegTimesCircle className="ms-1 font-14 global-cursor-pointer" onClick={() => deleteMunicipalities(val.id, val.name)}/>
                                        <FaRegEdit className="ms-1 font-14 global-cursor-pointer" onClick={() => editMunicipalities(val.id, val.name, val.state_id, data.country_id, dataSet)}/>
                                    </span>)
                                })}
                            </div>, td)},
                  }],
                "language": {
                    "paginate": {
                        "previous": "<",
                        "next": ">"
                    },
                },
                data: dataSet,
                paging: true,
                responsive: true,
                pageLength: 5
            }).columns.adjust();
            setCurrentTable(dataTable);
            //Click actions
            $('#dataTable tbody').on('click', '.deleteLocation', function() {
                const rowLink = $(this).parent().parent().parent();
                const selectedLocation = dataTable.row(rowLink).data();
                setElementDelete({ 
                    type: 'states', 
                    id: selectedLocation.id, 
                    title: 'Eliminar Estado', 
                    body: `¿Está seguro de que desea eliminar el estado "${selectedLocation.name}"?` 
                })
                $('#deleteModal').modal('show');
            });
            //
            $('#dataTable tbody').on('click', '.editLocation', function() {
                const rowLink = $(this).parent().parent().parent();
                const selectedLocation = dataTable.row(rowLink).data();
                setLocationValidation(false)
                setLocation({
                    id: selectedLocation.id,
                    name: selectedLocation.name,
                    country_id: selectedLocation.country_id,
                    title: 'Editar estado',
                    type: 'edit',
                    state_id: ''
                });
                $('#mngStatesModal').modal('show')
            });
        }
        
    }

    // Handler Inputs change
    const handleChangeInput = e => {
        const { name, value } = e.target;
        setInfoEdit(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const loadTableProperties = (dataSet = []) => {
        if ($.fn.DataTable.isDataTable('#dataTableProperties')) {
            if (currentTableProperties) {
                currentTableProperties.clear();
                currentTableProperties.rows.add(dataSet);
                currentTableProperties.draw();
            }
        } else {
            let dataTable = $('#dataTableProperties').DataTable({
                dom: 'rtp',
                columns: [
                    { 
                        title: "Nombre", 
                        data: 'name',
                        className: "align-middle text-left",
                        width: '80%'
                    },
                    {
                        data: null,
                        title: "",
                        className: "align-middle text-right",
                    }
                ],
                columnDefs: [{
                    targets: 1,
                    createdCell: (td, data, rowData, row, col) => {
                        ReactDOM.render(
                        <div className="nowrap">
                                <a className="me-1 global-cursor-pointer editProperty" title="Editar"><FaEdit className="text-warning font-16"/></a>
                                <a className="me-1 global-cursor-pointer deleteProperty" title="Eliminar"><FaTrashAlt className="text-danger font-16"/></a>
                            </div>, td)},
                }],
                "language": {
                    "paginate": {
                        "previous": "<i className='fa fa-chevron-left'>",
                        "next": "<i className='fa fa-chevron-right'>"
                    },
                },
                data: dataSet,
                paging: false,
                pageLength: 10,
                responsive: true
            }).columns.adjust();
            setCurrentTableProperties(dataTable);
            //Click actions
            $('#dataTableProperties tbody').on('click', '.deleteProperty', function() {
                const rowLink = $(this).parent().parent().parent();
                const selectedProperty = dataTable.row(rowLink).data();
                setElementDelete({ 
                    type: 'properties', 
                    id: selectedProperty.id, 
                    title: 'Eliminar Tipo de Propiedades', 
                    body: `¿Está seguro de que desea eliminar "${selectedProperty.name}" como tipo de propiedad?` 
                })
                $('#deleteModal').modal('show');
            });
            //
            $('#dataTableProperties tbody').on('click', '.editProperty', function() {
                const rowLink = $(this).parent().parent().parent();
                const selectedProperty = dataTable.row(rowLink).data();
                setElementEdit({ 
                    action: 'edit', 
                    type: 'properties', 
                    id: selectedProperty.id, 
                    title: 'Editar Tipo de Propiedades'
                })
                setInfoEdit({...selectedProperty})
                $('#editAdminModal').modal('show');
            });
        }
        
    }

    const loadTableOperations = (dataSet = []) => {
        if ($.fn.DataTable.isDataTable('#dataTableOperations')) {
            if (currentTableOperations) {
                currentTableOperations.clear();
                currentTableOperations.rows.add(dataSet);
                currentTableOperations.draw();
            }
        } else {
            let dataTable = $('#dataTableOperations').DataTable({
                dom: 'rtp',
                columns: [
                    { 
                        title: "Nombre", 
                        data: 'name',
                        className: "align-middle text-left",
                        width: '80%'
                    },
                    {
                        data: null,
                        title: "",
                        className: "align-middle text-right",
                    }
                ],
                columnDefs: [{
                    targets: 1,
                    createdCell: (td, data, rowData, row, col) => {
                        ReactDOM.render(
                        <div className="nowrap">
                                <a className="me-1 global-cursor-pointer editOperations" title="Editar estado"><FaEdit className="text-warning font-16"/></a>
                                <a className="me-1 global-cursor-pointer deleteOperations" title="Eliminar estado"><FaTrashAlt className="text-danger font-16"/></a>
                            </div>, td)},
                }],
                "language": {
                    "paginate": {
                        "previous": "<i className='fa fa-chevron-left'>",
                        "next": "<i className='fa fa-chevron-right'>"
                    },
                },
                data: dataSet,
                paging: false,
                pageLength: 10,
                responsive: true
            }).columns.adjust();
            setCurrentTableOperations(dataTable);
            //Click actions
            $('#dataTableOperations tbody').on('click', '.deleteOperations', function() {
                const rowLink = $(this).parent().parent().parent();
                const selectedOperation = dataTable.row(rowLink).data();
                setElementDelete({ 
                    type: 'operations', 
                    id: selectedOperation.id, 
                    title: 'Eliminar Tipo de Operación', 
                    body: `¿Está seguro de que desea eliminar "${selectedOperation.name}" como tipo de operación?` 
                })
                $('#deleteModal').modal('show');
            });
            //
            $('#dataTableOperations tbody').on('click', '.editOperations', function() {
                const rowLink = $(this).parent().parent().parent();
                const selectedOperation = dataTable.row(rowLink).data();
                setElementEdit({ 
                    action: 'edit', 
                    type: 'operations', 
                    id: selectedOperation.id, 
                    title: 'Editar Tipo de Operación'
                })
                setInfoEdit({...selectedOperation})
                $('#editAdminModal').modal('show');
            });
        }
        
    }

    const loadTableProjects = async(dataSet = []) => {
        if ($.fn.DataTable.isDataTable('#dataTableProjects')) {
            if (currentTableProjects) {
                currentTableProjects.clear();
                currentTableProjects.rows.add(dataSet);
                currentTableProjects.draw();
            }
        } else {
            let dataTable = $('#dataTableProjects').DataTable({
                dom: 'rtp',
                columns: [
                    {
                        data: null,
                        title: "",
                        className: "align-middle text-center",
                        width: "5%"
                    },
                    { 
                        title: "Titulo", 
                        data: 'title',
                        className: "align-middle text-capitalize",
                    },
                    { 
                        title: "Descripcion", 
                        data: null,
                        className: "align-middle",
                        render: function(data, type, row) {
                            return `<span>${String(data.description).substring(0, 80)}...</span>`;
                        }
                    },
                    {
                        data: null,
                        title: "",
                        className: "align-middle text-right",
                    }
                ],
                columnDefs: [
                    {
                        targets: 0,
                        width: 70,
                        createdCell: (td, data, rowData, row, col) => {
                            ReactDOM.render(
                                <div className="nowrap">
                                        <img className="me-2" src={getImageBackend(baseURL, '/enterprise/', data.images[0].url)} width="70" height="70" alt=""/>
                                </div>, td)},
                    },
                    {
                    targets: 3,
                    createdCell: (td, data, rowData, row, col) => {
                        ReactDOM.render(
                        <div className="nowrap">
                                <a className="me-1 global-cursor-pointer editProject" title="Editar proyecto"><FaEdit className="text-warning font-16"/></a>
                                <a className="me-1 global-cursor-pointer deleteProject" title="Eliminar proyecto"><FaTrashAlt className="text-danger font-16"/></a>
                            </div>, td)},
                }],
                "language": {
                    "paginate": {
                        "previous": "<i className='fa fa-chevron-left'>",
                        "next": "<i className='fa fa-chevron-right'>"
                    },
                    "emptyTable": "Proyectos no encontrados"
                },
                data: dataSet,
                paging: false,
                pageLength: 10,
                responsive: false
            }).columns.adjust();
            setCurrentTableProjects(dataTable);
            //
            $('#dataTableProjects tbody').on('click', '.editProject', async function() {
                const rowLink = $(this).parent().parent();
                const selectedProject = dataTable.row(rowLink).data();
                setActionProject('edit')
                setProject({ 
                    id: selectedProject.id, 
                    title: selectedProject.title, 
                    description: selectedProject.description
                })
                let imagesProperty = []
                for (const img of selectedProject.images) {
                    let newImage = {}
                    newImage.url = await getBase64FromUrl(getImageBackend(baseURL, '/enterprise/', img.url))
                    imagesProperty.push(newImage)
                }
                setPropertyImages(imagesProperty)
                $('#createProjectModal').modal('show');
            });
            //
            $('#dataTableProjects tbody').on('click', '.deleteProject', function() {
                const rowLink = $(this).parent().parent();
                const selectedProject = dataTable.row(rowLink).data();
                setElementDelete({ 
                    type: 'projects', 
                    id: selectedProject.id, 
                    title: 'Eliminar Proyecto', 
                    body: `¿Está seguro de que desea eliminar el proyecto "${selectedProject.title}"?` 
                })
                $('#deleteModal').modal('show');
            });
        }
        
    }

    const loadTableNews = async(dataSet = []) => {
        dataSet.map(value => {
            value.date = value.date ? String(value.date).split('T')[0] : ''
        })
        if ($.fn.DataTable.isDataTable('#dataTableNews')) {
            if (currentTableNews) {
                currentTableNews.clear();
                currentTableNews.rows.add(dataSet);
                currentTableNews.draw();
            }
        } else {
            let dataTable = $('#dataTableNews').DataTable({
                dom: 'rtp',
                columns: [
                    {
                        data: null,
                        title: "",
                        className: "align-middle text-center",
                        width: "10%"
                    },
                    { 
                        title: "Titulo", 
                        data: 'title',
                        className: "align-middle text-capitalize",
                    },
                    { 
                        title: "Fecha", 
                        data: 'date',
                        className: "align-middle text-capitalize",
                    },
                    { 
                        title: "Descripcion", 
                        data: null,
                        className: "align-middle",
                        render: function(data, type, row) {
                            return `<span>${String(data.description).substring(0, 80)}...</span>`;
                        }
                    },
                    {
                        data: null,
                        title: "",
                        className: "align-middle text-right",
                    }
                ],
                columnDefs: [
                    {
                        targets: 0,
                        width: 70,
                        createdCell: (td, data, rowData, row, col) => {
                            ReactDOM.render(
                                <div className="nowrap">
                                        <img className="me-2" src={getImageBackend(baseURL, '/enterprise/', data.images[0].url)} width="70" height="70" alt=""/>
                                </div>, td)},
                    },
                    {
                    targets: 4,
                    createdCell: (td, data, rowData, row, col) => {
                        ReactDOM.render(
                        <div className="nowrap">
                                <a className="me-1 global-cursor-pointer editNew" title="Editar noticia"><FaEdit className="text-warning font-16"/></a>
                                <a className="me-1 global-cursor-pointer deleteNew" title="Eliminar noticia"><FaTrashAlt className="text-danger font-16"/></a>
                            </div>, td)},
                }],
                "language": {
                    "paginate": {
                        "previous": "<i className='fa fa-chevron-left'>",
                        "next": "<i className='fa fa-chevron-right'>"
                    },
                    "emptyTable": "Noticias no encontradas"
                },
                data: dataSet,
                paging: false,
                pageLength: 10,
                responsive: false
            }).columns.adjust();
            setCurrentTableNews(dataTable);
            //
            $('#dataTableNews tbody').on('click', '.editNew', async function() {
                const rowLink = $(this).parent().parent();
                const selectedNew = dataTable.row(rowLink).data();
                setActionNews('edit')
                setNews({ 
                    id: selectedNew.id, 
                    title: selectedNew.title, 
                    description: selectedNew.description,
                    date: selectedNew.date
                })
                let imagesNews = []
                for (const img of selectedNew.images) {
                    let newImage = {}
                    newImage.url = await getBase64FromUrl(getImageBackend(baseURL, '/enterprise/', img.url))
                    imagesNews.push(newImage)
                }
                setNewsImages(imagesNews)
                $('#createNewModal').modal('show');
            });
            //
            $('#dataTableNews tbody').on('click', '.deleteNew', function() {
                const rowLink = $(this).parent().parent();
                const selectedNew = dataTable.row(rowLink).data();
                setElementDelete({ 
                    type: 'news', 
                    id: selectedNew.id, 
                    title: 'Eliminar Noticia', 
                    body: `¿Está seguro de que desea eliminar la noticia "${selectedNew.title}"?` 
                })
                $('#deleteModal').modal('show');
            });
        }
        
    }

    const handlerBanner = (selectorFiles, type) => {
        var reader = new FileReader();
        reader.onload = function() {
            var dataURL = reader.result;
            type === 'banner' ? setUrlBanner(dataURL) : setUrlVideo(dataURL);
            //
            showLoading();
            let form_data = new FormData();
            form_data.append('file', selectorFiles[0]);
            http.post(`/enterprise/${type}`, form_data,
            { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`}})
            .then(() => {
                hideLoading();
                toast.success("Banner guardado exitosamente!", {
                    position: toast.POSITION.BOTTOM_LEFT,
                    autoClose: 1500
                });
            }, () => {
                hideLoading();
                toast.error("La imagen no puede execeder los 2MB!", {
                    position: toast.POSITION.BOTTOM_LEFT,
                    autoClose: 2500
                });
            });
        }
        reader.readAsDataURL(selectorFiles[0]);
    }

    const handlerBanner2 = (selectorFiles, type) => {
        var reader = new FileReader();
        reader.onload = function() {
            var dataURL = reader.result;
            type === 'banner' ? setUrlBanner2(dataURL) : setUrlVideo2(dataURL);
            //
            showLoading();
            let form_data = new FormData();
            form_data.append('file', selectorFiles[0]);
            http.post(`/enterprise/${type}2`, form_data,
            { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`}})
            .then(() => {
                hideLoading();
                toast.success("Banner guardado exitosamente!", {
                    position: toast.POSITION.BOTTOM_LEFT,
                    autoClose: 1500
                });
            }, () => {
                hideLoading();
                toast.error("La imagen no puede execeder los 2MB!", {
                    position: toast.POSITION.BOTTOM_LEFT,
                    autoClose: 2500
                });
            });
        }
        reader.readAsDataURL(selectorFiles[0]);
    }

    const handlerBannerAdviser = (selectorFiles) => {
        var reader = new FileReader();
        reader.onload = function() {
            var dataURL = reader.result;
            setUrlBannerAdviser(dataURL);
            //
            showLoading();
            let form_data = new FormData();
            form_data.append('file', selectorFiles[0]);
            http.post(`/enterprise/banner-advisers`, form_data,
            { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`}})
            .then(() => {
                hideLoading();
                toast.success("Banner guardado exitosamente!", {
                    position: toast.POSITION.BOTTOM_LEFT,
                    autoClose: 1500
                });
            }, () => {
                hideLoading();
                toast.error("La imagen no puede execeder los 2MB!", {
                    position: toast.POSITION.BOTTOM_LEFT,
                    autoClose: 2500
                });
            });
        }
        reader.readAsDataURL(selectorFiles[0]);
    }

    const handlerBannerFooter = (selectorFiles) => {
        var reader = new FileReader();
        reader.onload = function() {
            var dataURL = reader.result;
            setUrlBannerFooter(dataURL);
            //
            showLoading();
            let form_data = new FormData();
            form_data.append('file', selectorFiles[0]);
            http.post(`/enterprise/banner-footer`, form_data,
            { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`}})
            .then(() => {
                hideLoading();
                toast.success("Banner guardado exitosamente!", {
                    position: toast.POSITION.BOTTOM_LEFT,
                    autoClose: 1500
                });
            }, () => {
                hideLoading();
                toast.error("La imagen no puede execeder los 2MB!", {
                    position: toast.POSITION.BOTTOM_LEFT,
                    autoClose: 2500
                });
            });
        }
        reader.readAsDataURL(selectorFiles[0]);
    }

    const handlerBannerHead = (selectorFiles) => {
        var reader = new FileReader();
        reader.onload = function() {
            var dataURL = reader.result;
            setUrlBannerHead(dataURL);
            //
            showLoading();
            let form_data = new FormData();
            form_data.append('file', selectorFiles[0]);
            http.post(`/enterprise/banner-head`, form_data,
            { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`}})
            .then(() => {
                hideLoading();
                toast.success("Banner guardado exitosamente!", {
                    position: toast.POSITION.BOTTOM_LEFT,
                    autoClose: 1500
                });
            }, () => {
                hideLoading();
                toast.error("La imagen no puede execeder los 2MB!", {
                    position: toast.POSITION.BOTTOM_LEFT,
                    autoClose: 2500
                });
            });
        }
        reader.readAsDataURL(selectorFiles[0]);
    }

    const handlerBannerCounts = (selectorFiles) => {
        var reader = new FileReader();
        reader.onload = function() {
            var dataURL = reader.result;
            setUrlBannerCounts(dataURL);
            //
            showLoading();
            let form_data = new FormData();
            form_data.append('file', selectorFiles[0]);
            http.post(`/enterprise/banner-counts`, form_data,
            { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`}})
            .then(() => {
                hideLoading();
                toast.success("Banner guardado exitosamente!", {
                    position: toast.POSITION.BOTTOM_LEFT,
                    autoClose: 1500
                });
            }, () => {
                hideLoading();
                toast.error("La imagen no puede execeder los 2MB!", {
                    position: toast.POSITION.BOTTOM_LEFT,
                    autoClose: 2500
                });
            });
        }
        reader.readAsDataURL(selectorFiles[0]);
    }

    useEffect(() => {
        http.get('/enterprise/options')
        .then((response) => {
            showLoading()
            const data = response.data.data;
            setCountries(data.countries)
            setDefaultStates(data.states)
        }, () => {
            hideLoading();
        });
    }, []);

    useEffect(() => {
        async function fetchMyAPI() {
            showLoading();
            try {
                const response = await http.get('/enterprise/info')
                const data = response.data.data;
                //set banncer
                data.locations ? loadTable(data.locations) : loadTable([]);
                data.properties ? loadTableProperties(data.properties) : loadTable([]);
                data.negotiations ? loadTableOperations(data.negotiations) : loadTable([]);
                data.enterprise ? setContact({...data.enterprise }) : setContact({ name: '', address: '', email: '', phone: '' })
                data.projects ? loadTableProjects(data.projects) : loadTableProjects([])
                data.news ? loadTableNews(data.news) : loadTableNews([])
                //
                if (data.enterprise) {
                    if (data.enterprise.overview) setEditorOverview(setInfoEditor(data.enterprise.overview))
                    if (data.enterprise.mision) setEditorMision(setInfoEditor(data.enterprise.mision))
                    if (data.enterprise.vision) setEditorVision(setInfoEditor(data.enterprise.vision))
                    if (data.enterprise.adviser) setEditorAdviser(setInfoEditor(data.enterprise.adviser))
                    if (data.enterprise.url_banner_main) setUrlBanner(getImageBackend(baseURL, '/enterprise/', data.enterprise.url_banner_main))
                    if (data.enterprise.url_video_main) setUrlVideo(getImageBackend(baseURL, '/enterprise/', data.enterprise.url_video_main))
                    if (data.enterprise.url_banner_secondary) setUrlBanner2(getImageBackend(baseURL, '/enterprise/', data.enterprise.url_banner_secondary))
                    if (data.enterprise.url_video_secondary) setUrlVideo2(getImageBackend(baseURL, '/enterprise/', data.enterprise.url_video_secondary))
                    if (data.enterprise.url_banner_advisers) setUrlBannerAdviser(getImageBackend(baseURL, '/enterprise/', data.enterprise.url_banner_advisers))
                    if (data.enterprise.url_banner_head) setUrlBannerHead(getImageBackend(baseURL, '/enterprise/', data.enterprise.url_banner_head))
                    if (data.enterprise.url_banner_numbers) setUrlBannerCounts(getImageBackend(baseURL, '/enterprise/', data.enterprise.url_banner_numbers))
                    if (data.enterprise.url_banner_footer) setUrlBannerFooter(getImageBackend(baseURL, '/enterprise/', data.enterprise.url_banner_footer))
                    if (data.enterprise.text_project) setTextProject(data.enterprise.text_project)
                    setCounts({
                        count_success_negotiations: data.enterprise.count_success_negotiations || 0,
                        count_properties: data.enterprise.count_properties || 0,
                        count_advisers: data.enterprise.count_advisers || 0
                    })
                }
            } finally {
                hideLoading();
            }
        }
        fetchMyAPI()
    }, [dataLoaded]);

    useEffect(() => {
        if (global.socials && contact.socials) {
            contact.socials ? setInfoSocials(contact.socials || []) : setInfoSocials([]) 
        }
    }, [contact, global.socials]);

    const handlerDelete = async() => {
        let url = ''
        let msg = ''
        if (elementDelete.type === 'operations') {
            url = `/enterprise/operation/${elementDelete.id}`
            msg = "Tipo de Operación eliminado exitosamente!"
        } else if (elementDelete.type === 'properties') {
            url = `/enterprise/property/${elementDelete.id}`
            msg = "Tipo de propiedad eliminado exitosamente!"
        } else if (elementDelete.type === 'states') {
            url = `/enterprise/state/${elementDelete.id}`
            msg = "Estado eliminado exitosamente!"
        } else if (elementDelete.type === 'municipalities') {
            url = `/enterprise/municipality/${elementDelete.id}`
            msg = "Municipio eliminado exitosamente!"
        } else if (elementDelete.type === 'projects') {
            url = `/enterprise/project/${elementDelete.id}`
            msg = "Proyecto eliminado exitosamente!"
        } else if (elementDelete.type === 'news') {
            url = `/enterprise/new/${elementDelete.id}`
            msg = "Noticia eliminada exitosamente!"
        }
        //
        try {
            await http.delete(url,
            { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`}})
            hideLoading();
            toast.success(msg, {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 1500
            });
            setDataLoaded(!dataLoaded)
            $('#deleteModal').modal('hide')
        } catch (err) {
            const message = err.response.data.message
            hideLoading();
            toast.error(message || "Error al eliminar, intente nuvamente", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
        }
    }

    const handlerEdit = async () => {
        if (elementEdit.type === 'operations') {
            if (infoEdit.name) {
                try {
                    await http.put(`/enterprise/operation/${elementEdit.id}`, { ... infoEdit },
                    { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`}})
                    hideLoading();
                    toast.success("Tipo de Operación guardado exitosamente!", {
                        position: toast.POSITION.BOTTOM_LEFT,
                        autoClose: 1500
                    });
                    $('#editAdminModal').modal('hide')
                    setDataLoaded(!dataLoaded)
                } catch {
                    hideLoading();
                    toast.error("Error al guardar, intente nuvamente", {
                        position: toast.POSITION.BOTTOM_LEFT,
                        autoClose: 2500
                    });
                }
            }
        } else if (elementEdit.type === 'properties') {
            if (infoEdit.name) {
                try {
                    await http.put(`/enterprise/property/${elementEdit.id}`, { ... infoEdit },
                    { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`}})
                    hideLoading();
                    toast.success("Tipo de propiedad guardado exitosamente!", {
                        position: toast.POSITION.BOTTOM_LEFT,
                        autoClose: 1500
                    });
                    $('#editAdminModal').modal('hide')
                    setDataLoaded(!dataLoaded)
                } catch {
                    hideLoading();
                    toast.error("Error al guardar, intente nuvamente", {
                        position: toast.POSITION.BOTTOM_LEFT,
                        autoClose: 2500
                    });
                }
            }
        }
    }

    const handlerCreate = async () => {
        if (elementEdit.action === 'create') {
            if (elementEdit.type === 'operations') {
                if (infoEdit.name) {
                    try {
                        await http.post('/enterprise/operation', { ... infoEdit },
                        { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`}})
                        hideLoading();
                        toast.success("Tipo de Operación guardado exitosamente!", {
                            position: toast.POSITION.BOTTOM_LEFT,
                            autoClose: 1500
                        });
                        $('#createAdminModal').modal('hide')
                        setDataLoaded(!dataLoaded)
                    } catch {
                        hideLoading();
                        toast.error("Error al guardar, intente nuvamente", {
                            position: toast.POSITION.BOTTOM_LEFT,
                            autoClose: 2500
                        });
                    }
                }
            } else if (elementEdit.type === 'properties') {
                if (infoEdit.name) {
                    try {
                        await http.post('/enterprise/property', { ... infoEdit },
                        { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`}})
                        hideLoading();
                        toast.success("Tipo de propiedad guardado exitosamente!", {
                            position: toast.POSITION.BOTTOM_LEFT,
                            autoClose: 1500
                        });
                        $('#createAdminModal').modal('hide')
                        setDataLoaded(!dataLoaded)
                    } catch {
                        hideLoading();
                        toast.error("Error al guardar, intente nuvamente", {
                            position: toast.POSITION.BOTTOM_LEFT,
                            autoClose: 2500
                        });
                    }
                }
            }
        }
    }

    const addLocation = (e, type) => {
        e.preventDefault();
        setLocationValidation(false)
        if (type === 'state') {
            setLocation({ id: null, country_id: '', state_id: '', name: '', title: 'Crear estado', type: 'save' })
            $('#mngStatesModal').modal('show');
        } else if (type === 'municipality') {
            setLocation({ id: null, country_id: '', state_id: '', name: '', title: 'Crear municipio', type: 'save' })
            $('#mngMunicipalitiesModal').modal('show');
        }
    }

    const addPropery = () => {
        setElementEdit({ 
            action: 'create', 
            type: 'properties', 
            id: 0, 
            title: 'Agregar Tipo de Propiedades'
        })
        setInfoEdit({ name: '' })
        $('#createAdminModal').modal('show');
    }

    const addOperation = () => {
        setElementEdit({ 
            action: 'create', 
            type: 'operations', 
            id: 0, 
            title: 'Agregar Tipo de Operación'
        })
        setInfoEdit({ name: '' })
        $('#createAdminModal').modal('show');
    }

    // Handler Inputs change
    const handleContactInput = e => {
        const { name, value } = e.target;
        setContact(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCountsInput = e => {
        const { name, value } = e.target;
        setCounts(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    //
    const setInfoSocials = (info = []) => {
        let enterpriseSocials = []
        for (const data of (global.socials || [])) {
            let newData = { ...data }
            const existInfo = info.find(el => el.social_networks_id === data['id'])
            newData['url'] = existInfo ? existInfo['url'] : ''
            newData['status'] = existInfo ? existInfo['status'] : false
            enterpriseSocials.push(newData)
        }
        setLinks([...enterpriseSocials])
    }

    //
    const saveContact = async() => {
        setShowValidation(true);
        const result = $('#contactForm')[0].checkValidity();
        if (!result) return false;
        //
        try {
            await http.put(`/enterprise/contact`, contact,
            { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`}})
            hideLoading();
            setShowValidation(false);
            toast.success("Infomarción guardada exitosamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 1500
            });
        } catch {
            hideLoading();
            toast.error("Error al guardar, intente nuvamente", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
        }
    }

    //
    const saveCounts = async() => {
        setShowValidation(true);
        const result = $('#countForm')[0].checkValidity();
        if (!result) return false;
        //
        try {
            await http.put(`/enterprise/counts`, counts,
            { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`}})
            hideLoading();
            setShowValidation(false);
            toast.success("Infomarción guardada exitosamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 1500
            });
        } catch {
            hideLoading();
            toast.error("Error al guardar, intente nuvamente", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
        }
    }

    //
    const handlerSocials = (e, id) => {
        let { name, value } = e.target;
        const findElement = links.findIndex(el => el.id == id)
        if (name === 'status') {
            links[findElement][name] = e.target.checked
        } else {
            links[findElement][name] = value
        }
        setLinks([...links]);
    }

    const searchLocations = (e) => {
        if (currentTable) currentTable.search(`${e.target.value}`).draw();
    }

    //
    const saveSocials = async() => {
        showLoading()
        try {
            await http.post(`/enterprise/socials`, links,
            { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`}})
            hideLoading();
            toast.success("Infomarción guardada exitosamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 1500
            });
        } catch {
            hideLoading();
            toast.error("Error al guardar, intente nuvamente", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
        }
    }

    //
    const saveInfoEnterprise = async() => {
        const body = new FormData()
        //
        let file = document.forms["formInfo"]["file"].files;
        if (file && file.length > 0) body.append('file', file[0]);
        body.append('overview', draftToHtml(convertToRaw(editorOverview.getCurrentContent())))
        body.append('mision', draftToHtml(convertToRaw(editorMision.getCurrentContent())))
        body.append('vision', draftToHtml(convertToRaw(editorVision.getCurrentContent())))
        body.append('adviser', draftToHtml(convertToRaw(editorAdviser.getCurrentContent())))
        body.append('text_project', textProject)
        //
        showLoading()
        try {
            await http.post(`/enterprise/info`, body,
            { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`}})
            hideLoading();
            toast.success("Infomarción guardada exitosamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 1500
            });
        } catch {
            hideLoading();
            toast.error("Error al guardar, intente nuvamente", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
        }
    }

    //
    const deleteMunicipalities = (id, name) => {
        setElementDelete({ 
            type: 'municipalities', 
            id, 
            title: 'Eliminar Municipio', 
            body: `¿Está seguro de que desea eliminar el municipio "${name}"?` 
        })
        $('#deleteModal').modal('show');
    }

    //
    const editMunicipalities = async (id, name, state_id, country_id, states) => {
        const currentStates = (states || []).filter(items => items.country_id == country_id)
        setStates(currentStates || [])
        setTimeout(() => {
            setLocationValidation(false)
            setLocation({
                id,
                name,
                country_id,
                title: 'Editar municipio',
                type: 'edit',
                state_id
            });
            $('#mngMunicipalitiesModal').modal('show')
        }, 10);
    }

    //
    const locationChangeInput = e => {
        const { name, value } = e.target;
        setLocation(prevState => ({
            ...prevState,
            [name]: value
        }));
        if (name === "country_id") {
            const currentStates = defaultStates.filter(items => items.country_id == value)
            setLocation(prevState => ({
                ...prevState,
                state_id: ""
            }));
            setStates(currentStates || [])
        }
    };

    //
    const saveStates = async() => {
        setLocationValidation(true);
        const result = $('#mngStates')[0].checkValidity();
        if (!result) return false;
        //
        let method = location.type === 'edit' ? 'put' : 'post'
        let url = location.type === 'edit' ? `/enterprise/state/${location.id}` : '/enterprise/state'
        try {
            await http[method](url, location,
            { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`}})
            hideLoading();
            toast.success("Infomarción guardada exitosamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 1500
            });
            $('#mngStatesModal').modal('hide')
            setDataLoaded(!dataLoaded)
        } catch {
            hideLoading();
            toast.error("Error al guardar, intente nuvamente", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
        }
    }

    //
    const saveMunicipalities = async() => {
        setLocationValidation(true);
        const result = $('#mngMunicipalities')[0].checkValidity();
        if (!result) return false;
        //
        let method = location.type === 'edit' ? 'put' : 'post'
        let url = location.type === 'edit' ? `/enterprise/municipality/${location.id}` : '/enterprise/municipality'
        try {
            await http[method](url, location,
            { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`}})
            hideLoading();
            toast.success("Infomarción guardada exitosamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 1500
            });
            $('#mngMunicipalitiesModal').modal('hide')
            setDataLoaded(!dataLoaded)
        } catch {
            hideLoading();
            toast.error("Error al guardar, intente nuvamente", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
        }
    }

    const deleteVideoMain = async() => {
        showLoading()
        try {
            await http.delete(`/enterprise/video`,
            { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`}})
            hideLoading();
            toast.success("Infomarción actualizada exitosamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 1500
            });
            setUrlVideo(null)
            setDataLoaded(!dataLoaded)
        } catch {
            hideLoading();
            toast.error("Error al eliminar, intente nuvamente", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
        }
    }

    const deleteVideoSecond = async() => {
        showLoading()
        try {
            await http.delete(`/enterprise/video2`,
            { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`}})
            hideLoading();
            toast.success("Infomarción actualizada exitosamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 1500
            });
            setUrlVideo2(null)
            setDataLoaded(!dataLoaded)
        } catch {
            hideLoading();
            toast.error("Error al eliminar, intente nuvamente", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
        }
    }

    // ---------------------------------------- miami --------------------------------------
    const addNewProject = () => {
        setActionProject('create')
        setProject({ id: 0, title: '', description: '', images: [] })
        setPropertyImages([])
        $('#createProjectModal').modal('show');
    }

    const searchProjects = (e) => {
        if (currentTableProjects) currentTableProjects.search(`${e.target.value}`).draw();
    }

    const handlerSaveProject = async() => {
        setShowValidation(true);
        const result = $(`#newProjectForm`)[0].checkValidity();
        if (!result) return false
        //
        if (propertyImages.length === 0) {
            $('#invalidImages').show()
            return false
        }
        //
        let form_data = new FormData();
        form_data.append('title', project.title)
        form_data.append('description', project.description)
        for (const img of propertyImages) {
            form_data.append('file[]', DataURIToBlob(img.url));
        }
        //
        const url = actionProject === 'create' ? '/enterprise/project' : `/enterprise/project/${project.id}`
        const method = actionProject === 'create' ? 'post' : 'put'
        showLoading()
        try {
            await http[method](url, form_data,
            { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`}})
            toast.success("Infomarción guardada exitosamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 1500
            });
            setDataLoaded(!dataLoaded)
            $('#createProjectModal').modal('hide');
        } catch (error) {
            let msgError = 'Error al guardar';
            if (error.response.status === 413) msgError = 'Error, valide el tamaño de sus imágenes';
            toast.error(`${msgError}, intente nuevamente!`, {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
        } finally {
            $('#invalidImages').hide()
            hideLoading();
            setShowValidation(false);
        }
    }

    const projectChangeInput = e => {
        const { name, value } = e.target;
        setProject(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    // remove image by index
    const removeImageByIndex = index => {
        const temp = [...propertyImages];
        temp.splice(index, 1);
        setPropertyImages(temp);
    }

    // handler to save images
    const handlerImages = (files) => {
        const reader = new FileReader();  
        const readFile = index => {
            if (index >= files.length) return
            let file = files[index]
            reader.onload = function(e) {
                var dataURL = reader.result;
                setPropertyImages(prevState => [...prevState, { url: dataURL }]);
                // do sth with bin
                readFile(index+1)
            }
            reader.readAsDataURL(file);
        }
        readFile(0);
    }

    // ------------------------------------ noticias --------------------------------
    const addNew = () => {
        setActionNews('create')
        setNews({ id: 0, title: '', description: '', date: '', images: [] })
        setNewsImages([])
        $('#createNewModal').modal('show');
    }

    const searchNews = (e) => {
        if (currentTableNews) currentTableNews.search(`${e.target.value}`).draw();
    }

    const handlerSaveNew = async() => {
        setShowValidation(true);
        const result = $(`#newNewsForm`)[0].checkValidity();
        if (!result) return false
        //
        if (newsImages.length === 0) {
            $('#invalidImages2').show()
            return false
        }
        //
        let form_data = new FormData();
        form_data.append('title', news.title)
        form_data.append('description', news.description)
        form_data.append('date', news.date)
        for (const img of newsImages) {
            form_data.append('file[]', DataURIToBlob(img.url));
        }
        //
        const url = actionNews === 'create' ? '/enterprise/new' : `/enterprise/new/${news.id}`
        const method = actionNews === 'create' ? 'post' : 'put'
        showLoading()
        try {
            await http[method](url, form_data,
            { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`}})
            toast.success("Infomarción guardada exitosamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 1500
            });
            setDataLoaded(!dataLoaded)
            $('#createNewModal').modal('hide');
        } catch (error) {
            let msgError = 'Error al guardar';
            if (error.response.status === 413) msgError = 'Error, valide el tamaño de sus imágenes';
            toast.error(`${msgError}, intente nuevamente!`, {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
        } finally {
            $('#invalidImages2').hide()
            hideLoading();
            setShowValidation(false);
        }
    }

    const newChangeInput = e => {
        const { name, value } = e.target;
        setNews(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    // remove image by index
    const removeImageByIndex2 = index => {
        const temp = [...newsImages];
        temp.splice(index, 1);
        setNewsImages(temp);
    }

    // handler to save images
    const handlerImages2 = (files) => {
        const reader = new FileReader();  
        const readFile = index => {
            if (index >= files.length) return
            let file = files[index]
            reader.onload = function(e) {
                var dataURL = reader.result;
                setNewsImages(prevState => [...prevState, { url: dataURL }]);
                // do sth with bin
                readFile(index+1)
            }
            reader.readAsDataURL(file);
        }
        readFile(0);
    }

    return (<>
        <div className="search">
            <HeaderAdmin/>
            <div className="d-flex bg-white" id="wrapper">
                <SidebarAdmin/>
                <div className="page-content-wrapper bg-admin container-fluid px-md-5 mt-3">
                    <h2 className="text-primary mt-2 mb-4 fw-bold">Configuración de la WEB</h2>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link font-14 active" id="app-tab" data-bs-toggle="tab" data-bs-target="#app" type="button" role="tab" aria-controls="app" aria-selected="true">
                                General
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link font-14" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">
                                Contacto
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link font-14" id="rrss-tab" data-bs-toggle="tab" data-bs-target="#rrss" type="button" role="tab" aria-controls="rrss" aria-selected="false">
                                Redes Sociales
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link font-14" id="info-tab" data-bs-toggle="tab" data-bs-target="#info" type="button" role="tab" aria-controls="info" aria-selected="false">
                                Info Empresa
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link font-14" id="number-tab" data-bs-toggle="tab" data-bs-target="#number" type="button" role="tab" aria-controls="number" aria-selected="false">
                                Números
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link font-14" id="project-tab" data-bs-toggle="tab" data-bs-target="#project" type="button" role="tab" aria-controls="project" aria-selected="false">
                                Proyectos
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link font-14" id="news-tab" data-bs-toggle="tab" data-bs-target="#news" type="button" role="tab" aria-controls="news" aria-selected="false">
                                Noticias
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="app" role="tabpanel" aria-labelledby="app-tab">
                            <WebSet
                                urlBanner={urlBanner}
                                fileUpload={fileUpload}
                                handlerBanner={handlerBanner}
                                defaultVideoUrl={defaultVideoUrl}
                                urlVideo={urlVideo}
                                fileUpload3={fileUpload3}
                                deleteVideoMain={deleteVideoMain}
                                addLocation={addLocation}
                                searchLocations={searchLocations}
                                addPropery={addPropery}
                                addOperation={addOperation}
                            />
                        </div>
                        <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                            <ContactSet
                                showValidation={showValidation}
                                handleContactInput={handleContactInput}
                                contact={contact}
                                saveContact={saveContact}
                            />
                        </div>
                        <div className="tab-pane fade mb-5" id="rrss" role="tabpanel" aria-labelledby="rrss-tab">
                            <SocialsSet
                                links={links}
                                global={global}
                                handlerSocials={handlerSocials}
                                saveSocials={saveSocials}
                            />
                        </div>
                        <div className="tab-pane fade" id="info" role="tabpanel" aria-labelledby="info-tab">
                            <InfoSet
                                urlBannerHead={urlBannerHead}
                                fileUploadHead={fileUploadHead}
                                handlerBannerHead={handlerBannerHead}
                                urlBannerAdviser={urlBannerAdviser}
                                fileUploadAdviser={fileUploadAdviser}
                                handlerBannerAdviser={handlerBannerAdviser}
                                urlBanner2={urlBanner2}
                                fileUpload2={fileUpload2}
                                handlerBanner2={handlerBanner2}
                                urlVideo2={urlVideo2}
                                defaultVideoUrl={defaultVideoUrl}
                                fileUpload4={fileUpload4}
                                deleteVideoSecond={deleteVideoSecond}
                                editorOverview={editorOverview}
                                setEditorOverview={setEditorOverview}
                                editorMision={editorMision}
                                setEditorMision={setEditorMision}
                                editorVision={editorVision}
                                setEditorVision={setEditorVision}
                                saveInfoEnterprise={saveInfoEnterprise}
                                setTextProject={setTextProject}
                                textProject={textProject}
                                urlBannerFooter={urlBannerFooter}
                                fileUploadFooter={fileUploadFooter}
                                handlerBannerFooter={handlerBannerFooter}
                                editorAdviser={editorAdviser}
                                setEditorAdviser={setEditorAdviser}
                            />
                        </div>
                        <div className="tab-pane fade" id="number" role="tabpanel" aria-labelledby="number-tab">
                            <NumbersSet
                                showValidation={showValidation}
                                counts={counts}
                                handleCountsInput={handleCountsInput}
                                saveCounts={saveCounts}
                                handlerBannerCounts={handlerBannerCounts}
                                urlBannerCounts={urlBannerCounts}
                                fileUploadCounts={fileUploadCounts}
                            />
                        </div>
                        <div className="tab-pane fade" id="project" role="tabpanel" aria-labelledby="project-tab">
                            <ProjectsSet
                                searchProjects={searchProjects}
                                addNewProject={addNewProject}
                            />
                        </div>
                        <div className="tab-pane fade" id="news" role="tabpanel" aria-labelledby="news-tab">
                            <NewsSet
                                addNewNews={addNew}
                                searchNews={searchNews}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Copyright/>
        </div>
        <ModalDelete
            title={elementDelete.title}
            body={elementDelete.body}
            handlerDelete={handlerDelete}
        />
        <ModalEditAdmin
            title={elementEdit.title}
            data={infoEdit}
            handlerEdit={handlerEdit}
            handleChangeInput={handleChangeInput}
        />
        <ModalCreateAdmin
            title={elementEdit.title}
            data={infoEdit}
            handlerCreate={handlerCreate}
            handleChangeInput={handleChangeInput}
        />
        <ModalMngStates
            data={location}
            handleChangeInput={locationChangeInput}
            countries={countries}
            handlerSubmit={saveStates}
            showValidation={locationValidation}
        />
        <ModalMngMunicipalities
            data={location}
            handleChangeInput={locationChangeInput}
            countries={countries}
            states={states}
            handlerSubmit={saveMunicipalities}
            showValidation={locationValidation}
        />
        <ModalCreateProject
            propertyImages={propertyImages}
            setPropertyImages={setPropertyImages}
            handlerCreate={handlerSaveProject}
            data={project}
            handleChangeInput={projectChangeInput}
            action={actionProject}
            removeImageByIndex={removeImageByIndex}
            handlerImages={handlerImages}
            showValidation={showValidation}
        />
        <ModalCreateNew
            newsImages={newsImages}
            setNewsImages={setNewsImages}
            handlerCreate={handlerSaveNew}
            data={news}
            handleChangeInput={newChangeInput}
            action={actionNews}
            removeImageByIndex={removeImageByIndex2}
            handlerImages={handlerImages2}
            showValidation={showValidation}
        />
        </>
    )
}

export default withContext(AppAdmin);