import React from "react";
import { getImageBackend } from './../Helper'

const PreviewProject = props => {
    const {  detail, baseUrl, global } = props

    return (<>
        {(global && global['enterprise'] && global['enterprise']['url_banner_head']) && 
        <div className="app-team__photo2 position-relative">
            <div className="color-overlay"></div>
            <img src={getImageBackend(baseUrl, '/enterprise/', global['enterprise']['url_banner_head'])} className="d-block w-100 h-100"/>    
        </div>}
        <div className="container mt-4">
            <div className="row mt-2 mb-5">
                <div className="col-md-8">
                    <div id="carouser-detail" className="carousel slide carousel-fade global-cursor-pointer" data-bs-ride="carousel" data-bs-interval="false">
                        <div className="carousel-inner">
                            {(detail.images || []).map((image, j) => {
                                return (<div className={`carousel-item detail__caraousel__detail ${ j === 0 ? 'active' : '' }`} data-index={image.index} key={j}>
                                    <img src={getImageBackend(baseUrl, '/enterprise/', image.url)} className="d-block w-100" alt="..."/>
                                </div>)
                            })}
                            
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouser-detail" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouser-detail" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card-body pb-0 pt-1">
                                <div className="row mx-0">
                                    <div className="col-md-12 px-0 detail__caraousel__info">
                                        <p className="h3 text-primary text-uppercase mb-0">{detail.title}</p>
                                    </div>
                                    <div className="col-md-12 px-0 mt-1">
                                        <p className="detail__caraousel__description mb-0 app-description">{detail.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default PreviewProject;
