import React, { useState }  from 'react';  
import { getImageBackend } from './../../Helper'
import { FaCamera, FaTimes, FaPlus } from "react-icons/fa";

const ModalEditAdvisor = props => {
    const fileUpload = React.createRef();
    const [avatar, setAvatar] = useState(null)
    const { handlerEdit, data, handleChangeInput, baseUrl, global, showValidation, handlerChangeItems, jobs } = props

    const handlerAvatar = selectorFiles => {
        var reader = new FileReader();
        reader.onload = function() {
            var dataURL = reader.result;
            setAvatar(dataURL)
        }
        reader.readAsDataURL(selectorFiles[0]);
    }


    return (  
        <div className="modal fade" id="editAdviserModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header bg-warning">
                        <h5 className="modal-title text-dark" id="staticBackdropLabel">Editar Asesor</h5>
                        <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form name="editAdviserForm" id="editAdviserForm" autoComplete={'off'} className={`modal-body needs-validation ${showValidation ? 'was-validated' : ''}`}>
                        <div className="row">
                            <div className="col-md-4 mx-auto mb-4 position-relative img-adviser">
                                <img src={avatar || getImageBackend(baseUrl, '/advisers/', data.avatar || null)} className="img-thumbnail avatar-adviser" alt="..."/>
                                <FaCamera className="position-absolute top-50 start-50 font-50 translate-middle-x global-cursor-pointer"  onClick={() => fileUpload.current.click()}/>
                                <div className="invalid-feedback fw-bold" id="avatarRequired">
                                    Insertar foto del asesor
                                </div>
                                <div className="d-none">
                                    <input name="file" type="file" ref={fileUpload} accept="image/gif,image/jpeg,image/jpg,image/png"  onChange={(e) => handlerAvatar(e.target.files)} className="form-control-file d-none"></input>
                                </div>
                            </div>
                            <div className="col-md-8 row">
                                <div className="col-12">
                                    <label htmlFor="first_name" className="form-label mb-1 fw-bold">Nombre</label>
                                    <input type="text" className="form-control" name="first_name" value={data.first_name} onChange={handleChangeInput} minLength={3} required/>
                                </div>
                                <div className="col-12 mt-2">
                                    <label htmlFor="last_name" className="form-label mb-1 fw-bold">Apellidos</label>
                                    <input type="text" className="form-control" name="last_name" value={data.last_name} onChange={handleChangeInput} minLength={3} required/>
                                </div>
                                <div className="col-12 mt-2">
                                    <label htmlFor="phone" className="form-label mb-1 fw-bold">Teléfono</label>
                                    <input type="text" className="form-control" name="phone" value={data.phone} onChange={handleChangeInput} minLength={3} required/>
                                </div>
                                <div className="col-12 mt-2">
                                    <label htmlFor="email" className="form-label mb-1 fw-bold">Correo</label>
                                    <input type="email" className="form-control" name="email" value={data.email} onChange={handleChangeInput} minLength={3} required/>
                                </div>
                                <div className="col-12 mt-2">
                                    <label htmlFor="job" className="form-label mb-1 fw-bold">Grupo</label>
                                    <select className="form-select form-control" name="adviser_job_id" aria-label="Default select example" onChange={handleChangeInput} value={data.adviser_job_id} required>
                                        {jobs.map((val, i) => { 
                                            return (<option key={i} value={val.id}>{val.name}</option>)
                                        })}
                                    </select>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="job" className="form-label mb-1 fw-bold">Cargo</label>
                                    <input type="text" className="form-control" name="job" value={data.job} onChange={handleChangeInput} minLength={3} required/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-2">
                            <label htmlFor="overview" className="form-label mb-1 fw-bold">Reseña</label>
                            <textarea rows={3} type="text" className="form-control h-100" name="overview" value={data.overview} onChange={handleChangeInput} minLength={3} required/>
                        </div>
                        <div className="col-12 mt-3">
                            <label htmlFor="job" className="form-label mb-1 fw-bold">Redes sociales</label>
                            <div className={`mb-3 pb-0`}>
                                {(data.socials || []).map((network, j) => {
                                    return !network.delete && (<div className={`row g-3 mt-1`} key={j}>
                                        <div className="col-md-4">
                                            <select className="form-select form-control" name="id" aria-label="Default select example" value={network.id} onChange={(e) => handlerChangeItems(e, j, 'modify')}>
                                                {(global.socials || []).map((val, i) => { 
                                                    return (<option key={i} value={val.id}>{val.name}</option>)
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <input type="url" className="form-control" name="url" placeholder="URL" value={network.url} onChange={(e) => handlerChangeItems(e, j, 'modify')} required/>
                                        </div>
                                        <div className="col-md-2">
                                            <button type="button" data-index={j} className={`btn btn-danger rounded-circle mx-1 ${data.socials.length > 1 ? '' : 'd-none'}`} onClick={(e) => handlerChangeItems(e, j, 'delete')}><FaTimes/></button>
                                            <button type="button" data-index={j} className={`btn btn-success rounded-circle mx-1`} onClick={(e) => handlerChangeItems(e, j, 'add')}><FaPlus/></button>
                                        </div>
                                  </div>)
                                })}
                            </div>
                            <div className="invalid-feedback fw-bold duplicateSocials">
                                Hay redes sociales duplicadas, revisa la informacion
                            </div>
                        </div>
                    </form>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
                        <button type="button" className="btn btn-warning" onClick={handlerEdit}>Editar</button>
                    </div>
                </div>
            </div>
        </div> 
    )  
}  

export default ModalEditAdvisor; 