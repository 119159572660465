import React  from 'react';  
import { FaWhatsapp, FaMarker, FaExternalLinkAlt } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { Link } from 'react-router-dom';

const ModalDetailAdviser = props => {
    const { data, iconsSocial } = props
    const styleLink = { color: 'inherit', textDecoration: 'inherit'}

    return (  
        <div className="modal fade" id="detailAdviserModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-info">
                        <h5 className="modal-title text-dark" id="staticBackdropLabel">Asesor - {data.first_name} {data.last_name}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    {data && <div className="modal-body">
                        <div className="card-body pt-0">
                            <h5 className="card-title fw-bold">{data.name}</h5>
                            <p className="card-text mb-1"><FaMarker/><span className="text-muted ms-1 fw-bold">{data.job}</span></p>
                            <p className="card-text mb-1"><FiMail className="fw-bold"/><span className="ms-1 text-decoration-underline global-cursor-pointer">{data.email}</span></p>
                            <p className="card-text mb-1"><FaWhatsapp className="fw-bold"/><span className="ms-1 global-cursor-pointer">{data.phone}</span></p>
                            <div className="">
                                {(data.socials || []).map((val, i) => { 
                                    if (val && val.main) {
                                        const SpecificComponent = iconsSocial[val.main.code];
                                        return (<Link style={styleLink} title={val.main.name} target="_blank" to={{ pathname: val.url }} key={i}>
                                            <SpecificComponent className="app-header__icons fs-5 mt-1 color-gray"/>
                                        </Link>)
                                    } else {
                                        return
                                    }
                                })}
                            </div>

                            <p className="card-text mb-1 mt-3"><span className="text-muted ms-1 fw-lighter">{data.overview}</span></p>

                            {(data.properties || []).length > 0 && <h5 className="card-title fw-bold mt-3">Inmuebles asociados</h5>}
                            <ul className="list-group">
                                {(data.properties || []).map((val, i) => { 
                                    return (<li className="list-group-item" key={i}>{i + 1}. {val.title}
                                    <Link style={styleLink} title={'Apartamento'} target="_blank" to={`/inmueble/${val.id}`}>
                                        <FaExternalLinkAlt className="float-end global-cursor-pointer"/>
                                    </Link>
                                </li>)
                                })}
                            </ul>
                        </div>
                    </div>}
                    <div className="modal-footer">
                        <button type="button" className="btn btn-info" data-bs-dismiss="modal">Aceptar</button>
                    </div>
                </div>
            </div>
        </div> 
    )  
}  

export default ModalDetailAdviser; 