import React from "react";
import { FaEdit, FaCamera, FaTrashAlt, FaMapMarkerAlt, FaPlusCircle, FaRegBuilding, FaRegHandshake, FaImage }  from "react-icons/fa";

const WebSet = props => {
    const { urlBanner, fileUpload, handlerBanner, defaultVideoUrl, urlVideo, fileUpload3, deleteVideoMain, addLocation,
        searchLocations, addPropery, addOperation } = props

    return (<>
        <div className="card mt-4 mb-5 p-2">
            <h5 className="text-secondary fw-bold px-3 mt-2 mb-0">
                <FaImage/> Banner Principal
            </h5>
            <div className="w-100 position-relative mb-2 mt-4">
                <img src={urlBanner} className="img-fluid w-100 img-banner" alt="..." />    
                <input name="file" type="file" ref={fileUpload} accept="image/gif,image/jpeg,image/jpg,image/png" id="imgStoreAvatar" onChange={(e) => handlerBanner(e.target.files, 'banner')} className="form-control-file d-none"></input>
                <button type="button" className="btn bg-white btn-outline-secondary position-absolute btn-banner d-flex align-items-center"  onClick={() => fileUpload.current.click()}>
                    <FaEdit className="me-1"/>Actualizar Banner
                </button>
            </div>
            <p className="mb-0 text-muted fst-italic">Se recomienda imágenes de 2048 x 904 px o superiores con un peso menor a 2 MB</p>
        </div>
        <div className="card mt-4 mb-5 p-2">
            <h5 className="text-secondary fw-bold px-3 mt-2 mb-0">
                <FaCamera/> Video Principal
            </h5>
            <div className="w-100 position-relative mb-2 mt-4">
                {!urlVideo && <img src={defaultVideoUrl} className="img-fluid w-100 img-banner" alt="..." />}
                {urlVideo && <video className="img-fluid w-100 img-banner" controls >
                    <source src={urlVideo} type="video/mp4"/>
                </video>}
                <div className="position-absolute btn-banner d-flex">
                    <button type="button" className="btn btn-outline-secondary d-flex align-items-center"  onClick={() => fileUpload3.current.click()}>
                        <FaEdit className="me-1"/>Actualizar
                    </button>
                    <button type="button" className="btn btn-outline-secondary d-flex align-items-center ms-1"  onClick={deleteVideoMain}>
                        <FaTrashAlt className="me-1"/>Eliminar
                    </button>
                </div>
                <input name="file" type="file" ref={fileUpload3} accept="video/*" id="imgStoreAvatar" onChange={(e) => handlerBanner(e.target.files, 'video')} className="form-control-file d-none"></input>
            </div>
            <p className="mb-0 text-muted fst-italic">Se recomienda video con un peso menor a 25 MB</p>
        </div>
        <div className="card mt-4 mb-5 p-2">
            <h5 className="text-secondary fw-bold px-3 mt-2 mb-0">
                <FaMapMarkerAlt/> Ubicaciones
                <div className="btn-group float-end" role="group">
                    <button id="btnGroupDrop1" type="button" className="btn btn-outline-secondary  d-flex align-items-center dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <FaPlusCircle className="me-1 font-16"/>Agregar ubicación
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                        <li><a className="dropdown-item" href="/" onClick={(e) => addLocation(e, 'state')}>Estado</a></li>
                        <li><a className="dropdown-item" href="/" onClick={(e) => addLocation(e, 'municipality')}>Municipio</a></li>
                    </ul>
                </div>
            </h5>
            <div className="row mx-0 mt-3">
                <div className="col-md-12 mb-1">
                    <div className="form-group has-search mb-0">
                        <span className="fas fa-search form-control-feedback mt-1 ml-1"></span>
                        <input className="form-control input-form w-100 input-big" onChange={searchLocations} placeholder="Buscar ubicaciones"></input>
                    </div>
                </div>
            </div>
            <div className="container">
                <table className="table table-hover w-100 mt-2 bg-white rounded wrap"
                    id="dataTable"
                    cellSpacing="0"
                />
            </div>
        </div>
        <div className="row p-2">
            <div className="col-md-6">
                <div className="card my-4 pt-2">
                    <h5 className="text-secondary fw-bold px-3 mt-2 mb-0">
                        <FaRegBuilding/> Tipo de propiedades
                        <button type="button" className="btn btn-outline-secondary float-end d-flex align-items-center" onClick={() => addPropery()}>
                            <FaPlusCircle className="me-1 font-16"/>Agregar propiedad
                        </button>
                    </h5>
                    <div className="container">
                        <table className="table table-hover w-100 mt-2 bg-white rounded wrap"
                            id="dataTableProperties"
                            cellSpacing="0"
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="card my-4 pt-2">
                    <h5 className="text-secondary fw-bold px-3 mt-2 mb-0">
                        <FaRegHandshake/> Tipo de operaciones
                        <button type="button" className="btn btn-outline-secondary float-end d-flex align-items-center" onClick={() => addOperation()}>
                            <FaPlusCircle className="me-1 font-16"/>Agregar operación
                        </button>
                    </h5>
                    <div className="container">
                        <table className="table table-hover w-100 mt-2 bg-white rounded wrap"
                            id="dataTableOperations"
                            cellSpacing="0"
                        />
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default WebSet; 