import React, { useEffect, useState } from "react";
import { NavBar, Footer, Copyright, VerticalNet } from '../components/commons'
import { Searcher, FeatureProperties, WhyUs, Miami, NewsHome } from '../components'
import withContext from "../withContext";
import { http } from './../services/HttpAxios'
import { showLoading, hideLoading } from './../Helper'
import { Helmet } from 'react-helmet';
// JQuery
import $ from 'jquery'
window.jQuery = $;

const Home = props => {
    const { iconsSocial, global, baseUrl, appTitle } = props.context
    const [negotiations, setNegotiations] = useState([])
    const [properties, setProperties] = useState([])
    const [features, setFeatures] = useState([])
    const [projects, setProjects] = useState([])
    const [news, setNews] = useState([])

    useEffect(() => {
        $(window).scrollTop(0);
        if (global['enterprise']) {
            showLoading()
            http.get('/enterprise/home')
            .then((response) => {
                const data = response.data.data;
                if (data && data.negotiations) setNegotiations(data.negotiations)
                if (data && data.properties) setProperties(data.properties)
                if (data && data.features) setFeatures(data.features)
                if (data && data.projects) setProjects(data.projects)
                if (data && data.noticias) setNews(data.noticias)
                hideLoading();
            }, () => {
                hideLoading();
            });
        }
    }, [props.context]);

    return (<>
        <Helmet>
            <title>{appTitle} | Home</title>
            <link rel="canonical" href="/"></link>
        </Helmet>
        {projects.length > 0 && <div className="home">
            <div className="position-relative">
                <NavBar className="position-absolute"/>
                <Searcher
                    negotiations={negotiations}
                    properties={properties}
                    global={global}
                    baseUrl={baseUrl}
                />
            </div>
            <FeatureProperties
                features={features}
                baseUrl={baseUrl}
            />
            <WhyUs
                global={global}
                baseUrl={baseUrl}
            />
            <Miami
                projects={projects}
                baseUrl={baseUrl}
                global={global}
            />
            <NewsHome
                projects={news}
                baseUrl={baseUrl}
                global={global}
            />
            <Footer
                global={global}
                baseUrl={baseUrl}
            />
            <Copyright/>
            <VerticalNet 
                iconsSocial={iconsSocial} 
                global={global}
            />
        </div>}
    </>)
}

export default withContext(Home);
