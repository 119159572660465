import React from "react";

const Copyright = props => {

    return (
        <div className="w-100 bg-secondary textcenter row mx-0 pt-3">
            <p className="text-center fw-bold">© Copyright 2021 skygroupinvictus.com.ve</p>
        </div>
    )
}

export default Copyright;