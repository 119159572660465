import React  from 'react';  

const ModalEditAdmin = props => {
    const { handlerEdit, title, data, handleChangeInput } = props

    return (  
        <div className="modal fade" id="editAdminModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-warning">
                        <h5 className="modal-title text-dark" id="staticBackdropLabel">{title}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="col-12">
                            <label htmlFor="name" className="form-label mb-1 fw-bold">Nombre</label>
                            <input type="text" className="form-control" name="name" value={data.name} onChange={handleChangeInput} required/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
                        <button type="button" className="btn btn-warning" onClick={handlerEdit}>Editar</button>
                    </div>
                </div>
            </div>
        </div> 
    )  
}  

export default ModalEditAdmin; 