import React  from 'react';  

const ModalPasswordUser = props => {
    const { handlerPassword, data, handleChangeInput, showValidation } = props

    return (  
        <div className="modal fade" id="passwrodUserModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-secondary">
                        <h5 className="modal-title text-dark" id="staticBackdropLabel">Actualizar contraseña</h5>
                        <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form name="editUserForm" id="passwordUserForm" autoComplete={'off'} className={`modal-body needs-validation ${showValidation ? 'was-validated' : ''}`}>
                        <div className="col-12">
                            <label htmlFor="password1" className="form-label mb-0 fw-bold">Nueva Contraseña</label>
                            <input autoComplete={"on"} type="password" className="form-control" name="password1" value={data.password1} onChange={handleChangeInput} required minLength={5}/>
                        </div>
                        <div className="col-12 mt-2">
                            <label htmlFor="password2" className="form-label mb-0 fw-bold">Confirmar Contraseña</label>
                            <input autoComplete={"on"} type="password" className="form-control" name="password2" value={data.password2} onChange={handleChangeInput} required minLength={5}/>
                            <div id="emailHelp2" className="form-text">Inserte un password de al menos 5 caracteres</div>
                        </div>
                    </form>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
                        <button type="button" className="btn btn-secondary" disabled={data.password1 !== data.password2} onClick={handlerPassword}>Actualizar contraseña</button>
                    </div>
                </div>
            </div>
        </div> 
    )  
}  

export default ModalPasswordUser; 