import React  from 'react'; 
import Select from 'react-select';
import { ReactSortable } from "react-sortablejs";
import { MdPublish } from "react-icons/md";
import { FaWarehouse, FaCloudUploadAlt, FaRegBuilding, FaLocationArrow, FaImage, FaCamera } from "react-icons/fa";

const ViewFormProperty = props => {
    const { showValidation , property, handleChangeInput, handlerAdviser, selectedAdviser, advisers,
        typeNegotiatons, typeProperties, countries, states, municipalities, propertyImages, setPropertyImages,
        fileUpload, removeImageByIndex, handlerImages, handlerPubish, progress } = props

    return (  
        <form id="formEstate" autoComplete={'off'} className={`needs-validation container ml-0 mr-auto ${showValidation ? 'was-validated' : ''}`}>
            <h5 className="text-secondary fw-bold mt-4 mb-0 d-flex align-items-center">
                <FaRegBuilding className="me-2"/>Info General
            </h5>
            <div className="row mt-3">
                <div className="form-group col-md-12">
                    <label className="fw-bold mb-1" htmlFor="title">Título</label>
                    <input type="text" name="title" className="form-control input-form" value={property.title} required minLength="3" onChange={handleChangeInput}></input>
                </div>
            </div>
            <div className="row mt-3">
                <div className="form-group col-md-12">
                    <label className="fw-bold mb-1" htmlFor="description">Descripción</label>
                    <textarea type="text" name="description" className="form-control input-form h-auto" value={property.description} rows={14} required minLength="3" onChange={handleChangeInput}></textarea>
                </div>
            </div>
            <div className="row mt-3">
                <div className="form-group col-md-6">
                    <label className="fw-bold mb-1" htmlFor="code">Código</label>
                    <input type="text" name="code" className="form-control input-form" value={property.code} onChange={handleChangeInput} required></input>
                </div>
                <div className="form-group col-md-6">
                    <label className="fw-bold mb-1" htmlFor="price">Precio ($)</label>
                    <input type="number" name="price" className="form-control input-form" value={property.price} onChange={handleChangeInput} required min={1}></input>
                </div>
            </div>
            <div className="row mt-3">
                <div className="form-group col-md-6">
                    <label className="fw-bold mb-1" htmlFor="adviser_id">Asesor Comercial</label>
                    <Select
                        name="adviser_id"
                        className="select-advisers input-form"
                        onChange={handlerAdviser}
                        options={advisers}
                        placeholder="Seleccionar"
                        value={selectedAdviser}
                        isSearchable={true}
                        isMulti={true}
                    />
                    <div className="invalid-feedback" id="invalidAdviser">
                        Por favor seleccione un asesor
                    </div>
                </div>
            </div>

            <h5 className="text-secondary fw-bold mt-5 mb-0 d-flex align-items-center">
                <FaWarehouse className="me-2"/>Detalle del inmueble
            </h5>
            <div className="row mt-3">
                <div className="form-group col-md-6">
                    <label className="fw-bold mb-1" htmlFor="type_negotiation_id">Tipo de Negociación</label>
                    <select name="type_negotiation_id" className="form-control form-select" required value={property.type_negotiation_id} onChange={handleChangeInput}>
                        <option disabled value="">Seleccionar</option>
                        {typeNegotiatons.map((val, i) => { 
                            return (<option key={i} value={val.id}>{val.name}</option>)
                        })}
                    </select>
                </div>
                <div className="form-group col-md-6">
                    <label className="fw-bold mb-1" htmlFor="type_property_id">Tipo de Inmueble</label>
                    <select name="type_property_id" className="form-control form-select" required value={property.type_property_id} onChange={handleChangeInput}>
                        <option disabled value="">Seleccionar</option>
                        {typeProperties.map((val, i) => { 
                            return (<option key={i} value={val.id}>{val.name}</option>)
                        })}
                    </select>
                </div>
            </div>
            <div className="row mt-3">
                <div className="form-group col-md-6">
                    <label className="fw-bold mb-1" htmlFor="meters_build">Metraje de Construcción (m<sup>2</sup>)</label>
                    <input type="number" name="meters_build" className="form-control input-form" min={0} value={property.meters_build || ''} onChange={handleChangeInput}></input>
                </div>
                <div className="form-group col-md-6">
                    <label className="fw-bold mb-1" htmlFor="meters">Metraje de Terreno (m<sup>2</sup>)</label>
                    <input type="number" name="meters" className="form-control input-form" min={0} value={property.meters || ''} onChange={handleChangeInput}></input>
                </div>
            </div>
            <div className="row mt-3">
                <div className="form-group col-md-6">
                    <label className="fw-bold mb-1" htmlFor="rooms">Habitaciones</label>
                    <input type="number" name="rooms" className="form-control input-form" min={0} value={property.rooms || ''} onChange={handleChangeInput}></input>
                </div>
                <div className="form-group col-md-6">
                    <label className="fw-bold mb-1" htmlFor="bathrooms">Baños</label>
                    <input type="number" name="bathrooms" className="form-control input-form" min={0} value={property.bathrooms || ''} onChange={handleChangeInput}></input>
                </div>
            </div>
            <div className="row mt-3">
                <div className="form-group col-md-6">
                    <label className="fw-bold mb-1" htmlFor="parking">Puestos de estacionamiento</label>
                    <input type="number" name="parking" className="form-control input-form" min={0} value={property.parking || ''} onChange={handleChangeInput}></input>
                </div>
            </div>

            <h5 className="text-secondary fw-bold mt-5 mb-0 d-flex align-items-center">
                <FaLocationArrow className="me-2"/>Ubicación
            </h5>
            <div className="row mt-3">
                <div className="form-group col-md-4">
                    <label className="fw-bold mb-1" htmlFor="country_id">País</label>
                    <select name="country_id" className="form-control form-select" required value={property.country_id} onChange={handleChangeInput}>
                        <option disabled value="">Seleccionar</option>
                        {countries.map((val, i) => { 
                            return (<option key={i} value={val.id}>{val.name}</option>)
                        })}
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <label className="fw-bold mb-1" htmlFor="state_id">Estado</label>
                    <select name="state_id" className="form-control form-select" required value={property.state_id} onChange={handleChangeInput}>
                        <option disabled value="">Seleccionar</option>
                        {states.map((val, i) => { 
                            return (<option key={i} value={val.id}>{val.name}</option>)
                        })}
                    </select>
                </div>
                <div className="form-group col-md-4">
                    <label className="fw-bold mb-1" htmlFor="municipality_id">Municipio</label>
                    <select name="municipality_id" className="form-control form-select" required value={property.municipality_id} onChange={handleChangeInput}>
                        <option disabled value="">Seleccionar</option>
                        {municipalities.map((val, i) => { 
                            return (<option key={i} value={val.id}>{val.name}</option>)
                        })}
                    </select>
                </div>
            </div>
            <div className="row mt-3">
                <div className="form-group col-md-12">
                    <label className="fw-bold mb-1" htmlFor="sector">Sector</label>
                    <input type="text" name="sector" className="form-control input-form" id="autocompleteGoogle" required minLength="6" value={property.sector} onChange={handleChangeInput}></input>
                </div>
            </div>

            <h5 className="text-secondary fw-bold mt-5 mb-2 d-flex align-items-center">
                <FaImage className="me-2"/>Imágenes
                <button type="button" className="ms-auto btn btn-primary d-flex align-items-center" onClick={() => fileUpload.current.click()}>
                    Cargar
                    <FaCloudUploadAlt className="ms-2 font-20"/>
                </button>
            </h5>
            <div className="container mx-0 py-2 images-properties" id="containerPropertiesImgs">
                <ReactSortable className="row" list={propertyImages} setList={setPropertyImages}>
                {(propertyImages || []).map((item, i) => (
                    <div key={i} className="col-md-2 item-drop-img border">
                        <img src={item.url} className="img-fluid" alt="Inmueble_image"/>
                        <button type="button" className="btn btn-dark text-white rounded py-0 px-2 fw-bold" onClick={() => removeImageByIndex(i)}>x</button>
                    </div>
                ))}
                </ReactSortable>
                {propertyImages.length === 0 && <div className="text-center mt-5 row">
                    <FaCamera className="font-50 text-muted global-cursor-pointer" onClick={() => fileUpload.current.click()}/>
                    <p>Haz click o arrastra las fotos para cargarlas</p>
                </div>}
                <input name="file" type="file" multiple ref={fileUpload} accept="image/gif,image/jpeg,image/jpg,image/png"  onChange={(e) => handlerImages(e.target.files)} className="form-control-file d-none"></input>
            </div>
            <div className="invalid-feedback" id="invalidImages">
                Por favor introduzca al menos 5 fotos del inmueble
            </div>
            {progress > 0 && 
            <div className="progress mt-5">
                <div className="progress-bar" role="progressbar" style={{ width: Math.trunc(progress) + '%' }} aria-valuenow={Math.trunc(progress)} aria-valuemin="0" aria-valuemax="100"></div>
            </div>}
            <div className="form-row my-5 pb-3">
                <button type="button" id="btnPublis" className="mx-auto btn btn-primary px-4 py-2 d-flex align-items-center" onClick={handlerPubish}>
                    Publicar Inmueble
                    <MdPublish className="ms-2 font-20"/>
                </button>
            </div>
        </form>
    )  
}

export default ViewFormProperty; 