import React, { useEffect } from "react";
import { NavBar, Footer, ContactForm, Copyright } from '../components/commons'
import withContext from './../withContext'
import { Helmet } from 'react-helmet';
//
import $ from 'jquery'
window.jQuery = $;

const Contacto = props => {
    const { iconsSocial, global, baseUrl, appTitle } = props.context

    useEffect(async() => {
        $(window).scrollTop(0);
    }, [props.context]);

    return (
        <><Helmet>
            <title>{appTitle} | Contacto</title>
            <link rel="canonical" href="/contacto"></link>
        </Helmet>
        <div className="contacto position-relative">
            <NavBar className={"position-absolute bg-transparent"}/>
            <ContactForm
                global={global}
                baseUrl={baseUrl}
                iconsSocial={iconsSocial}
            />
            <Footer
                global={global}
                baseUrl={baseUrl}
            />
            <Copyright/>
        </div>
    </>)
}

export default withContext(Contacto);
