import axios from 'axios';
import baseService from './../constants';
// Se crea instancia http con valores default
const http = axios.create({
    baseURL: baseService
});

const httpSuccess = axios.create({
    baseURL: baseService
});

http.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response.status === 401) {
        localStorage.removeItem("user");
        document.location.href = process.env.PUBLIC_URL + '/admin/auth';
    }
    return Promise.reject(error);
});
        
export { http, httpSuccess };
export default http;