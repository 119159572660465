import React, { useEffect, useState } from "react";
import { NavBar, Footer, Copyright } from '../components/commons'
import { AdviserShow } from '../components'
import withContext from "../withContext";
import { showLoading, hideLoading } from './../Helper'
import { http } from './../services/HttpAxios'
import { Helmet } from 'react-helmet';
//
import $ from 'jquery';

const DetailAdviser = props => {
    const { iconsSocial, global, baseUrl, appTitle } = props.context
    const [detail, setDetail] = useState({})

    useEffect(async() => {
        $(window).scrollTop(0);
        if (global['enterprise']) {
            try {
                showLoading()
                const response = await http.get(`/advisers/${props.match.params.id}`)
                const data = await response.data.data;
                setDetail(data)
                hideLoading()
            } catch {}
        }
    }, [props.context]);

    return (<>
        <Helmet>
            <title>{appTitle} | Asesor</title>
            <link rel="canonical" href={`/asesor/${props.match.params.id}`}></link>
        </Helmet>
        <div className="team position-relative">
            <NavBar className={"position-absolute bg-transparent"}/>
            {Object.keys(detail).length > 0 && <AdviserShow
                global={global}
                detail={detail}
                iconsSocial={iconsSocial}
                baseUrl={baseUrl}
            />}
            <Footer
                global={global}
                baseUrl={baseUrl}
            />
            <Copyright/>
        </div>
    </>)
}

export default withContext(DetailAdviser);