import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
//
import $ from 'jquery';
//
import { Header, NavBar, Footer } from '../../components/commons'
import { showLoading, hideLoading } from './../../Helper'
import { httpSuccess } from './../../services/HttpAxios'
import withContext from './../../withContext'

const LoginAdmin = props => {
    const history = useHistory();
    // HOOKS
    const [showValidation, setShowValidation] = useState(false);
    const [login, setLogin] = useState({ email: '', password: '' })

    // Handler Button
    const handlerButtonForm = () => {
        setShowValidation(true);
        const result = $('#loginAdminForm')[0].checkValidity();
        if (!result) return false;
        // call api
        showLoading();
        httpSuccess.post('/users/login', login)
        .then((response) => {
            const user = response.data.data;
            localStorage.setItem("user", JSON.stringify(user));
            toast.success("Sesión iniciada exitosamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3500
            });
            setTimeout(function(){ 
                document.location.href = process.env.PUBLIC_URL + (user.role === 'ADMIN' ? '/admin/app' : '/admin/inmuebles'); 
            }, 1500);
        }, () => {
            hideLoading();
            toast.error("Correo o contraseña inválida!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
        });
    }

    // Handler Inputs change
    const handleChangeInput = e => {
        const { name, value } = e.target;
        setLogin(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <div className="search">
            <NavBar/>
            <form className={`form-signin needs-validation ${showValidation ? 'was-validated' : ''}`} id="loginAdminForm" name="loginAdminForm" autoComplete={'off'}>
                <h1 className="h3 mb-3 font-weight-bold text-center">Admin Login</h1>

                <label className="sr-only">Email address</label>
                <input type="email" name="email" onChange={handleChangeInput} value={login.email} className="form-control input-form inputpss mb-2" required/>
                
                <label className="sr-only">Password</label>
                <input type="password" name="password" onChange={handleChangeInput} value={login.password} className="form-control inputpss input-form" placeholder="" required/>
                
                <div className="d-grid gap-2">
                    <button className="btn btn-lg btn-primary btn-block mb-5 mt-3" onClick={() => handlerButtonForm()} type="button">Sign in</button>
                </div>
            </form>
            <Footer/>
        </div>
    )
}

export default withContext(LoginAdmin);