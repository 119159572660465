import React  from 'react';  

const ModalWarning = props => {
    const { handlerClose, handlerAction, title, body } = props

    return (  
        <div className="modal fade" id="warningModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-warning">
                        <h5 className="modal-title text-dark" id="staticBackdropLabel">{title}</h5>
                        <button type="button" className="btn-close text-white" aria-label="Close" data-dismiss="modal"  onClick={handlerClose}></button>
                    </div>
                    <div className="modal-body">
                        {body}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light" data-dismiss="modal" onClick={handlerClose}>Cancelar</button>
                        <button type="button" className="btn btn-warning text-dark" onClick={handlerAction}>Aceptar</button>
                    </div>
                </div>
            </div>
        </div> 
    )  
}  

export default ModalWarning; 