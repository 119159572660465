import React, { useEffect, useState } from "react";
import { NavBar, Footer, Copyright } from '../components/commons'
import { ListTeam } from '../components'
import { showLoading, hideLoading } from './../Helper'
import { http } from './../services/HttpAxios'
import $ from 'jquery';
import withContext from "../withContext";
import { Helmet } from 'react-helmet';

const Team = props => {
    const { global, baseUrl, appTitle } = props.context
    const [arrayTeam, setArrayTeam] = useState([])
    const [original, setOriginal] = useState([])
    const [managers, setManagers] = useState([])
    const [administrative, setAdministrative] = useState([])
    const [advisers, setAdvisers] = useState([])
    const [legal, setLegal] = useState([])
    const [marketing, setMarketing] = useState([])
    const [loadingArray, setLoadingArray] = useState(false)

    useEffect(() => {
        $(window).scrollTop(0);
        if (global['enterprise']) {
            showLoading()
            setLoadingArray(false)
            http.get('/advisers/all?filter=true')
            .then((response) => {
                const data = response.data.data;
                if (data && data.advisers) setArrayTeam(data.advisers)
                hideLoading();
            }, () => {
                hideLoading();
            });
        }
    }, [props.context]);

    useEffect(() => {
        arrayTeam.forEach(item => {
            if (item.jobs.id == 1) {
                setManagers(oldArray => [...oldArray, item]);
            } else if (item.jobs.id == 2) {
                setAdministrative(oldArray => [...oldArray, item]);
            }   else if (item.jobs.id == 3) {
                setAdvisers(oldArray => [...oldArray, item]);
            } else if (item.jobs.id == 4) {
                setLegal(oldArray => [...oldArray, item]);
            }   else if (item.jobs.id == 5) {
                setMarketing(oldArray => [...oldArray, item]);
            }
        })
        setLoadingArray(true)
    }, [arrayTeam]);

    useEffect(() => {
        if (original.length == 0) {
            setOriginal([...advisers])
        }
    }, [advisers]);

    const handlerSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setAdvisers(original.filter(item => ((value ? (item.first_name + ' ' + item.last_name).toLowerCase().includes(value) : true))));
        if (!value) setAdvisers(original);
    }

    return (<>
        <Helmet>
            <title>{appTitle} | Nuestro Equipo</title>
            <link rel="canonical" href="/equipo"></link>
        </Helmet>
        <div className="team position-relative">
            <NavBar className={"position"}/>
            {loadingArray && <ListTeam
                arrayTeam={arrayTeam}
                managers={managers}
                administrative={administrative}
                advisers={advisers}
                baseUrl={baseUrl}
                handlerSearch={handlerSearch}
                global={global}
                legal={legal}
                marketing={marketing}
            />}
            <Footer
                global={global}
                baseUrl={baseUrl}
            />
            <Copyright/>
        </div>
    </>)
}

export default withContext(Team);
