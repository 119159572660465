import React, { useEffect, useState } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { FiMail } from "react-icons/fi"
import { getImageBackend, formatNumeric } from '../Helper'
import withContext from '../withContext'
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";

const AdviserShow = props => {
    const { baseUrl, global, detail, iconsSocial } = props
    const styleLink = { color: 'inherit', textDecoration: 'inherit'}
    const history = useHistory();

    const showDetail = (property) => {
        history.push(`/inmueble/${property.public_id}`)
    }

    return (<>
        {(global && global['enterprise'] && global['enterprise']['url_banner_head']) && 
        <div className="app-team__photo2 position-relative">
            <div className="color-overlay"></div>
            <img src={getImageBackend(baseUrl, '/enterprise/', global['enterprise']['url_banner_head'])} className="d-block w-100 h-100"/>    
        </div>}
        <div className="row container-fluid my-5">
            <div className="col-md-4 adviser__detail__avatar">
                <img className="w-100 h-100 ms-auto" src={getImageBackend(baseUrl, '/advisers/', detail.avatar)}/>
            </div>
            <div className="col-md-8 container">
                <p className="font-36 fw-bold mt-4 mb-0 text-uppercase mt-5 mt-md-0">{detail.first_name} {detail.last_name}</p>
                <p className="font-26 mt-0 text-uppercase">{detail.job}</p>
                <div className="d-flex align-items-center mt-4">
                    <FaPhoneAlt className="font-18"/><span className="ms-2">{detail.phone}</span>
                    <FiMail className="font-18 ms-5"/><span className="ms-2">{detail.email}</span>
                </div>
                <p className="font-18 mt-3 adviser__detail__text app-description">{detail.overview}</p>
                <div className="mt-3">
                    {(detail.socials || []).map((val, i) => { 
                        if (val && val.main) {
                            const SpecificComponent = iconsSocial[val.main.code];
                            return (<Link style={styleLink} title={val.main.name} target="_blank" to={{ pathname: val.url }} key={i}>
                                <SpecificComponent className="font-26 mt-1 color-gray me-2"/>
                            </Link>)
                        } else {
                            return
                        }
                    })}
                </div>
            </div>
        </div>
        {(detail.properties || []).length > 0 && <h1 className="text-uppercase text-center mt-2" data-aos="fade-right">Propiedades disponibles</h1>}
        <div className="row mb-5 mx-2">
            {detail.properties.map((property, i) => { 
                return (<div className="col-md-4 px-1 py-1 mt-0" key={i} onClick={() => showDetail(property)}>
                <div className="properties__main position-relative">
                    <div className="color-overlay"></div>
                    <img className="img-fluid w-100" src={getImageBackend(baseUrl, '/properties/', property.images[0].url)}></img>
                    <div className="properties__content">
                        <h3>{property.sector}</h3>
                        <p>${formatNumeric(property.price || 0, 0)}</p>
                        <span className="_button rounded-pill" onClick={() => showDetail(property)}>DETALLE</span>
                    </div>
                </div>
            </div>)
            })}
        </div>
    </>)
}

export default withContext(AdviserShow);