import React, { useState } from "react";
import { getImageBackend, showLoading, hideLoading, getCurrentUser } from "./../Helper";
import { FaRegPaperPlane } from "react-icons/fa";
import { http } from './../services/HttpAxios'
import { toast } from 'react-toastify';
import $ from 'jquery';

const AdviserWish = props => {
    const { baseUrl, global } = props
    const currentUser = getCurrentUser();
    const [showValidation, setShowValidation] = useState(false);
    const [contact, setContact] = useState({
        name: '',
        email: '',
        phone: '',
        subject: ''
    })

    // Handler Inputs change
    const handleChangeInput = e => {
        const { name, value } = e.target;
        setContact(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Handler Button
    const handlerButtonForm = async () => {
        setShowValidation(true);
        const result = $('#formContact')[0].checkValidity();
        if (!result) return false;
        //
        showLoading()
        const body = {
            subject: 'Alguien quiere convertirse en asesor',
            template: 'adviser',
            context: contact
        }
        try {
            await http.post(`/mail/send`, body)
            hideLoading();
            setContact({ name: '', email: '', phone: '', subject: '' })
            setShowValidation(false);
            toast.success("Infomarción enviada exitosamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 1500
            });
        } catch {
            hideLoading();
            toast.error("Error al enviar, intente nuvamente", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
        }
    }

    return (<>
        {(global && global['enterprise'] && global['enterprise']['url_banner_advisers']) && 
        <div className="app-team__photo position-relative">
            <div className="color-overlay3"></div>
            <img src={getImageBackend(baseUrl, '/enterprise/', global['enterprise']['url_banner_advisers'])} className="d-block w-100 h-100"/>    
        </div>}
        <div className="app-team container mb-5">
            <h1 className="text-center py-4 app-title">Conviértete en asesor</h1>
            <p className="font-18 mt-3 container text-center mb-5">
                Conviértete en un Agente Inmobiliario y recibe el respaldo de nuestra marca, capacitaciones, un sistema de propiedades a comercializarse y mucho más. Déjanos tus datos y nos comunicaremos contigo.
            </p>

            <div className="container pt-5 mt-5 mb-5">
                <form id="formContact" autoComplete={'off'} className={`row g-3 px-1 px-md-5 needs-validation ${showValidation ? 'was-validated' : ''}`} noValidate>
                    <div className="col-md-4">
                        <label htmlFor="name" className="form-label mb-0 fw-bold">Nombre completo</label>
                        <input type="text" className="form-control" name="name" value={contact.name} onChange={handleChangeInput} required/>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="email" className="form-label mb-0 fw-bold">Email</label>
                        <input type="email" className="form-control" name="email" value={contact.email} onChange={handleChangeInput} required/>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="phone" className="form-label mb-0 fw-bold">Teléfono</label>
                        <input type="phone" className="form-control" name="phone"  value={contact.phone} onChange={handleChangeInput} required/>
                    </div>
                    <div className="col-12">
                        <label htmlFor="subject" className="form-label mb-0 fw-bold">Escriba su consulta</label>
                        <textarea className="form-control" name="subject" rows="4" style={{ height: 'unset' }} value={contact.subject} onChange={handleChangeInput} required></textarea>
                    </div>
                    <div className="col-12">
                        <button className="btn btn-primary fs-6" type="button"  onClick={() => handlerButtonForm()}><FaRegPaperPlane className="me-2"/>Enviar</button>
                    </div>
                </form>
            </div>
        </div>
    </>)
}

export default AdviserWish;