import React from "react";
import { FaSearch } from "react-icons/fa";
import { getImageBackend } from "./../Helper";
import { Adviser } from './commons'

const ListTeam = props => {
    const { arrayTeam, baseUrl, handlerSearch, global, managers, administrative, advisers,
    legal, marketing } = props

    return (<>
        {(global && global['enterprise'] && global['enterprise']['url_banner_advisers']) && 
        <div className="app-team__photo position-relative">
            <div className="color-overlay3"></div>
            <img src={getImageBackend(baseUrl, '/enterprise/', global['enterprise']['url_banner_advisers'])} className="d-block w-100 h-100"/>    
        </div>}

        {(managers && managers.length > 0) && <div className="app-team container mb-5">
            <h1 className="text-center py-4 app-title">Directiva</h1>
            <div className="row row-cols-1 row-cols-md-3 g-4 justify-content-center">
            {managers.map((adviser, i) => {
                return (
                    <div className="col-md-3 px-1" key={i}>
                        <Adviser
                            className={"adviser__property2"}
                            data={adviser}
                            baseUrl={baseUrl}
                        />
                    </div>)
                })}
            </div>
        </div>}

        {(legal && legal.length > 0) && <div className="app-team container mb-5">
            <h1 className="text-center py-4 app-title">Departamento Legal</h1>
            <div className="row row-cols-1 row-cols-md-3 g-4 justify-content-center">
            {legal.map((adviser, i) => {
                return (
                    <div className="col-md-3 px-1" key={i}>
                        <Adviser
                            className={"adviser__property2"}
                            data={adviser}
                            baseUrl={baseUrl}
                        />
                    </div>)
                })}
            </div>
        </div>}

        {(administrative && administrative.length > 0) && <div className="app-team container mb-5">
            <h1 className="text-center py-4 app-title">Departamento Administrativo</h1>
            <div className="row row-cols-1 row-cols-md-3 g-4 justify-content-center">
            {administrative.map((adviser, i) => {
                return (
                    <div className="col-md-3 px-1" key={i}>
                        <Adviser
                            className={"adviser__property2"}
                            data={adviser}
                            baseUrl={baseUrl}
                        />
                    </div>)
                })}
            </div>
        </div>}

        {(marketing && marketing.length > 0) && <div className="app-team container mb-5">
            <h1 className="text-center py-4 app-title">Departamento de Marketing y Publicidad</h1>
            <div className="row row-cols-1 row-cols-md-3 g-4 justify-content-center">
            {marketing.map((adviser, i) => {
                return (
                    <div className="col-md-3 px-1" key={i}>
                        <Adviser
                            className={"adviser__property2"}
                            data={adviser}
                            baseUrl={baseUrl}
                        />
                    </div>)
                })}
            </div>
        </div>}

        <div className="app-team container mb-5">
            <h1 className="text-center py-4 app-title">Asesores Inmobiliarios</h1>
            <nav className="navbar navbar-expand-lg mb-4 bg-white d-flex justify-content-center">
                <div className="d-flex align-items-center mb-0 headSearch">
                    <FaSearch className="fsearch"/>
                    <input type="search" className="form-control border" onChange={handlerSearch} 
                    autoComplete={'off'} placeholder={`Encuentra tu Asesor`}></input>
                </div>
            </nav>

            <div className="row row-cols-1 row-cols-md-3 g-4 justify-content-center">
            {advisers.map((adviser, i) => {
                return (
                    <div className="col-md-3 px-1" key={i}>
                        <Adviser
                            className={"adviser__property2"}
                            data={adviser}
                            baseUrl={baseUrl}
                        />
                    </div>)
                })}
            </div>
        </div>
    </>)
}

export default ListTeam;