import React  from 'react'; 
import { BiBed, BiBath, BiCar, BiHomeAlt } from "react-icons/bi";
import { getImageBackend, formatNumeric } from './../../Helper'
import { Adviser } from './../commons'

const ModalDetailEstate = props => {
    const { detailEstate, iconsSocial, baseUrl } = props
    const styleLink = { color: 'inherit', textDecoration: 'inherit'}

    return (  
        <div className="modal fade" id="previewEstateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header bg-primary">
                        <h5 className="modal-title text-white" id="staticBackdropLabel">Detalle del Inmueble</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    {Object.keys(detailEstate).length > 0 && <div className="modal-body">
                        <div className="container my-3">
                            <div className="row mt-2">
                                <div className="col-md-8">
                                    <div id="carouser-detail" className="carousel slide carousel-fade global-cursor-pointer" data-bs-ride="carousel" data-bs-interval="false">
                                        <div className="carousel-inner">
                                            {(detailEstate.images || []).map((image, j) => {
                                                return (<div className={`carousel-item detail__caraousel__detail ${ j === 0 ? 'active' : '' }`} data-index={image.index} key={j}>
                                                    <img src={getImageBackend(baseUrl, '/properties/', image.url)} className="d-block w-100" alt="..."/>
                                                </div>)
                                            })}
                                            
                                        </div>
                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouser-detail" data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button className="carousel-control-next" type="button" data-bs-target="#carouser-detail" data-bs-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card-body pb-0 pt-1">
                                                <div className="row mx-0">
                                                    <div className="col-md-12 px-0 detail__caraousel__info">
                                                        <p className="h3 text-primary text-uppercase mb-0">{detailEstate.title}</p>
                                                    </div>
                                                    <div className="col-md-12 px-0 mt-1">
                                                        <p className="h1 text-primary fw-bold text-uppercase mb-0">$ {formatNumeric(detailEstate.price, 0)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="card-body pt-0">
                                                <div className="row mt-4">
                                                    <p className="text-muted fw-bold pe-0 text-uppercase detail__caraousel__info">Tipo de negociación:
                                                        <span className="fw-light ps-2">{detailEstate.negotiation.name}</span>
                                                    </p>
                                                </div>
                                                <div className="row mt-2">
                                                    <p className="text-muted fw-bold pe-0 text-uppercase">Tipo de propiedad:
                                                        <span className="fw-light ps-2">{detailEstate.type.name}</span>
                                                    </p>
                                                </div>
                                                <div className="row mt-2">
                                                    <p className="text-muted fw-bold pe-0 text-uppercase detail__caraousel__info">Ubicación:
                                                        <span className="fw-light ps-2">{detailEstate.state.name} - {detailEstate.municipality.name}</span>
                                                    </p>
                                                </div>
                                                <div className="row mt-2 detail__caraousel__icons">
                                                    <div className="col-md-6 d-flex align-items-center">
                                                        <BiBed className="font-26 text-muted"/>
                                                        <span className="ps-2">{detailEstate.rooms} {detailEstate.rooms > 1 ? 'Habitaciones' : 'Habitación'}</span>
                                                    </div>
                                                    <div className="col-md-6 d-flex align-items-center">
                                                        <BiBath className="font-26 text-muted"/>
                                                        <span className="ps-2">{detailEstate.bathrooms} {detailEstate.bathrooms > 1 ? 'Baños' : 'Baño'}</span>
                                                    </div>
                                                </div>
                                                <div className="row mt-4 detail__caraousel__icons">
                                                    <div className="col-md-6 d-flex align-items-center">
                                                        <BiCar className="font-26 text-muted"/>
                                                        <span className="ps-2">{detailEstate.parking} {detailEstate.parking > 1 ? 'Puestos' : 'Puesto'} de estacionamiento</span>
                                                    </div>
                                                    <div className="col-md-6 d-flex align-items-center">
                                                        <BiHomeAlt className="font-26 text-muted"/>
                                                        <span className="ps-2">{formatNumeric(detailEstate.meters, 0)} m<sup>2</sup> </span>
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <p className="text-muted fw-bold pe-0 text-uppercase">Código:
                                                        <span className="fw-light ps-2">{detailEstate.code}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5 mt-3">
                                <div className="col-md-8">
                                    <p className="detail__caraousel__description mb-0 px-3 app-description">{detailEstate.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Aceptar</button>
                    </div>
                </div>
            </div>
        </div> 
    )  
}  

export default ModalDetailEstate; 