import React from "react";
import { FaLink, FaSave }  from "react-icons/fa";

const SocialsSet = props => {
    const { links, global, handlerSocials, saveSocials } = props

    return (<>
        <h5 className="text-secondary fw-bold mt-4 mb-3">
            <FaLink className="me-1"/>Links de redes de la empresa
        </h5>
        <form className="row g-3 needs-validation" autoComplete={'off'} noValidate>
            <div className={`mb-3 mt-3 pb-0`}>
                {(links || []).map((network, j) => {
                return (<div className="row py-2" key={j}>
                            <div className="col-md-4">
                                <select disabled className="form-select form-control" name="id" aria-label="Default select example" value={network.id}>
                                    {(global.socials || []).map((val, i) => { 
                                        return (<option key={i} value={val.id}>{val.name}</option>)
                                    })}
                                </select>
                            </div>
                            <div className="col-md-4">
                                <input type="url" className="form-control" name="url" onChange={(e) => handlerSocials(e, network.id)} value={network.url} required/>
                            </div>
                            <div className={`form-control border-0 col-md-2 my-auto pt-0`}>
                                <div className="form-check form-switch ps-0">
                                    <input className="form-check-input" type="checkbox" role="switch" name="status" onChange={(e) => handlerSocials(e, network.id)} defaultChecked={network.status}/>
                                </div>
                            </div>
                        </div>)
                    })}
            </div>

            <div className="col-12 mt-3">
                <button className="btn btn-primary d-flex align-items-center" type="button" onClick={saveSocials}> <FaSave className="me-1"/> Guardar</button>
            </div>
        </form>
    </>);
}

export default SocialsSet; 