import React from "react";
import { FaInfoCircle, FaSave, FaImage, FaEdit }  from "react-icons/fa";

const NumbersSet = props => {
    const { showValidation, counts, handleCountsInput, saveCounts, urlBannerCounts, fileUploadCounts, handlerBannerCounts } = props

    return (<>
        <h5 className="text-secondary fw-bold mt-4 mb-3">
            <FaInfoCircle/> Números "POR QUÉ TRABAJAR CON NOSOTROS"
        </h5>
        <form id="countForm" autoComplete={'off'} className={`row g-3 needs-validation ${showValidation ? 'was-validated' : ''}`} noValidate>
            <div className="col-md-6">
                <label htmlFor="count_success_negotiations" className="form-label">Negociaciones exitosas</label>
                <input type="number" className="form-control" name="count_success_negotiations" value={counts.count_success_negotiations}  onChange={handleCountsInput} required/>
            </div>
            <div className="col-md-6">
                <label htmlFor="count_properties" className="form-label">Total de Inmuebles</label>
                <input type="number" className="form-control" name="count_properties" value={counts.count_properties}  onChange={handleCountsInput} required/>
            </div>
            <div className="col-md-6">
                <label htmlFor="count_advisers" className="form-label">Asesores Inmobiliarios</label>
                <input type="number" className="form-control" name="count_advisers" value={counts.count_advisers}  onChange={handleCountsInput} required/>
            </div>
            <div className="col-12">
                <button className="btn btn-primary d-flex align-items-center" type="button" onClick={saveCounts}> 
                    <FaSave className="me-1"/> Guardar
                </button>
            </div>
        </form>
        <form className="row g-3 needs-validation mb-5 pt-3" id="countBannerInfo" name="countBannerInfo" noValidate>
            <div className="form-group mb-3 border">
                <label className="fw-bold my-4 font-16 px-2" htmlFor="name"><FaImage className="me-1"/>Banner "POR QUÉ TRABAJAR CON NOSOTROS"</label>
                <div className="row">
                    <div className="col-12 position-relative mb-2">
                        <img src={urlBannerCounts} className="img-fluid w-100 img-banner" alt="..." />    
                        <input name="file" type="file" ref={fileUploadCounts} accept="image/gif,image/jpeg,image/jpg,image/png" id="imgCountsAvatar" onChange={(e) => handlerBannerCounts(e.target.files)} className="form-control-file d-none"></input>
                        <button type="button" className="btn btn-outline-secondary position-absolute btn-banner d-flex align-items-center"  onClick={() => fileUploadCounts.current.click()}>
                            <FaEdit className="me-1"/>Actualizar Banner
                        </button>
                    </div>
                </div>
                <p className="mb-0 text-muted fst-italic">Se recomienda imágenes de 570 x 240 px, con un peso menor a 2 MB</p>
            </div>
        </form>
    </>);
}

export default NumbersSet; 