import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Consumer } from '../Context';

const ProtectedAdminRoute = ({ component: Component, ...rest }) => (
  <Consumer>
    {({ user }) => (
      <Route
        render={props =>
          {
            if (user && Object.keys(user).length > 0) {
              if (user.role === 'ADMIN') {
                return  <Component {...props} />
              } else {
                return <Redirect to="/admin/perfil" />
              }
            } else {
              return <Redirect to="/admin/auth" />
            }
          }
        }
        {...rest}
      />
    )}
  </Consumer>
)

export default ProtectedAdminRoute
