import React, { useEffect, useState } from "react";
import withContext from './../withContext'
import { getImageBackend } from './../Helper'

const WhyUs = props => {
    const { global, baseUrl } = props;
    const [showInfo, setShowInfo] = useState(false)
    const [countNegotiations, setCountNegotiations] = useState(0)
    const [countProperties, setCountProperties] = useState(0)
    const [countAdvisers, setCountAdvisers] = useState(0)
    const [urlBanner, setUrlBanner] = useState('assets/img/background2.jpg')

    const animateValue = (obj, start, end, duration) => {
        if (obj) {
            let startTimestamp = null;
            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp;
                const progress = Math.min((timestamp - startTimestamp) / duration, 1);
                obj.innerHTML = Math.floor(progress * (end - start) + start);
                if (progress < 1) {
                    window.requestAnimationFrame(step);
                }
            };
            window.requestAnimationFrame(step);
        }
    }

    useEffect(() => {
        const obj1 = document.getElementById("countNegotiations");
        const obj2 = document.getElementById("countProperties");
        const obj3 = document.getElementById("countAdvisers");
        if(Number(obj2.textContent) == 0) {
            animateValue(obj1, 0, countNegotiations, 2000);
            animateValue(obj2, 0, countProperties, 2000);
            animateValue(obj3, 0, countAdvisers, 2000);
        }
    }, [showInfo]);
      
    useEffect(() => {
        window.addEventListener('scroll', function(e) {
            const element = document.querySelector('#main-container');
            if (element) {
                const position = element.getBoundingClientRect();
                if (window.screen.width > 768) {
                    if(position.top >= 0 && position.bottom <= window.innerHeight) {
                        setShowInfo(prevState => !prevState)
                    }
                } else {
                    if(position.top >= -100 && position.bottom*0.8 <= window.innerHeight) {
                        setShowInfo(prevState => !prevState)
                    }
                }
                
            }
        });
    }, []);

    //
    useEffect(() => {
        const currentEnterprise = (global && global.enterprise) ? global.enterprise : {}
        if (currentEnterprise['count_success_negotiations']) setCountNegotiations(currentEnterprise['count_success_negotiations'])
        if (currentEnterprise['count_properties']) setCountProperties(currentEnterprise['count_properties'])
        if (currentEnterprise['count_advisers']) setCountAdvisers(currentEnterprise['count_advisers'])
        if (currentEnterprise.url_banner_numbers) setUrlBanner(getImageBackend(baseUrl, '/enterprise/', currentEnterprise.url_banner_numbers))
    }, [props]);

    return (
        <div className="mx-0 mt-3 why-us app-img-bg" id="main-container" style={{backgroundImage: `url(${urlBanner})`,
        backgroundAttachment: 'fixed',
        backgroundBlendMode: 'luminosity, normal',
        backgroundPosition: '0px 0px',
        backgroundSize: 'cover',
        WebkitBackgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'}}>
            <h2 className="text-uppercase" data-aos="fade-up">¿Por qué trabajar con nosotros?</h2>
            <div className="row" data-aos="fade-up">
                <div className="col-md-4 why-us__items">
                    <div className="why-us__items--numbers" id="countNegotiations">0</div>
                    <div className="why-us__items--title">
                        Negociaciones <br/> Exitosas 
                    </div>
                </div>
                <div className="col-md-4 why-us__items">
                    <div className="why-us__items--numbers" id="countProperties">0</div>
                    <div className="why-us__items--title">
                        Total de <br/> Inmuebles 
                    </div>
                </div>
                <div className="col-md-4 why-us__items border-0">
                    <div className="why-us__items--numbers" id="countAdvisers">0</div>
                    <div className="why-us__items--title">
                        Asesores <br/> Inmobiliarios 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withContext(WhyUs);