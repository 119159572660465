import React, { useState, useEffect } from "react";
import { BsChevronDoubleDown, BsSearch } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { getImageBackend } from './../../Helper'
// JQuery
import $ from 'jquery'
window.jQuery = $;

const Searcher = props => {
    const { negotiations, properties, baseUrl, global } = props
    const history = useHistory();
    const [enterprise, setEnterprise] = useState({})
    const [queryString, setQueryString] = useState({
        operations: "",
        properties: "",
        location: ""
    })

    // Handler Inputs change
    const handleChangeInput = e => {
        const { name, value } = e.target;
        setQueryString(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    //
    useEffect(() => {
        const currentEnterprise = (global && global.enterprise) ? global.enterprise : {}
        setEnterprise(currentEnterprise)
    }, [props]);

    const searchEstate = () => { 
        const queryParam = new URLSearchParams(queryString).toString()
        history.push(`/buscar?${queryParam}`)
    }

    const scollDown = (e) => {
        document.getElementById('propertyFeature').scrollIntoView({ 
            behavior: 'smooth' 
          });
        return false
    }

    const onSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        searchEstate()
    }

    return (<>
        <div className="app-searcher d-flex justify-content-center align-items-center app-img-bg" style={{backgroundImage: `url(${enterprise.url_banner_main ? getImageBackend(baseUrl, '/enterprise/', enterprise.url_banner_main) : 'assets/img/video.png'})`,
        backgroundAttachment: 'fixed',
        backgroundBlendMode: 'luminosity, normal',
        backgroundPosition: '0px 0px',
        backgroundSize: 'cover',
        WebkitBackgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'}}>
            <div className="color-overlay"></div>
            {enterprise.url_video_main && <video className="app-searcher__video" src={getImageBackend(baseUrl, '/enterprise/', enterprise.url_video_main)} autoPlay={true} loop playsInline muted></video>}
            <div className="app-searcher__container">
                <form onSubmit={onSubmit} className="row g-3 app-searcher__container__form" autoComplete="off" data-aos="zoom-in">
                    {/* <h1 className="text-white text-center mb-3 text-uppercase">Somos la llave a tus sueños</h1> */}
                    <div className="col-md-3">
                        <select id="typeSearch" value={queryString.operations} name="operations" onChange={handleChangeInput} className="form-select input-search rounded-pill">
                            <option value={''} selected>Tipo de operación</option>
                            {negotiations.map((val, i) => { 
                                return (<option key={i} value={val.id}>{val.name}</option>)
                            })}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <select id="typeProperty" value={queryString.properties} name="properties" onChange={handleChangeInput} className="form-select input-search rounded-pill">
                            <option value={''} selected>Tipo de inmueble</option>
                            {properties.map((val, i) => { 
                                return (<option key={i} value={val.id}>{val.name}</option>)
                            })}
                        </select>
                    </div>
                    <div className="col-md-5">
                        <input type="text"  value={queryString.location} name="location" onChange={handleChangeInput} className="form-control input-search rounded-pill" placeholder="Ubicación / Código" id="code"/>
                    </div>
                    <div className="col d-md-block mt-auto">
                        <button type="button" className="btn btn-block btn-primary rounded-pill" onClick={() => searchEstate() }>
                            <BsSearch className="font-18"/><span className="ps-1 d-none">Buscar</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <BsChevronDoubleDown className="z-over btn-above text-white" data-aos="fade-up" onClick={scollDown}/>
    </>)
}

export default Searcher;