
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getCurrentUser } from './../../Helper'

const SidebarAdmin = props => {
    const [user, setUser] = useState({});

    useEffect(() => {
        setUser(getCurrentUser() || {})
    }, []);

    return (
        <div className="bg-sidebar-user sidebar-width px-0" id="sidebar-wrapper">
            <div className="row mx-0 px-4 d-block d-md-none cursor-pointer text-right my-2">
                <span className="font-18 ml-auto pr-2 text-white">Cerrar</span><i className="fa fa-window-close font-26 text-dark"></i>
            </div>
            <ul className="list-group w-100">
                {(user && user.role === 'ADMIN') && <li className="list-group-item bg-sidebar-user text-white py-3">
                    <NavLink to="/admin/app" className="sidebar-items" activeClassName="selected">
                        <svg className="icon-sidebar" version="1.0" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#FFFFFF" stroke="none">
                                <path className="icon-path-sidebar" opacity="0.5" d="M2240 5106 c-147 -41 -267 -162 -306 -310 -9 -35 -14 -112 -14 -242 0 -163 -3 -202 -20 -256 -48 -157 -179 -269 -349 -299 -102 -18 -159 -2 -381 111 -233 117 -284 134 -391 128 -102 -6 -207 -55 -271 -127 -54 -60 -280 -448 -301 -516 -26 -82 -30 -153 -14 -231 35 -164 108 -247 331 -374 179 -102 235 -150 276 -234 62 -126 62 -266 0 -392 -42 -85 -97 -131 -291 -244 -191 -110 -254 -165 -302 -267 -29 -62 -32 -77 -32 -163 0 -116 1 -117 168 -404 79 -136 140 -228 174 -263 59 -61 151 -109 234 -122 103 -17 153 -1 388 117 246 125 297 140 406 125 146 -21 267 -108 332 -241 l38 -76 6 -245 c5 -261 9 -283 62 -373 31 -54 111 -129 165 -157 87 -44 141 -51 409 -51 146 0 274 5 305 11 157 33 293 170 327 327 6 29 11 135 11 235 0 243 18 307 115 415 82 91 243 152 359 136 78 -10 109 -24 297 -133 95 -56 196 -108 223 -116 146 -44 325 7 424 119 52 60 265 426 291 501 19 55 24 90 24 165 -1 107 -21 179 -74 262 -45 70 -99 113 -255 203 -81 46 -163 103 -197 134 -138 131 -173 332 -87 507 45 93 99 141 266 239 196 115 236 146 284 213 73 101 97 231 64 342 -25 84 -269 492 -334 560 -96 98 -222 142 -351 120 -58 -10 -94 -26 -231 -106 -90 -52 -186 -106 -215 -120 -207 -100 -455 -9 -560 204 l-38 76 -6 245 c-4 184 -9 256 -21 288 -51 139 -163 242 -303 279 -68 18 -570 18 -635 0z m580 -1735 c135 -45 239 -110 340 -211 101 -101 166 -205 211 -340 27 -81 33 -114 37 -220 3 -92 0 -146 -12 -202 -72 -349 -346 -617 -692 -678 -45 -8 -117 -11 -190 -8 -98 4 -136 11 -214 37 -134 45 -239 110 -340 211 -101 101 -165 204 -212 340 -32 92 -33 100 -33 260 0 161 1 167 34 262 100 291 338 506 636 574 40 9 106 13 200 10 126 -2 149 -6 235 -35z"/>
                            </g>
                        </svg>
                        Configuración
                    </NavLink>
                </li>}
                {(user && user.role === 'ADMIN') && <li className="list-group-item bg-sidebar-user text-white py-3 border-bottom-sidebar">
                    <NavLink to="/admin/users" className="sidebar-items" activeClassName="selected">
                    <svg  className="icon-sidebar" xmlns="http://www.w3.org/2000/svg" version="1.0" width="16" height="16" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#FFFFFF" stroke="none">
                                <path className="icon-path-sidebar" d="M1326 3994 c-335 -72 -608 -329 -713 -670 -25 -83 -27 -102 -27 -264 0 -162 2 -182 27 -263 47 -152 121 -281 220 -384 22 -24 39 -44 36 -47 -2 -2 -62 -34 -134 -70 -344 -175 -597 -451 -677 -741 -21 -76 -22 -103 -26 -527 l-3 -448 440 0 441 0 0 80 0 80 -355 0 -355 0 0 348 c0 372 4 413 53 522 134 298 459 545 875 665 56 17 102 32 102 35 0 3 -42 32 -92 65 -163 107 -253 206 -322 357 -55 117 -69 187 -70 328 0 100 4 140 22 203 80 279 292 488 572 564 89 24 290 24 380 0 157 -41 329 -153 425 -276 27 -34 54 -60 60 -57 22 8 125 77 125 83 0 12 -54 78 -119 147 -134 139 -299 230 -494 271 -88 19 -300 18 -391 -1z"/>
                                <path className="icon-path-sidebar" d="M3385 3995 c-123 -28 -246 -81 -344 -147 -96 -66 -271 -253 -257 -276 3 -5 33 -26 67 -47 l62 -38 19 24 c103 131 190 203 319 263 117 56 187 71 329 71 149 0 216 -16 345 -80 165 -81 281 -198 360 -360 60 -125 79 -205 79 -345 0 -135 -18 -217 -74 -335 -69 -148 -157 -244 -322 -353 -48 -31 -88 -60 -88 -64 0 -3 15 -10 33 -14 85 -18 277 -88 366 -133 263 -131 454 -309 557 -516 72 -147 74 -162 74 -557 l0 -348 -355 0 -355 0 0 -80 0 -80 441 0 441 0 -5 448 c-5 508 -5 508 -86 677 -122 253 -347 462 -660 614 -46 22 -86 43 -89 46 -3 2 16 27 41 54 245 262 317 680 174 1011 -58 133 -97 190 -207 300 -133 133 -241 198 -420 253 -85 25 -354 33 -445 12z"/>
                                <path className="icon-path-sidebar" d="M2391 3389 c-286 -37 -552 -230 -682 -494 -72 -146 -92 -230 -92 -395 -1 -212 52 -385 168 -540 38 -52 144 -158 193 -193 23 -17 42 -33 42 -37 0 -4 -19 -12 -41 -19 -125 -38 -340 -151 -469 -247 -85 -64 -231 -205 -286 -276 -52 -67 -115 -181 -146 -264 -22 -57 -22 -74 -26 -491 l-3 -433 1465 0 1466 0 0 413 c0 376 -2 421 -19 489 -85 330 -433 657 -863 809 -38 13 -68 27 -68 30 0 3 27 28 60 54 141 113 251 285 301 470 30 109 32 337 5 439 -99 371 -399 639 -766 685 -95 12 -146 12 -239 0z m314 -183 c33 -9 98 -36 145 -58 69 -34 102 -59 175 -133 51 -51 107 -118 128 -155 120 -207 128 -479 21 -693 -75 -151 -136 -211 -369 -367 -107 -72 -164 -115 -158 -121 6 -6 33 -17 62 -25 247 -69 361 -108 470 -160 161 -76 270 -153 387 -269 112 -111 181 -214 222 -327 26 -72 26 -77 30 -400 l4 -328 -1302 0 -1301 0 3 337 3 338 27 63 c74 173 250 359 473 499 98 62 263 133 468 202 115 38 210 71 212 73 2 2 -74 53 -168 113 -241 154 -333 251 -404 425 -79 196 -70 398 27 595 99 203 267 339 489 395 92 24 262 21 356 -4z"/>
                            </g>
                        </svg>
                        Usuarios
                    </NavLink>
                </li>}
                <li className="list-group-item bg-sidebar-user text-white py-3">
                    <NavLink to="/admin/inmuebles" className="sidebar-items" activeClassName="selected">
                        <svg className="icon-sidebar" xmlns="http://www.w3.org/2000/svg" version="1.0" width="16" height="16" viewBox="0 0 900.000000 860.000000" preserveAspectRatio="xMidYMid meet">
                            <g opacity="0.5" transform="translate(0.000000,860.000000) scale(0.100000,-0.100000)" fill="#FFFFFF" stroke="none">
                                <path className="icon-path-sidebar" d="M2455 8586 c-129 -33 -233 -116 -288 -233 l-32 -68 0 -2681 0 -2680 37 -76 c57 -114 150 -191 273 -222 53 -14 284 -16 2055 -16 1771 0 2002 2 2055 16 123 31 216 108 273 222 l37 76 3 2660 c2 2956 8 2707 -67 2823 -46 72 -125 135 -207 166 l-59 22 -2015 2 c-1590 1 -2026 -1 -2065 -11z m3593 -1123 l-3 -308 -1545 0 -1545 0 -3 308 -2 307 1550 0 1550 0 -2 -307z m0 -1240 l-3 -308 -1545 0 -1545 0 -3 308 -2 307 1550 0 1550 0 -2 -307z m0 -1240 l-3 -308 -1515 -1 c-833 -1 -1530 1 -1547 3 l-33 5 0 304 0 304 1550 0 1550 0 -2 -307z m0 -1236 l2 -307 -1550 0 -1550 0 0 303 c0 167 2 306 4 308 2 2 699 4 1548 4 l1543 0 3 -308z"/>
                                <path className="icon-path-sidebar" d="M5 5878 c3 -18 41 -285 85 -593 43 -308 126 -893 184 -1300 57 -407 161 -1140 231 -1630 247 -1747 242 -1718 306 -1870 61 -148 147 -248 257 -301 120 -57 -87 -54 3417 -54 2149 0 3251 4 3293 10 191 31 319 141 396 342 68 177 102 335 161 744 76 534 301 2119 575 4059 44 308 82 575 85 593 l6 32 -536 0 c-294 0 -535 -4 -535 -9 0 -4 -49 -356 -110 -782 -195 -1376 -339 -2391 -421 -2962 -43 -307 -79 -562 -79 -567 0 -7 -1003 -10 -2820 -10 -1551 0 -2820 3 -2820 8 0 6 -78 554 -401 2827 -60 424 -132 933 -161 1133 l-52 362 -533 0 -534 0 6 -32z"/>
                            </g>
                        </svg>
                        Inmuebles
                    </NavLink>
                </li>
                {(user && user.role === 'ADMIN') && <li className="list-group-item bg-sidebar-user text-white py-3">
                    <NavLink to="/admin/asesores" className="sidebar-items" activeClassName="selected">
                        <svg className="icon-sidebar" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className="icon-path-sidebar" opacity="0.4" d="M7 8C9.20938 8 11 6.20937 11 4C11 1.79063 9.20938 0 7 0C4.79063 0 3 1.79063 3 4C3 6.20937 4.79063 8 7 8ZM9.99375 9.01875L8.5 15L7.5 10.75L8.5 9H5.5L6.5 10.75L5.5 15L4.00625 9.01875C1.77812 9.125 0 10.9469 0 13.2V14.5C0 15.3281 0.671875 16 1.5 16H12.5C13.3281 16 14 15.3281 14 14.5V13.2C14 10.9469 12.2219 9.125 9.99375 9.01875Z" fill="#FFFFFF"/>
                        </svg>
                        Asesores
                    </NavLink>
                </li>}
                <li className="list-group-item bg-sidebar-user text-white py-3">
                    <NavLink to="/admin/perfil" className="sidebar-items" activeClassName="selected">
                        <svg version="1.0"  className="icon-sidebar" xmlns="http://www.w3.org/2000/svg" fill="none" width="18" height="18" viewBox="0 0 1024.000000 1024.000000" preserveAspectRatio="xMidYMid meet">
                            <g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)" fill="#FFFFFF" stroke="none">
                                <path className="icon-path-sidebar" opacity="0.6" d="M4920 9273 c-776 -41 -1504 -285 -2137 -715 -72 -49 -192 -137 -265 -196 -171 -136 -504 -469 -640 -639 -514 -646 -816 -1390 -899 -2218 -16 -155 -16 -615 0 -770 84 -830 387 -1575 905 -2225 131 -164 467 -499 634 -632 962 -766 2166 -1066 3378 -843 652 121 1287 413 1824 841 174 138 506 470 644 644 794 997 1091 2288 810 3525 -267 1174 -1048 2190 -2119 2756 -644 340 -1416 511 -2135 472z m640 -658 c708 -97 1329 -373 1855 -825 128 -110 355 -346 458 -475 851 -1069 1008 -2537 403 -3755 -182 -365 -370 -625 -670 -926 -233 -232 -422 -382 -676 -535 -1022 -614 -2304 -665 -3370 -135 -344 172 -619 367 -894 636 -268 262 -450 500 -629 821 -218 390 -358 827 -419 1309 -17 140 -17 640 0 780 103 811 427 1495 977 2059 266 272 502 454 826 634 390 218 862 369 1304 416 55 6 118 13 140 15 96 10 585 -4 695 -19z"  fill="#FFFFFF"/>
                                <path className="icon-path-sidebar" opacity="0.6" d="M4960 7674 c-143 -24 -221 -54 -321 -120 -146 -97 -256 -255 -300 -434 -17 -70 -17 -248 0 -320 65 -268 273 -475 541 -541 71 -17 249 -17 320 0 285 70 495 293 545 580 19 105 19 137 0 242 -49 281 -253 503 -526 574 -59 15 -214 27 -259 19z"  fill="#FFFFFF"/>
                                <path className="icon-path-sidebar" opacity="0.6" d="M4870 5724 c-476 -18 -902 -33 -947 -33 l-83 -1 0 -160 0 -160 203 0 c212 0 290 -11 366 -49 50 -26 98 -82 111 -130 7 -27 10 -369 8 -1083 -3 -1040 -3 -1043 -24 -1089 -23 -49 -67 -88 -124 -109 -23 -9 -109 -15 -257 -18 l-223 -4 0 -164 0 -164 1250 0 1250 0 0 164 0 164 -197 5 c-225 5 -281 17 -342 73 -26 23 -42 48 -50 79 -8 31 -11 430 -11 1379 l0 1336 -32 -1 c-18 -1 -422 -17 -898 -35z" fill="#FFFFFF"/>
                            </g>
                        </svg>
                        Mi Perfil
                    </NavLink>
                </li>
            </ul>
        </div>);
    }

export default SidebarAdmin; 