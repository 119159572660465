/* eslint-disable import/first */
import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { FaUndoAlt, FaChevronLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
// Components
import { HeaderAdmin, SidebarAdmin } from '../../components/admin'
import { showLoading, hideLoading, getCurrentUser, getImageBackend, DataURIToBlob } from './../../Helper'
import { http } from './../../services/HttpAxios'
import withContext from './../../withContext'
import ViewFormProperty  from './ViewFormProperty'
// JQuery
import $ from 'jquery'
window.jQuery = $;

const EditEstateAdmin = props => {
    const history = useHistory();
    const fileUpload = React.createRef();
    const currentUser = getCurrentUser();
    const defaultValues = {
        title: '',
        description: '',
        code: '',
        price: '',
        adviser_id: "",
        type_negotiation_id: "",
        type_property_id: "",
        bathrooms: '',
        rooms: '',
        meters: '',
        meters_build: '',
        parking: '',
        country_id: "",
        state_id: "",
        municipality_id: "",
        sector: ""
    }
    //
    const [typeNegotiatons, setTypeNegotiations] = useState([])
    const [typeProperties, setTypeProperties] = useState([])
    const [advisers, setAdvisers] = useState([])
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [municipalities, setMunicipalities] = useState([])
    const [defaultStates, setDefaultStates] = useState([])
    const [defaultMunicipalities, setDefaultMunicipalities] = useState([])
    const [showValidation, setShowValidation] = useState(false);
    const [property, setProperty] = useState(defaultValues)
    const [propertyImages, setPropertyImages] = useState([])
    const [selectedAdviser, setSelectedAdviser] = useState([])
    const [progress, setProgress] = useState(0)
    //
    const resetForm = () => {
        setProperty(defaultValues)
        setSelectedAdviser(null)
        setPropertyImages([])
    }

    // Get select options
    const getOptions = () => {
        showLoading()
        http.get('/enterprise/options')
        .then((response) => {
            const data = response.data.data;
            setTypeNegotiations(data.negotiations || []);
            setTypeProperties(data.properties || []);
            setCountries(data.countries || [])
            setDefaultStates(data.states || [])
            setDefaultMunicipalities(data.municipalities || [])
            hideLoading();
        }, () => {
            hideLoading();
        });
    }

    // get advisers options
    const getAllAdvisers = () => {
        showLoading();
        http.get('/advisers/all')
        .then((response) => {
            const data = response.data.data;
            (data.advisers || []).map(adv => {
                adv.img = getImageBackend(props.context.baseUrl, '/advisers/', adv.avatar)
                adv.id = adv.id
                adv.value = adv.first_name + ' ' + adv.last_name
                adv.label = <div className="d-flex" dangerouslySetInnerHTML={{ __html: `
                <img class="mr-1 rounded-circle" src="${adv.img}" width="35" height="35" alt="Generic placeholder image">
                <div class="media-body ms-2">
                    <h6 class="fw-bold mb-0">${adv.first_name + ' ' + adv.last_name}</h6>
                    <span class="font-13">${adv.jobs.name}</span>
                </div>` }} />
            });
            setAdvisers(data.advisers);
        });
    }

    useEffect(() => {
        getOptions();
        getAllAdvisers();
        //
        $('#containerPropertiesImgs').on('dragover dragleave', function(e) {
            e.stopPropagation();
            e.preventDefault();
        });
        $('#containerPropertiesImgs').on('drop', function(e) {
            e.stopPropagation();
            e.preventDefault();
            let files = e.originalEvent.target.files || e.originalEvent.dataTransfer.files;
            handlerImages(files)
        });
    }, []);

    useEffect(() => {
        propertyImages.length < 5 ? $('#invalidImages').show() :  $('#invalidImages').hide()
    }, [propertyImages]);

    //handler inputs
    const handleChangeInput = e => {
        const { name, value } = e.target;
        setProperty(prevState => ({
            ...prevState,
            [name]: value
        }));
        //
        if (name === "country_id") {
            const currentStates = defaultStates.filter(items => items.country_id == value)
            setProperty(prevState => ({
                ...prevState,
                state_id: "",
                municipality_id: ""
            }));
            setStates(currentStates || [])
            setMunicipalities([])
        }
        if (name === "state_id") {
            const currentMunicipalities = defaultMunicipalities.filter(items => items.state_id == value)
            setProperty(prevState => ({
                ...prevState,
                municipality_id: ""
            }));
            setMunicipalities(currentMunicipalities || [])
        }
    };

    // Back to list properties
    const handlerBack = () => {
        history.push("/admin/inmuebles")
    }

    //Handler publish property
    const handlerPubish = () => {
        setShowValidation(true);
        const result = $(`#formEstate`)[0].checkValidity();
        if (!result) {
            window.scrollTo(0, 0);
            return false
        };
        //
        if (!selectedAdviser || selectedAdviser.length == 0) {
            $('#invalidAdviser').show()
            window.scrollTo(0, 0);
            return false
        }
        //
        if (propertyImages.length < 5) {
            return false
        }
        //
        $("#btnPublis").prop('disabled', true);
        let form_data = new FormData();
        delete property.advisers;
        for (let key in property) {
            form_data.append(key, property[key] || 0);
        }
        form_data.append('created_by', currentUser.id)
        for (let element of propertyImages) {
            form_data.append('file[]', DataURIToBlob(element.url));
        }
        //
        for (const iterator of selectedAdviser) {
            form_data.append('advisers[]', iterator.id);
        }
        //
        setProgress(1)
        http.post('/properties/create', form_data,
        { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`, "Content-Transfer-Encoding": 'base64' },
            onUploadProgress: progressEvent => {
                let currentProgress = (progressEvent.loaded / progressEvent.total) * 100;
                setProgress(currentProgress)
          }})
        .then(() => {
            hideLoading();
            toast.success("Inmueble creado exitosamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000
            });
            window.location.href = "/admin/inmuebles"
        }, (error) => {
            hideLoading();
            setProgress(0)
            let msgError = 'Error al guardar inmbueble';
            if (error.response.status === 413) msgError = 'Error, valide el tamaño de sus imágenes';
            toast.error(`${msgError}, intente nuevamente!`, {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
            $("#btnPublis").prop('disabled', false);
        });
    }

    // handler to save images
    const handlerImages = (files) => {
        showLoading()
        const reader = new FileReader();  
        const readFile = index => {
            if (index >= files.length) {
                hideLoading()
                return
            }
            let file = files[index]
            reader.onload = function(e) {
                var dataURL = reader.result;
                setPropertyImages(prevState => [...prevState, { url: dataURL }]);
                // do sth with bin
                readFile(index+1)
            }
            reader.readAsDataURL(file);
        }
        readFile(0);
    }

    // remove image by index
    const removeImageByIndex = index => {
        const temp = [...propertyImages];
        temp.splice(index, 1);
        setPropertyImages(temp);
    }

    // Handler select adviser
    const handlerAdviser = (adviser) => {
        setSelectedAdviser(adviser)
        $('#invalidAdviser').hide()
    }

    return (
        <div className="search">
            <HeaderAdmin/>
            <div className="d-flex bg-white" id="wrapper">
                <SidebarAdmin/>
                <div className="page-content-wrapper bg-admin container-fluid px-md-5 mt-3">
                    <h2 className="text-primary mt-2 mb-4 fw-bold text-capitalize">Crear Inmueble
                        <button type="button" className="btn btn-outline-secondary float-end d-flex align-items-center" onClick={resetForm}>
                            <FaUndoAlt className="me-1 font-16"/>Reset
                        </button>
                        <button type="button" onClick={handlerBack} className="me-2 btn btn-outline-secondary float-end d-flex align-items-center">
                            <FaChevronLeft className="me-1 font-16"/>Volver
                        </button>
                    </h2>
                    <div className="container card">
                        <ViewFormProperty
                            showValidation={showValidation}
                            property={property}
                            handleChangeInput={handleChangeInput}
                            handlerAdviser={handlerAdviser}
                            selectedAdviser={selectedAdviser}
                            advisers={advisers}
                            typeNegotiatons={typeNegotiatons}
                            typeProperties={typeProperties}
                            countries={countries}
                            states={states}
                            municipalities={municipalities}
                            propertyImages={propertyImages}
                            setPropertyImages={setPropertyImages}
                            fileUpload={fileUpload}
                            removeImageByIndex={removeImageByIndex}
                            handlerImages={handlerImages}
                            handlerPubish={handlerPubish}
                            progress={progress}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withContext(EditEstateAdmin);