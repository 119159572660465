import React from "react";
import ContactForm from './commons/ContactForm'
import ReactHtmlParser from 'react-html-parser';  
import { getImageBackend } from './../Helper'

const InfoEnterprise = props => {
    const { baseUrl, global } = props
    const enterprise = global['enterprise'] || {}

    return (<>
        {(global && global['enterprise'] && global['enterprise']['url_banner_head']) && 
        <div className="app-team__photo2 position-relative">
            <div className="color-overlay"></div>
            <img src={getImageBackend(baseUrl, '/enterprise/', global['enterprise']['url_banner_head'])} className="d-block w-100 h-100"/>    
        </div>}
        <div className="w-100 container">
            {Object.keys(enterprise).length > 0 &&<div className="row my-5">
                <div className="col-md-7 d-flex justify-content-center align-items-center">
                    {!enterprise.url_video_secondary && <img src={enterprise.url_banner_secondary ? getImageBackend(baseUrl, '/enterprise/', enterprise.url_banner_secondary)  :  "./assets/img/nosotros.jpg"} className="w-100 px-4 us__img" alt="..."/>}
                    {enterprise.url_video_secondary && <video className="img-fluid w-100 us__img" controls >
                        <source src={getImageBackend(baseUrl, '/enterprise/', enterprise.url_video_secondary)}/>
                    </video>}
                </div>
                <div className="col-md-5">
                    <h2 className="text-center us__title">SKY GROUP <br/> INVICTUS</h2>
                    <div className="card-body us__text app-description">
                        { ReactHtmlParser(enterprise.overview || '') }
                    </div>
                </div>
            </div>}
            <div className="row my-5">
                <h2 className="text-center us__title my-5">Asesoría Inmobiliaria a tu alcance</h2>
                <div className="col-md-6">
                    <h3 className="text-center us__subtitle">Misión</h3>
                    <div className="card-body us__text app-description">
                        { ReactHtmlParser(enterprise.mision || '') }
                    </div>
                </div>
                <div className="col-md-6">
                    <h3 className="text-center us__subtitle">Visión</h3>
                    <div className="card-body us__text app-description">
                        { ReactHtmlParser(enterprise.vision || '') }
                    </div>
                </div>
            </div>
            <div className="row my-5">
                <h2 className="text-center us__title my-5">¿Por qué trabajar con un Asesor Inmobiliario Invictus?</h2>
                <div className="col-md-9 mx-auto">
                    <div className="card-body us__text app-description">
                        { ReactHtmlParser(enterprise.adviser || '') }
                    </div>
                </div>
            </div>
            <div className="row my-5">
                <div className="col-md-12 px-md-5">
                    <ContactForm
                        hideAddress={true}
                    />
                </div>
            </div>
        </div>
    </>)
}

export default InfoEnterprise;