import React, { useState, useEffect } from "react";
import { NavBar, Footer, Copyright, VerticalNet } from '../components/commons'
import { ListNews } from '../components'
import withContext from "../withContext";
import { showLoading, hideLoading } from './../Helper'
import { Helmet } from 'react-helmet';
import { http } from './../services/HttpAxios'
import $ from 'jquery';

const News = props => {
    const { iconsSocial, global, baseUrl, appTitle } = props.context
    const [news, setNews] = useState([])

    useEffect(() => {
        if (global['enterprise']) {
            showLoading()
            http.get('/enterprise/news')
            .then((response) => {
                const data = response.data.data;
                if (data) setNews(data)
                hideLoading();
            }, () => {
                hideLoading();
            });
        }
        //
        const query = new URLSearchParams(props.location.search);
        const id = query.get('id');
        if (id) {
            waitForElm(String(id)).then((elm) => {
                if (elm) elm.scrollIntoView();
            });
        } else {
            $(window).scrollTop(0);
        }
    }, [props.context]);

    function waitForElm(selector) {
        return new Promise(resolve => {
            if (document.getElementById(selector)) {
                return resolve(document.getElementById(selector));
            }
    
            const observer = new MutationObserver(mutations => {
                if (document.getElementById(selector)) {
                    resolve(document.getElementById(selector));
                    observer.disconnect();
                }
            });
    
            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    }

    return (<>
        <Helmet>
            <title>{appTitle} | Noticias</title>
            <link rel="canonical" href="/noticias"></link>
        </Helmet>
        <div className="team position-relative">
            <NavBar className={"position-absolute bg-transparent"}/>
            <ListNews
                global={global}
                baseUrl={baseUrl}
                news={news}
            />
            <Footer
                global={global}
                baseUrl={baseUrl}
            />
            <Copyright/>
            <VerticalNet 
                iconsSocial={iconsSocial} 
                global={global}
            />
        </div>
    </>)
}

export default withContext(News);