import React, { useState, useEffect } from 'react'; 
import { Link } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";
import { NavLink } from "react-router-dom";
// Components
import { getAvatarUrl, getCurrentUser } from './../../Helper'
import { baseService } from './../../constants';

const HeaderAdmin  = props =>  {  
    const [user, setUser] = useState({})

    const logout = () => {
        localStorage.removeItem("user");
        document.location.href = process.env.PUBLIC_URL +'/admin/auth';
    }

    useEffect(() => {
        setUser(getCurrentUser() || {})
    }, []);

    return (<>
        <nav className="navbar navbar-expand-lg navbar-light bg-secondary px-4 py-0" style={{ listStyle: 'none' }}>
            <Link to="/admin/app" className="navbar-brand">
                <img src="logo_white.png" width="80" height="72" alt=""></img>
            </Link>
            <li className="nav-item px-2 dropdown ms-auto">
                <a className="nav-link px-0 px-md-2" type="button" id="navbarDropdownMenuLink"  data-bs-toggle="dropdown">
                    <span className="px-3 mb-0 text-dark d-none d-md-inline fw-bold">{user.name}</span> 
                    <img src={getAvatarUrl(baseService, user.avatar)} alt="Avatar" className="avatar"></img>
                </a>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    <NavLink to="/admin/perfil" className="dropdown-item px-3 text-200 d-flex align-items-center" type="button" activeClassName="selected">
                        Mi Perfil
                    </NavLink>
                    <a className="dropdown-item px-3 text-200 d-flex align-items-center" type="button" onClick={logout}>
                        Cerrar Sesión <FaSignOutAlt className="ms-1"/>
                    </a>
                </div>
            </li>
        </nav>
    </>)
}  

export default HeaderAdmin;