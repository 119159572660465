/* eslint-disable import/first */
// Libraries
import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import { FaEdit, FaInfoCircle, FaTrashAlt, FaPlusCircle } from "react-icons/fa";
import { toast } from 'react-toastify';
// Components
import { HeaderAdmin, SidebarAdmin } from '../../components/admin'
import { ModalCreateAdvisor, ModalEditAdvisor, ModalDelete, ModalDetailAdviser } from "../../components/modals";
import withContext from './../../withContext'
import { getImageBackend, showLoading, hideLoading, hasDuplicates, getCurrentUser } from './../../Helper'
import { http } from './../../services/HttpAxios'
// JQuery
import $ from 'jquery'
$.DataTable = require('datatables.net-bs');
window.jQuery = $;

const AdviserAdmin = props => {
    const iconsSocial = props.context.iconsSocial
    const global = props.context.global
    const currentUser = getCurrentUser();
    //
    const [showValidation, setShowValidation] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [currentTable, setCurrentTable] = useState(null);
    const [adviser, setAdviser] = useState({ first_name: '', email: '', phone: '', last_name: '', job: '', overview: '', adviser_job_id: 1, socials: [{ id: 1, url: '', delete: false}] })
    const [deleteAdviser, setDeleteAdviser] = useState({ id: 0, title: '', body: '' })
    const [counts, setCounts] = useState({ total: 0, active: 0, inactive: 0 });
    const [jobs, setJobs] = useState([])

    // Load table
    const loadTable = (dataSet = []) => {
        if ($.fn.DataTable.isDataTable('#dataTable')) {
            if (currentTable) {
                currentTable.clear();
                currentTable.rows.add(dataSet);
                currentTable.draw();
            }
        } else {
            let dataTable = $('#dataTable').DataTable({
                dom: 'rtp',
                columns: [
                    {
                        data: null,
                        title: "",
                        className: "align-middle text-center",
                        width: "45px"
                    },
                    { 
                        title: "Nombre", 
                        data: null,
                        className: "align-middle",
                        render: function(data, type, row) {
                            return `<span class="text-capitalize">${data.first_name} ${data.last_name}</span>`;
                        }
                    },
                    { 
                        title: "# Inmubles", 
                        data: null,
                        className: "align-middle",
                        render: function(data, type, row) {
                            return `<span class="text-capitalize">${data.properties.length}</span>`;
                        }
                    },
                    { 
                        title: "Email", 
                        data: 'email',
                        className: "align-middle",
                    },
                    { 
                        title: "Telefono", 
                        data: 'phone',
                        className: "align-middle",
                    },
                    { 
                        title: "Cargo", 
                        data: 'job',
                        className: "align-middle",
                    },
                    { 
                        title: "Grupo", 
                        data: null,
                        className: "align-middle",
                        render: function(data, type, row) {
                            return `<span class="text-capitalize">${data.jobs.name}</span>`;
                        }
                    },
                    { 
                        title: "Status", 
                        data: null,
                        className: "align-middle text-center",
                        render: function(data, type, row) {
                            return `<div class="d-flex justify-content-center align-items-center">
                                <div class="form-check form-switch ps-0">
                                    <input class="form-check-input inpStatus" type="checkbox" role="switch" id="${data.id}" ${data.status == 1 ? 'checked' : ''}>
                                    <label class="form-check-label" for="${data.id}"></label>
                                </div>
                            </div>`;
                        }
                    },
                    {
                        data: null,
                        title: "",
                        className: "align-middle text-center",
                        width: "10%"
                    },
                    {
                        data: 'status',
                        visible: false,
                    }
                ],
                columnDefs: [
                    {
                        targets: 0,
                        createdCell: (td, data, rowData, row, col) => {
                            ReactDOM.render(
                                <div className="nowrap">
                                        <img className="me-2 rounded-circle" src={getImageBackend(props.context.baseUrl, '/advisers/' ,data.avatar)} width="40" height="40" alt=""/>
                                </div>, td)},
                    },
                    {
                        targets: 8,
                        createdCell: (td, data, rowData, row, col) => {
                            ReactDOM.render(
                                <div className="nowrap">
                                        <a className="me-1 global-cursor-pointer detailAdviser" title="Detalle del asesor"><FaInfoCircle className="text-info font-16"/></a>
                                        <a className="me-1 global-cursor-pointer editAdviser" title="Editar asesor"><FaEdit className="text-warning font-16"/></a>
                                        <a className="me-1 global-cursor-pointer deleteAdviser" title="Eliminar asesor"><FaTrashAlt className="text-danger font-16"/></a>
                                    </div>, td)},
                    }
                ],
                "language": {
                    "paginate": {
                        "previous": "<i className='fa fa-chevron-left'>",
                        "next": "<i className='fa fa-chevron-right'>"
                    },
                },
                data: dataSet,
                paging: false,
                pageLength: 10,
                responsive: true
            }).columns.adjust();
            setCurrentTable(dataTable);
            //Click actions
            $('#dataTable tbody').on('click', '.deleteAdviser', function() {
                const rowLink = $(this).parent().parent().parent();
                const selectedAdviser = dataTable.row(rowLink).data();
                setDeleteAdviser({ 
                    id: selectedAdviser.id, 
                    title: 'Eliminar', 
                    body: `¿Está seguro de que desea eliminar a "${selectedAdviser.first_name + ' ' + selectedAdviser.last_name}"?` 
                })
                $('#deleteModal').modal('show');
            });
            //
            $('#dataTable tbody').on('click', '.editAdviser', function() {
                const rowLink = $(this).parent().parent().parent();
                const selectedAdviser = dataTable.row(rowLink).data();
                (selectedAdviser.socials || []).map(element => {
                    element.id = element.social_networks_id
                });
                setAdviser(selectedAdviser)
                $('#editAdviserModal').modal('show');
            });
            //
            $('#dataTable tbody').on('click', '.detailAdviser', function() {
                const rowLink = $(this).parent().parent().parent();
                const selectedAdviser = dataTable.row(rowLink).data();
                setAdviser({...selectedAdviser})
                $('#detailAdviserModal').modal('show');
            });
            //
            $('#dataTable tbody').on('click', '.inpStatus', function() {
                const rowLink = $(this).parent().parent().parent();
                const selectedAdviser = dataTable.row(rowLink).data();
                handlerChangeStatus(selectedAdviser.id);
            });
        }
        
    }

    useEffect(() => {
    }, []);

    useEffect(() => {
        getAllAdvisers();
    }, [dataLoaded]);

    const getAllAdvisers = () => {
        showLoading();
        http.get('/advisers/all')
        .then((response) => {
            const data = response.data.data;
            loadTable(data.advisers);
            let counters = { total: 0, active: 0, inactive: 0 }
            for (let index = 0; index < (data.advisers || []).length; index++) {
                counters.total += 1
                data.advisers[index]["status"] ? counters.active += 1 : counters.inactive += 1
            }
            setCounts({...counters})
            setJobs([...data.jobs])
            hideLoading();
        }, () => {
            hideLoading();
            loadTable([])
        });
    }

    const handlerCreateAdviser = () => {
        setShowValidation(false);
        setAdviser({ first_name: '', email: '', phone: '', adviser_job_id: 1, last_name: '', socials: [{ id: 1, url: '', delete: false}], job: '', overview: '' })
        $('.profileAdviser').hide();
        $('#createAdviserModal').modal('show')
    }

    // Handler to create adviser
    const handlerCreate = (isCreate = true) => {
        const nameForm = isCreate ? 'newAdviserForm' : 'editAdviserForm'
        const nameModal = isCreate ? 'createAdviserModal' : 'editAdviserModal'
        const url = isCreate ? '/advisers/create' : `/advisers/${adviser.id || null}`
        const method = isCreate ? 'post' : 'put'

        setShowValidation(true);
        $('.duplicateSocials').hide();
        $('.profileAdviser').hide();
        const result = $(`#${nameForm}`)[0].checkValidity();
        if (!result) return false;
        // Check if has photo
        if (isCreate) {
            let fileAdviser = document.forms[`${nameForm}`]["file"].files;
            if (!fileAdviser || fileAdviser.length === 0) {
                $('.profileAdviser').show();
                return false
            }
        }
        // Check if repeat socials
        let allSocials = []
        for (const social of adviser.socials) {
            allSocials.push(Number(social.id))
        }
        if (hasDuplicates(allSocials)) {
            $('.duplicateSocials').show();
            return false
        }
        // Call api
        showLoading()
        let formData = new FormData();
        for (let key in adviser) {
            Array.isArray(adviser[key])
            ? adviser[key].forEach(value => formData.append(key + '[]', JSON.stringify(value)))
            : formData.append(key, adviser[key]) ;
        }
        let file = document.forms[`${nameForm}`]["file"].files;
        if (file && file.length > 0) formData.append('file', file[0]);
        //
        http[method](url, formData,
        { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`} })
        .then((response) => {
            const message = response.data.message;
            hideLoading();
            setShowValidation(false);
            // show toast
            toast.success(message, {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000
            });
            $(`#${nameModal}`).modal('hide')
            setDataLoaded(!dataLoaded)
        }, (error) => {
            hideLoading();
            let msgError = 'Error al guardar';
            if (error.response.status === 413) msgError = 'Error, valide el tamaño de sus imágenes';
            toast.error(`${msgError}, intente nuevamente!`, {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
            hideLoading();
        });
    }

    // Handler to update adviser
    const handlerEdit = () => {
        handlerCreate(false)
    }

    const handlerDelete = () => {
        showLoading()
        http.delete(`/advisers/${deleteAdviser.id}`,
        { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`} })
        .then((response) => {
            const message = response.data.message;
            $('#deleteModal').modal('hide');
            hideLoading();
            toast.success(message, {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000
            });
            setDataLoaded(!dataLoaded)
        }, (error) => {
            const message = error.response.data.message
            toast.error(message || "Error al eliminar inmueble, intente nuevamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
            hideLoading();
        });
    }

    // Change status adviser
    const handlerChangeStatus = (id) => {
        showLoading();
        http.put(`/advisers/status/${id}`, { status: $(`#${id}`).is(":checked") },
        { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`} })
        .then((response) => {
            const message = response.data.message;
            hideLoading();
            setShowValidation(false);
            // show toast
            toast.success(message, {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000
            });
            setDataLoaded(prevCheck => !prevCheck)
        }, () => {
            $(`#${id}`).prop('checked', !$(`#${id}`).is(":checked"));
            toast.error("Error al actualizar, intente nuevamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
            hideLoading();
        });
    } 

    // Handler Inputs change
    const handleChangeInput = e => {
        const { name, value } = e.target;
        setAdviser(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handlerChangeItems = (e, index, type = 'modify') => {
        const { name, value } = e.target;
        if (type === 'modify') {
            let socials = adviser.socials[index]
            socials[name] = value
            adviser.socials[index] = socials
            setAdviser({...adviser});
        } else if (type === 'delete') {
            adviser.socials.splice(index, 1)
            setAdviser({...adviser});
        } else if (type === 'add') {
            adviser.socials.push({ id: 1, url: '', delete: false})
            setAdviser({...adviser});
        }
        setShowValidation(false);
        $('#duplicateSocials').hide();
        $('#profileAdviser').hide();
    }

    // Filter datatable
    const searchAdviser = (e) => {
        if (currentTable) currentTable.search(`${e.target.value}`).draw();
    }

    // Filter datatable by status
    const setStatusFilter = (e) => {
        const status = e.target.value == 'all' ? '' : e.target.value;
        if (currentTable) currentTable.columns(7).search(status).draw();
    }

    return (<>
        <div className="search">
            <HeaderAdmin/>
            <div className="d-flex bg-white" id="wrapper">
                <SidebarAdmin/>
                <div className="page-content-wrapper bg-admin container-fluid px-md-5 mt-3">
                    <h2 className="text-primary mt-2 mb-4 fw-bold">Gestiona a tus Asesores
                        <button type="button" onClick={handlerCreateAdviser} className="btn btn-outline-secondary float-end d-flex align-items-center">
                            <FaPlusCircle className="me-1 font-16"/>Agregar asesor
                        </button>
                    </h2>
                    <div className="d-block">
                        <p className="mb-1 d-flex align-items-center">Total: <span className="badge bg-success ms-1">{counts.total}</span></p>
                        <p className="mb-1 d-flex align-items-center">Activos: <span className="badge bg-info ms-1">{counts.active}</span></p>
                        <p className="mb-4 d-flex align-items-center">Inactivos: <span className="badge bg-danger ms-1">{counts.inactive}</span></p>
                    </div>
                    <div className="row mx-0">
                        <div className="col-md-10 ps-md-0 mb-3">
                            <div className="form-group has-search mb-0">
                                <span className="fas fa-search form-control-feedback mt-1 ml-1"></span>
                                <input className="form-control input-form w-100 input-big" onChange={searchAdviser} placeholder="Buscar Asesor"></input>
                            </div>
                        </div>
                        <div className="col-md-2 form-inline px-0 mb-3">
                            <div className="form-group w-100">
                                <select className="form-select form-control" name="role" aria-label="Default select example" onChange={setStatusFilter} defaultValue={'all'}>
                                    <option value={'all'}>-- TODOS --</option>
                                    <option value={true}>Activo</option>
                                    <option value={false}>Inactivo</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="container card">
                        <table className="table table-hover w-100 mt-2 bg-white rounded wrap"
                            id="dataTable"
                            cellSpacing="0"
                        />
                    </div>
                </div>
            </div>
        </div>
        <ModalCreateAdvisor
            handlerCreate={handlerCreate}
            data={adviser}
            handleChangeInput={handleChangeInput}
            baseUrl={props.context.baseUrl}
            global={global}
            showValidation={showValidation}
            handlerChangeItems={handlerChangeItems}
            jobs={jobs}
        />
        <ModalEditAdvisor
            handlerEdit={handlerEdit}
            data={adviser}
            handleChangeInput={handleChangeInput}
            baseUrl={props.context.baseUrl}
            global={global}
            showValidation={showValidation}
            handlerChangeItems={handlerChangeItems}
            jobs={jobs}
        />
        <ModalDelete
            title={deleteAdviser.title}
            body={deleteAdviser.body}
            handlerDelete={handlerDelete}
        />
        <ModalDetailAdviser
            data={adviser}
            iconsSocial={iconsSocial}
        />
    </>
    )
}

export default withContext(AdviserAdmin);