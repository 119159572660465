import React, { useState } from 'react';
import { FaCamera, FaImage, FaCloudUploadAlt } from "react-icons/fa";
import { ReactSortable } from "react-sortablejs";


const ModalCreateNew = props => {
    const fileUpload = React.createRef();
    const { handlerCreate, data, handleChangeInput, removeImageByIndex, handlerImages, newsImages, setNewsImages, action, showValidation } = props

    return (  
        <div className="modal fade" id="createNewModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className={`modal-header ${action === 'create' ? 'bg-primary' : 'bg-warning'}`}>
                        <h5 className={`modal-title ${action === 'create' ? 'text-white' : ''}`} id="staticBackdropLabel">{action === 'create' ? 'Agregar nueva noticia' : 'Editar noticia'}</h5>
                        <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form name="newNewsForm" id="newNewsForm" autoComplete={'off'} className={`modal-body needs-validation ${showValidation ? 'was-validated' : ''}`}>
                        <div className="col-12 mb-2">
                            <label htmlFor="title" className="form-label mb-1 fw-bold">Título</label>
                            <input type="text" className="form-control" name="title" value={data.title} onChange={handleChangeInput} required/>
                        </div>
                        <div className="col-12 mb-2">
                            <label htmlFor="date" className="form-label mb-1 fw-bold">Fecha</label>
                            <input type="date" className="form-control" name="date" value={data.date} onChange={handleChangeInput} required/>
                        </div>
                        <div className="col-12 mb-2">
                            <label htmlFor="description" className="form-label mb-1 fw-bold">Descripción</label>
                            <textarea type="text" className="form-control" style={{height: 'unset'}} rows={5} name="description" value={data.description} onChange={handleChangeInput} required/>
                        </div>
                        <h5 className="text-secondary fw-bold mt-5 mb-2 d-flex align-items-center">
                            <FaImage className="me-2"/>Imágenes
                            <button type="button" className="ms-auto btn btn-primary d-flex align-items-center" onClick={() => fileUpload.current.click()}>
                                Cargar
                                <FaCloudUploadAlt className="ms-2 font-20"/>
                            </button>
                        </h5>
                        <div className="container mx-0 py-2 images-properties" id="containerPropertiesImgs">
                            <ReactSortable className="row" list={newsImages} setList={setNewsImages}>
                            {(newsImages || []).map((item, i) => (
                                <div key={i} className="col-md-3 item-drop-img border">
                                    <img src={item.url} className="img-fluid" alt="Inmueble_image"/>
                                    <button type="button" className="btn btn-dark text-white rounded py-0 px-2 fw-bold" onClick={() => removeImageByIndex(i)}>x</button>
                                </div>
                            ))}
                            </ReactSortable>
                            {newsImages.length === 0 && <div className="text-center mt-5 row">
                                <FaCamera className="font-50 text-muted global-cursor-pointer" onClick={() => fileUpload.current.click()}/>
                                <p>Haz click o arrastra las fotos para cargarlas</p>
                            </div>}
                            <input name="file2" type="file" multiple ref={fileUpload} accept="image/gif,image/jpeg,image/jpg,image/png"  onChange={(e) => handlerImages(e.target.files)} className="form-control-file d-none"></input>
                        </div>
                        <div className="invalid-feedback" id="invalidImages2">
                            Por favor introduzca al menos una foto del proyecto
                        </div>
                    </form>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
                        <button type="button" className={`btn ${action === 'create' ? 'btn-primary' : 'btn-warning'}`} onClick={handlerCreate}>{action === 'create' ? 'Crear' : 'Editar'}</button>
                    </div>
                </div>
            </div>
        </div> 
    )  
}  

export default ModalCreateNew; 