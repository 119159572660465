import React, { useEffect, useState } from "react";
import { NavBar, Footer, PreviewState, Copyright } from '../components/commons'
import withContext from "../withContext";
import { showLoading, hideLoading, getImageBackend } from './../Helper'
import { http } from './../services/HttpAxios'
import { Helmet } from 'react-helmet';
//
import $ from 'jquery';

const DetailState = props => {
    const { iconsSocial, global, baseUrl, appTitle } = props.context
    const [detail, setDetail] = useState({})
    const currentUrl = window.location.href

    useEffect(async() => {
        $(window).scrollTop(0);
        if (global['enterprise']) {
            try {
                showLoading()
                const response = await http.get(`/properties/${props.match.params.id}`)
                const data = await response.data.data;
                if (!data) window.location = '/'; 
                setDetail(data)
                hideLoading()
            } catch {}
        }
    }, [props.context]);

    return (<>
        <Helmet>
            <title>{appTitle} | Inmueble</title>
            <link rel="canonical" href={`/inmueble/${props.match.params.id}`}></link>
        </Helmet>
        <div className="contacto position-relative">
            <NavBar className={"position-absolute bg-transparent"}/>
            {Object.keys(detail).length > 0 && <PreviewState
                detail={detail}
                iconsSocial={iconsSocial}
                baseUrl={baseUrl}
                global={global}
            />}
            <Footer
                global={global}
                baseUrl={baseUrl}
            />
            <Copyright/>
        </div>
    </>)
}

export default withContext(DetailState);