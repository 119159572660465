import React from "react";
import { FaUserCog, FaSave }  from "react-icons/fa";

const ContactSet = props => {
    const { showValidation, handleContactInput, contact, saveContact } = props

    return (<>
        <h5 className="text-secondary fw-bold mt-4 mb-3">
            <FaUserCog/> Información de Contacto
        </h5>
        <form id="contactForm" autoComplete={'off'} className={`row g-3 needs-validation ${showValidation ? 'was-validated' : ''}`} noValidate>
            <div className="col-md-6">
                <label htmlFor="name" className="form-label">Nombre de la empresa</label>
                <input type="text" className="form-control" name="name" value={contact.name}  onChange={handleContactInput} required/>
            </div>
            <div className="col-md-6">
                <label htmlFor="address" className="form-label">Dirección</label>
                <input type="text" className="form-control" name="address" value={contact.address}  onChange={handleContactInput} required/>
            </div>
            <div className="col-md-6">
                <label htmlFor="email" className="form-label">Correo</label>
                <input type="email" className="form-control" name="email" value={contact.email}  onChange={handleContactInput} required/>
            </div>
            <div className="col-md-6">
                <label htmlFor="phone" className="form-label">Teléfono</label>
                <input type="text" className="form-control" name="phone" value={contact.phone}  onChange={handleContactInput} required/>
            </div>
            <div className="col-12">
                <button className="btn btn-primary d-flex align-items-center" type="button" onClick={saveContact}> 
                    <FaSave className="me-1"/> Guardar
                </button>
            </div>
        </form>
    </>);
}

export default ContactSet; 