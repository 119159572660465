import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getImageBackend, formatNumeric } from './../Helper'
import withContext from './../withContext'

const FeatureProperties = props => {
    const { features, baseUrl } = props
    const history = useHistory();
    const [mainFeature, setMainFeautre] = useState(null)
    const [fourFeature, setFourFeature] = useState([])

    const seeAll = () => {
        history.push("/buscar")
    }

    const seeProperty = id => {
        history.push("/inmueble/" + id)
    }

    //
    useEffect(() => {
        let index = 0
        let featureFour = []
        for (const feat of features) {
            if (index === 0) {
                setMainFeautre(feat)
            } else {
                featureFour.push(feat)
            }
            index++
        }
        setFourFeature(featureFour)
    }, [props]);

    return (<><div className="d-md-flex d-none py-5 mt-5" id="propertyFeature">
        <div className="featured-prop" data-aos="fade-right">
            {mainFeature && <div className="w-100 position-relative pointer-hand" onClick={() => seeProperty(mainFeature.public_id)}>
                <div className="color-overlay3"></div>
                <img className="img-fluid img-property w-100" src={getImageBackend(baseUrl, '/properties/', mainFeature.images[0]['url'])}></img>
                <div className="property-content">
                    <h3>{mainFeature.sector}</h3>
                    <p>${formatNumeric(mainFeature.price || 0, 0)}</p>
                    <span className="_button rounded-pill" onClick={() => seeProperty(mainFeature.public_id)}>DETALLE</span>
                </div>
            </div>}
        </div>
        <div className="featured-content">
            <div className="title text-left text-uppercase mx-auto" data-aos="fade-up">
                <h1>Inmuebles
                    <span>destacados</span>
                </h1>
                <button className="btn btn-outline-primary title rounded-pill py-2 mt-5" onClick={seeAll}>VER TODOS</button>
            </div>
            <div className="properties-thumbs" data-aos="fade-left">
                {fourFeature.map((val, i) => { 
                    return (<div className="properties-thumb pointer-hand" key={i} onClick={() => seeProperty(val.public_id)}>
                    <div className="color-overlay2"></div>
                    <img className="h-100 w-100" src={getImageBackend(baseUrl, '/properties/', val.images[0]['url'])}></img>
                    <div className="properties-thumb-details">
                        <h2 className="playfair">{val.sector}</h2>
                        <h5>${formatNumeric(val.price || 0, 0)}</h5>
                        <span className="_button rounded-pill" onClick={() => seeProperty(val.public_id)}>DETALLE</span>
                    </div>
                </div>)
                })}
            </div>
        </div>
    </div>
    <div className="d-md-none d-block py-5 mt-3">
        <div className="title text-left text-uppercase mx-auto px-2" data-aos="fade-up">
            <h1>Inmuebles destacados</h1>
            <button className="btn btn-outline-primary title rounded-pill py-2 mt-2 mb-4" onClick={seeAll}>VER TODOS</button>
        </div>
        <div className="row">
            {features.map((val, i) => { 
                return (<div className="properties-thumb2 col-12 pointer-hand mt-2" key={i} onClick={() => seeProperty(val.public_id)}>
                <div className="color-overlay2"></div>
                <img className="h-100 w-100" src={getImageBackend(baseUrl, '/properties/', val.images[0]['url'])}></img>
                <div className="properties-thumb2-details">
                    <h2 className="playfair">{val.sector}</h2>
                    <h5>${formatNumeric(val.price || 0, 0)}</h5>
                    <span className="_button rounded-pill" onClick={() => seeProperty(val.public_id)}>DETALLE</span>
                </div>
            </div>)
            })}
        </div>
    </div>
    </>)
}

export default withContext(FeatureProperties);