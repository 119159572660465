/* eslint-disable import/first */
import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { FaCloudUploadAlt, FaSave, FaUserAlt, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
//
import $ from 'jquery';
window.jQuery = $;
//
import { HeaderAdmin, SidebarAdmin } from '../../components/admin'
import { getAvatarUrl, showLoading, hideLoading, getCurrentUser } from './../../Helper'
import { http } from './../../services/HttpAxios'
import withContext from './../../withContext'

const ProfileAdmin = props => {
    const fileUpload = React.createRef();
    const currentUser = getCurrentUser();
    //
    const [showEyePass, setShowEyePass] = useState({ eye_pass_1: false, eye_pass_2: false });
    const [showValidation, setShowValidation] = useState(false);
    const [profile, setProfile] = useState({ name: '', email: '', avatar: '', role: 'ADMIN' })
    const [userPassword, setUserPassword] = useState({ id: 5, password1: '', password2: '' });
    const [showValidationPass, setShowValidationPass] = useState(false);
    const [urlAvatar, setUrlAvatar] = useState(null)

    useEffect(() => {
        getUser()
    }, []);

    // Method to get All users
    const getUser = () => {
        showLoading();
        http.get(`users/${currentUser.id}`, 
        { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`} })
        .then((response) => {
            const data = response.data.data;
            setProfile(data)
            hideLoading();
        }, () => {
            hideLoading();
        });
    }
    
    //
    const handlerAvatar = selectorFiles => {
        var reader = new FileReader();
        reader.onload = function() {
            var dataURL = reader.result;
            setUrlAvatar(dataURL)
        }
        reader.readAsDataURL(selectorFiles[0]);
    }

    //handler inputs
    const handleChangeInput = e => {
        const { name, value } = e.target;
        setUserPassword(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    //
    const updateEyePass = type => {
        setShowEyePass(prevState => ({
            ...prevState,
            [type]: !showEyePass[type]
        }));
    }

    //
    const handlerSubmit = () => {
        setShowValidation(true);
        const result = $('#formProfile')[0].checkValidity();
        if (!result) return false;
        // Call api
        showLoading()
        let form_data = new FormData();
        for (let key in profile) {
            if (key != 'avatar') {
                form_data.append(key, profile[key]);
            }
        }
        let file = document.forms["formProfile"]["file"].files;
        if (file && file.length > 0) form_data.append('file', file[0]);

        http.put(`/users/${currentUser.id}`, form_data,
        { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`} })
        .then((response) => {
            const message = response.data.message;
            hideLoading();
            setShowValidation(false);
            // Update profie
            let updateUser = localStorage.getItem("user");
            updateUser = updateUser ? JSON.parse(updateUser) : null;
            updateUser.avatar = response.data.data.avatar;
            updateUser.name = response.data.data.name;
            localStorage.setItem("user", JSON.stringify(updateUser));
            // show toast
            toast.success(message, {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000
            });
            setTimeout(() => { window.location.reload() }, 1500)
        }, (error) => {
            const message = error.response.data.message
            toast.error(message || "Error al actualizar usuario, intente nuevamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
            hideLoading();
        });
    }

    //
    const handlerProfileInput = e => {
        const { name, value } = e.target;
        setProfile(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    //Submit button
    const handlerButtonForm = () => {
        $('#invalidFeedback').hide();
        if (userPassword.password1 !== userPassword.password2) {
            $('#invalidFeedback').show();
            return false;
        }
        setShowValidationPass(true);
        const result = $('#formPassword')[0].checkValidity();
        if (!result) return false;
        // Call api
        showLoading()
        http.put(`/users/password/${currentUser.id}`, userPassword,
        { headers: {"Authorization" : `Bearer ${currentUser.accessToken}`} })
        .then((response) => {
            const message = response.data.message;
            setShowValidationPass(false);
            setUserPassword({ id: 5, password1: '', password2: '' });
            hideLoading();
            toast.success(message, {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000
            });
        }, (error) => {
            const message = error.response.data.message
            toast.error(message || "Error al actualizar password, intente nuevamente!", {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 2500
            });
            hideLoading();
        });
    }

    return (
        <div className="search">
            <HeaderAdmin/>
            <div className="d-flex bg-white" id="wrapper">
                <SidebarAdmin/>
                <div className="page-content-wrapper bg-admin container-fluid px-md-5 mt-3">
                    <h2 className="text-primary mt-2 mb-4 fw-bold">Mi perfil</h2>

                    <div className="card mt-4 mb-5 p-2">
                        <h5 className="text-secondary fw-bold px-3 mt-2 mb-0 d-flex align-items-center">
                            <FaUserAlt className="me-2"/> Editar Perfil
                        </h5>
                        <form id="formProfile" autoComplete={'off'} className={`needs-validation container mt-5 ml-0 mr-auto ${showValidation ? 'was-validated' : ''}`}>
                            <div className="d-flex align-items-center">
                                <div className="me-4">
                                    <div className="d-flex img-profile align-items-center justify-content-center">
                                        <img alt="Image_Avatar" src={urlAvatar || getAvatarUrl(props.context.baseUrl, profile.avatar)} className={`img_avatar_profile img-avatar`}></img>
                                    </div>
                                    <div className="d-none">
                                        <input name="file" type="file" ref={fileUpload} accept="image/gif,image/jpeg,image/jpg,image/png"  onChange={(e) => handlerAvatar(e.target.files)} className="form-control-file d-none"></input>
                                    </div>
                                </div>
                                <button type="button" className="btn btn-primary btn-profile text-center d-flex align-items-center" onClick={() => fileUpload.current.click()}>
                                    <FaCloudUploadAlt className="me-2 font-20"/> <span className="d-none d-md-inline ml-3">Subir</span>
                                </button>
                            </div>
                            <div className="row mt-5">
                                <div className="form-group col-md-6">
                                    <label className="fw-bold" htmlFor="inputName">Nombre Completo</label>
                                    <input type="text" name="name" className="form-control input-form" value={profile.name} onChange={handlerProfileInput} required minLength={3}></input>
                                </div>
                                <div className="form-group col-md-6">
                                    <label className="fw-bold" htmlFor="inputEmail">Email</label>
                                    <input type="email" name="email" className="form-control input-form" value={profile.email} onChange={handlerProfileInput} required minLength={3}></input>
                                </div>
                            </div>
                            <div className="row mt-md-2">
                                <div className="form-group col-md-6">
                                    <label className="fw-bold" htmlFor="inputRol">Rol</label>
                                    <select name="rol" disabled={true} className="form-control input-form" value={profile.role} onChange={handlerProfileInput} required>
                                        <option value="ADMIN">ADMIN</option>
                                        <option value="USER">USER</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-row mt-3 pb-3">
                                <button type="button" className="ml-auto btn btn-primary px-4 py-2 d-flex align-items-center" onClick={handlerSubmit}>
                                    <FaSave className="me-2 font-20"/>
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </div>

                    <div className="card mt-4 mb-5 p-2">
                        <h5 className="text-secondary fw-bold px-3 mt-2 mb-0 d-flex align-items-center">
                            <FaLock className="me-2"/> Cambiar contraseña
                        </h5>
                        <form id="formPassword" autoComplete={'off'} className={`needs-validation container mt-4 ml-0 mr-auto ${showValidationPass ? 'was-validated' : ''}`}>
                            <div className="row mt-1">
                                <div className="form-group col-md-6">
                                    <label className="fw-bold" htmlFor="inputPassword1">Nueva Contraseña</label>
                                    <div className="input-group mb-2">
                                        <input autoComplete={'off'} type={showEyePass.eye_pass_1 ? "text" : "password"} name="password1" value={userPassword.password1} onChange={handleChangeInput} className="form-control input-form" required minLength="6"></input>
                                        <div className="input-group-append">
                                            <div className="input-group-text global-cursor-pointer h-100" onClick={() => updateEyePass('eye_pass_1')}>{showEyePass.eye_pass_1 ? <FaEye/> : <FaEyeSlash/>}</div>
                                        </div>
                                    </div>
                                    <div className="invalid-feedback h6" id="invalidFeedback">Por favor valide que ambas contraseñas coincidan</div>
                                </div>
                                <div className="form-group col-md-6">
                                    <label className="fw-bold" htmlFor="inputPassword2">Confirmar Contraseña</label>
                                    <div className="input-group mb-2">
                                    <input  autoComplete={'off'} type={showEyePass.eye_pass_2 ? "text" : "password"}  value={userPassword.password2} onChange={handleChangeInput} name="password2"  className="form-control input-form" required minLength="6"></input>
                                        <div className="input-group-append">
                                            <div className="input-group-text global-cursor-pointer h-100"  onClick={() => updateEyePass('eye_pass_2')}>{showEyePass.eye_pass_2 ? <FaEye/> : <FaEyeSlash/>}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3 mx-0 d-block">
                                <p className="h5 fw-bold">Consejo de seguridad</p>
                                <p className="col-10 col-md-6 col-xl-4 p-0 mt-2 contraseña-info-subtitle principal-font">Para generar una contraseña segura  
                                usa una combinación de caracteres alfanuméricos (letras y números) además de símbolos </p>
                            </div>
                            <div className="form-row mt-3 pb-3">
                                <button type="button" className="ml-auto btn btn-primary px-4 py-2 d-flex align-items-center" onClick={handlerButtonForm}>
                                    <FaSave className="me-2 font-20"/>
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withContext(ProfileAdmin);