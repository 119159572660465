import React, { useState, useEffect } from "react";
import { VerticalNet, NavBar, Footer, Copyright } from '../components/commons'
import { showLoading, hideLoading } from './../Helper'
import withContext from "../withContext";
import { http } from './../services/HttpAxios'
import { PreviewProject } from './../components'
import { Helmet } from 'react-helmet';
//
import $ from 'jquery';

const ProjectDetail = props => {
    const { iconsSocial, global, baseUrl, appTitle } = props.context
    const [detail, setDetail] = useState({})

    useEffect(async() => {
        $(window).scrollTop(0);
        if (global['enterprise']) {
            try {
                showLoading()
                const response = await http.get(`/enterprise/project/${props.match.params.id}`)
                const data = await response.data.data;
                setDetail(data)
                hideLoading()
            } catch {}
        }
    }, [props.context]);

    return (<>
        <Helmet>
            <title>{appTitle} | Miami</title>
            <link rel="canonical" href={`/proyecto/${props.match.params.id}`}></link>
        </Helmet>
        <div className="nosotros position-relative">
            <NavBar className={"position-absolute bg-transparent"}/>
            {Object.keys(detail).length > 0 && <PreviewProject
                detail={detail}
                baseUrl={baseUrl}
                global={global}
            />}
            <Footer
                global={global}
                baseUrl={baseUrl}
            />
            <Copyright/>
            <VerticalNet 
                iconsSocial={iconsSocial} 
                global={global}
            />
        </div>
    </>)
}

export default withContext(ProjectDetail);