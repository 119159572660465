import React, { useEffect } from "react";
import { NavBar, Footer, Copyright } from '../components/commons'
import withContext from "../withContext";
import { AdviserWish } from './../components'
import { Helmet } from 'react-helmet';
// JQuery
import $ from 'jquery'
window.jQuery = $;

const WishAdviser = props => {
    const { global, baseUrl, appTitle } = props.context

    useEffect(async() => {
        $(window).scrollTop(0);
    }, [props.context]);

    return (<>
        <Helmet>
            <title>{appTitle} | Asesor</title>
            <link rel="canonical" href="/asesor"></link>
        </Helmet>
        <div className="team position-relative">
            <NavBar className={"position-absolute bg-transparent"}/>
            <AdviserWish
                global={global}
                baseUrl={baseUrl}
            />
            <Footer
                global={global}
                baseUrl={baseUrl}
            />
            <Copyright/>
        </div>
    </>)
}

export default withContext(WishAdviser);
